var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}},model:{value:(_vm.inputValid),callback:function ($$v) {_vm.inputValid=$$v},expression:"inputValid"}},[_c('v-card-title',[_vm._v(" Create annotation release ")]),(!!_vm.error)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Release name","required":"","dense":"","rules":_vm.nameRules},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{attrs:{"dense":"","label":"Description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('v-select',{attrs:{"dense":"","items":_vm.datasets,"item-text":"name","item-value":"id","label":"Select dataset","hint":"Dataset","persistent-hint":"","return-object":"","single-line":"","rules":_vm.datasetRules},model:{value:(_vm.selectedDataset),callback:function ($$v) {_vm.selectedDataset=$$v},expression:"selectedDataset"}}),_c('div',{staticClass:"pt-4"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Batches")]),_c('div',{staticClass:"caption"},[_vm._v(" Select batches to include in the release. ")]),_c('div',{staticClass:"caption"},[_vm._v(" For each selected batch, select a corresponding annotation task or review task. ")]),_c('div',{staticClass:"caption"},[_vm._v(" The annotations in the selected task will be included in the release. ")]),_c('v-data-table',{staticClass:"elevation-0 mt-4",attrs:{"single-select":false,"show-select":"","dense":"","items":_vm.batchTableItems,"headers":_vm.batchTableHeaders},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('id-item',{attrs:{"id":item.id}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('date-item',{attrs:{"date":new Date(item.created_at)}})]}},{key:"item.selectedTask",fn:function(ref){
var item = ref.item;
return [_c('release-task-selector',{attrs:{"selectedTask":_vm.selectedTaskByBatch[item.id],"batchId":item.id,"tasks":item.tasksForBatch},on:{"update:selectedTask":function($event){return _vm.$set(_vm.selectedTaskByBatch, item.id, $event)},"update:selected-task":function($event){return _vm.$set(_vm.selectedTaskByBatch, item.id, $event)}}})]}}],null,true),model:{value:(_vm.selectedBatches),callback:function ($$v) {_vm.selectedBatches=$$v},expression:"selectedBatches"}})],1),_vm._v(" Creating a release with "+_vm._s(_vm.exportedAnnotationsAmount)+" annotation(s) from "+_vm._s(_vm.selectedBatches.length)+" batches ("+_vm._s(_vm.exportedAnnotationTasks.length)+" annotation tasks and "+_vm._s(_vm.exportedReviewTasks.length)+" review tasks) ")],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","to":_vm.cancelRoute}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":_vm.success ? 'green lighten-2' : 'accent',"loading":_vm.creating,"type":"submit","disabled":!_vm.inputValid ||
              _vm.exportedAnnotationsAmount === 0 ||
              _vm.loading ||
              _vm.creating}},[_vm._v(" "+_vm._s(_vm.success ? 'Success!' : 'Create')+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }