

































import Vue, { PropType } from 'vue';

import { ApiException } from '@/api/client';
import { TaskStatus } from '@/types';
import { TaskAndKind } from '@/layers';

export default Vue.extend({
  props: {
    taskAndKind: {
      required: true,
      type: Object as PropType<TaskAndKind>
    },
    status: {
      required: true,
      type: String as PropType<TaskStatus>
    }
  },
  data: function() {
    return {
      showDialog: false,
      submitting: false,
      submitError: null as string | null
    };
  },
  computed: {
    errorMessages(): string[] {
      return (this.submitError && [this.submitError]) || [];
    },
    isTaskReady(): boolean {
      return this.status === TaskStatus.Ready;
    },
    isTaskSubmitted(): boolean {
      return this.status === TaskStatus.Complete;
    }
  },
  methods: {
    async onSubmit(): Promise<void> {
      console.log(`Submitting task ${this.taskAndKind.task.id}`);
      this.submitError = null;
      try {
        if (this.taskAndKind.kind === 'AnnotationTask') {
          await this.$api.tasks.submitTask({
            taskId: this.taskAndKind.task.id
          });
        } else if (this.taskAndKind.kind === 'ReviewTask') {
          await this.$api.reviewTasks.submitTask({
            taskId: this.taskAndKind.task.id
          });
        } else {
          throw new Error(`Unknown task kind: ${this.taskAndKind.kind}`);
        }
        this.$emit('refresh');
        this.onClose();
      } catch (err) {
        if (err instanceof ApiException) {
          this.submitError = err.message;
        } else {
          console.error('Failed submitting', err);
          throw err;
        }
      } finally {
        this.submitting = false;
      }
    },
    onClose(): void {
      this.showDialog = false;
    }
  }
});
