





































































import { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import _find from 'lodash/find';
import ExportAnnotationsButton from '@/components/molecules/ExportAnnotationsButton.vue';
import RefreshButton from '@/components/molecules/RefreshButton.vue';
import IdItem from '@/components/molecules/IdItem.vue';
import DateItem from '@/components/molecules/DateItem.vue';
import Tooltip from '@/components/molecules/Tooltip.vue';
import { AnnotationRelease } from '@/types';

export default defineComponent({
  components: {
    ExportAnnotationsButton,
    IdItem,
    DateItem,
    RefreshButton,
    Tooltip
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    releases: {
      type: Array as PropType<AnnotationRelease[]>,
      required: true
    }
  },
  data: () => ({
    headers: [
      {
        text: 'Name',
        align: 'start',
        value: 'name',
        sortable: false
      },
      {
        text: 'ID',
        align: 'start',
        value: 'id',
        sortable: false
      },
      {
        text: 'Created by',
        value: 'createdBy',
        sortable: false
      },
      {
        text: 'Created at',
        value: 'createdAt',
        sortable: false
      },
      {
        text: 'Tasks',
        value: 'nTasks',
        align: 'center',
        sortable: false
      },
      {
        text: 'Annotations',
        value: 'nAnnotations',
        align: 'center',
        sortable: false
      },
      {
        text: 'Export',
        value: 'export',
        align: 'center',
        sortable: false
      }
    ]
  }),
  computed: {
    items(): any[] {
      return this.releases.map(release => ({
        ...release,
        id: release.id,
        name: release.name,
        description: release.description,
        createdBy: release.created_by?.name,
        createdAt: new Date(Date.parse(release.created_at)),
        nAnnotations: release.counts.annotations,
        nTasks: release.tasks.length + release.review_tasks.length,
        createdAtRaw: release.created_at
      }));
    },
    createReleaseRoute(): any {
      return {
        name: 'CreateAnnotationRelease',
        params: { projectId: this.project.id }
      };
    }
  },
  methods: {
    releaseForId(releaseId: string) {
      return _find(this.releases, release => release.id === releaseId);
    },
    isProcessing(release: AnnotationRelease) {
      return !release.finished_at;
    },
    toRelease(item: any) {
      return {
        name: 'ReleaseDetails',
        params: {
          releaseId: item.id,
          ...this.$route.params
        }
      };
    }
  }
});
