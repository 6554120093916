































































import Vue, { PropType } from 'vue';
import { Dataset, Project } from '@/types';

export default Vue.extend({
  props: {
    datasets: Array as PropType<Dataset[]>,
    project: Object as PropType<Project>
  },
  data: function() {
    return {
      showDialog: false,
      chosenDataset: null as Dataset | null,
      valid: false,
      rules: [dataset => !!dataset || 'Choose dataset']
    };
  },
  computed: {
    items(): { text: string; value: Dataset }[] {
      return this.datasets.map(dataset => ({
        text: `${dataset.name} (${dataset.id})`,
        value: dataset
      }));
    },
    formIsValid(): boolean {
      return !!this.chosenDataset;
    },
    rows(): { name: string; value: string }[] {
      return [{ name: 'Project', value: this.project.name }];
    }
  },
  methods: {
    async submit(): Promise<void> {
      const chosenDatasetId = this.chosenDataset?.id;
      console.log('Unlinking from dataset', chosenDatasetId);
      await this.$api.projects.unlinkDataset({
        datasetId: chosenDatasetId,
        projectId: this.project.id
      });
      this.$emit('refresh');
      this.onClose();
    },
    onClose() {
      (this.$refs.form as HTMLFormElement).reset();
      this.showDialog = false;
    }
  }
});
