




















































import _find from 'lodash/find';
import _orderBy from 'lodash/orderBy';
import Vue, { PropType } from 'vue';

import SubmitTaskButton from '@/components/SubmitTaskButton.vue';
import DateItem from '@/components/molecules/DateItem.vue';
import { AnnotationTask, ReviewTask, TaskStatus } from '@/types';
import { TaskAndKind } from '@/layers';

export default Vue.extend({
  components: {
    SubmitTaskButton,
    DateItem
  },
  data: () => ({
    headers: [
      {
        text: 'Task type',
        value: 'taskType',
        align: 'center'
      },
      {
        text: 'Stage',
        value: 'stage',
        align: 'center'
      },
      {
        text: 'Assigned by',
        value: 'createdBy'
      },
      {
        text: 'Assigned at',
        value: 'created_at'
      },
      {
        text: 'Status',
        value: 'status',
        align: 'center'
      },

      {
        text: 'Progress',
        value: 'progress',
        align: 'center'
      },
      {
        text: 'Priority',
        value: 'priority',
        align: 'center'
      },
      {
        text: 'Actions',
        value: 'annotate',
        align: 'center'
      }
    ]
  }),
  props: {
    tasksAndKind: {
      required: true,
      type: Array as PropType<Array<TaskAndKind>>
    }
  },
  computed: {
    hasPendingTasks(): boolean {
      return this.taskItems.some(item => item.status === TaskStatus.Pending);
    },
    hasPrioritizedTasks(): boolean {
      return this.taskItems.some(task => task.priority > 0);
    },
    hasCompletedAllTasks(): boolean {
      return this.taskItems.length === 0;
    },
    taskHeaders(): Array<Record<string, string>> {
      return this.hasPrioritizedTasks
        ? this.headers
        : this.headers.filter(header => header.value !== 'priority');
    },
    statusReady(): string {
      return TaskStatus.Ready;
    },
    taskItems(): Array<any> {
      const taskItems = this.tasksAndKind
        .map(({ task, kind }) => ({
          ...task,
          kind: kind,
          /* eslint-disable */
          id: task.id,
          // @ts-ignore
          annotator: kind === 'AnnotationTask' ? task.annotator?.name : null,
          assets: task.counts.assets,
          annotations: task.counts.annotations,
          // @ts-ignore
          priority: task.priority || 0,
          // @ts-ignore
          createdBy: task.created_by ? task.created_by.name : undefined,
          // @ts-ignore
          createdAt: task.created_at
            // @ts-ignore
            ? new Date(Date.parse(task.created_at)).toLocaleDateString()
            : null,
          status: task.submitted_at
            ? TaskStatus.Complete
            : task.counts.annotations === task.counts.assets
            ? TaskStatus.Ready
            : TaskStatus.Pending,
          progress: `${(
            (task.counts.annotations / task.counts.assets) *
            100.0
          ).toFixed(0)} %`,
          taskType: task.ui?.name,
          stage: kind === "AnnotationTask" ? "Annotation" : "Review"
          /* eslint-enable */
        }))
        .filter(taskItem => taskItem.status !== TaskStatus.Complete);
      return _orderBy(taskItems, ['priority', 'created_at'], ['desc', 'desc']);
    },
    noTasks(): boolean {
      return this.taskItems.length === 0;
    }
  },
  methods: {
    toGeneric(item: any): TaskAndKind {
      return _find(this.tasksAndKind, ({ task }) => task.id === item.id);
    },
    isAnnotationTask(item: any) {
      return item.kind === 'AnnotationTask';
    },
    annotateRoute(task: AnnotationTask) {
      return {
        name: 'TaskAnnotate',
        params: { projectId: task.project.id, taskId: task.id }
      };
    },
    reviewRoute(task: ReviewTask) {
      return {
        name: 'ReviewTaskAnnotate',
        params: {
          projectId: task.project.id,
          orgId: task.organization.id,
          taskId: task.id
        }
      };
    }
  }
});
