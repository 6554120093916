

























import { computed, defineComponent, toRefs, ref } from '@vue/composition-api';

const idToShort = (id: string, length: number): string => {
  return `${id.substring(0, length)}${length < id.length ? '...' : ''}`;
};

async function tryCopyToClipBoard(text: string) {
  const result = await navigator.permissions.query({
    name: 'clipboard-write'
  } as any);
  if (result.state == 'granted' || result.state == 'prompt') {
    await navigator.clipboard.writeText(text);
    return;
  }
  throw new Error(`Copy not granted`);
}

export default defineComponent({
  name: 'IdItem',
  components: {},
  props: {
    id: {
      required: true,
      type: String
    },
    length: {
      required: false,
      default: 4,
      type: Number
    }
  },
  setup(props) {
    const { id, length } = toRefs(props);

    const short = computed(() => idToShort(id.value, length.value));

    const show = ref(false);

    const copied = ref(false);

    async function copyToClipBoard() {
      try {
        await tryCopyToClipBoard(id.value);
        copied.value = true;
      } catch (err) {
        console.error(`Failed copying to clipboard`, err);
      }
    }

    return { copied, copyToClipBoard, short, long: id, show };
  }
});
