























import useApi from '@/api/useApi';
import { computed, defineComponent, ref, toRefs } from '@vue/composition-api';
import { PropType } from 'vue';
import { ApiException, BadRequest } from '../api/client';

import { ProjectItem } from '../types';

export default defineComponent({
  props: {
    project: {
      required: true,
      type: Object as PropType<ProjectItem>
    },
    closeDialog: {
      required: true,
      type: Function as PropType<() => void>
    }
  },
  setup(props, { emit }) {
    const { project } = toRefs(props);
    const deleting = ref(false);
    const deleteError = ref(null as Error | null);

    const errorMessage = computed(() => {
      if (deleteError.value == null) {
        return '';
      }

      if (deleteError.value instanceof BadRequest) {
        return deleteError.value.errors.map(err => err.title).join(',');
      } else {
        return deleteError.value.message;
      }
    });

    const datasetCount = computed(() => project.value.mDatasets);

    const api = useApi();

    function onClose() {
      deleteError.value = null;
      props.closeDialog();
    }

    async function onSubmit(): Promise<void> {
      console.log(`Deleting project ${project.value.id}`);
      deleteError.value = null;
      deleting.value = true;
      try {
        await api.projects.deleteProject({ projectId: project.value.id });
        emit('refresh');
        onClose();
      } catch (err) {
        console.error(`Error deleting project`, err);
        if (err instanceof ApiException) {
          deleteError.value = err;
        } else {
          deleteError.value = err;
          throw err;
        }
      } finally {
        deleting.value = false;
      }
    }

    return {
      datasetCount,
      deleting,
      deleteError,
      errorMessage,
      onSubmit,
      onClose
    };
  }
});
