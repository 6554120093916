








import { computed, defineComponent, toRefs } from '@vue/composition-api';
import { dateToShortFormat, dateToLongFormat } from '@/utils';

export default defineComponent({
  name: 'DateItem',
  components: {},
  props: {
    date: {
      required: true,
      type: Date
    }
  },
  setup(props) {
    const { date } = toRefs(props);

    const short = computed(() => dateToShortFormat(date.value));
    const long = computed(() => dateToLongFormat(date.value));

    return { short, long };
  }
});
