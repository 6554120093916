import { inject, InjectionKey } from '@vue/composition-api';
import annotatorApi, { AnnotatorApi } from '@/api';

/**
 * Symbol for providing and injecting AnnotatorApi in type-safe manner
 * https://logaretm.com/blog/2020-12-23-type-safe-provide-inject
 * Use `provide(ApiInjectKey)` for providing API to child components.
 */
export const ApiInjectKey: InjectionKey<AnnotatorApi> = Symbol('api');

/**
 * Use API client injected from the parent component, this makes it possible to override the API in testing
 */
const useApi = (): AnnotatorApi => {
  return inject(ApiInjectKey, annotatorApi);
};

export default useApi;
