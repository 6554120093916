




































import Vue, { PropType } from 'vue';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _maxBy from 'lodash/maxBy';

import { AnnotationGuideline, Project } from '@/types';
import { AnnotationUI } from '@/components/annotations/ui';
import CreateAnnotationGuidelineButton from '@/components/molecules/CreateAnnotationGuidelineButton.vue';
import DateItem from '@/components/molecules/DateItem.vue';

export default Vue.extend({
  components: {
    CreateAnnotationGuidelineButton,
    DateItem
  },
  data: () => ({
    headers: [
      {
        text: 'Version',
        align: 'start',
        sortable: true,
        value: 'version'
      },
      {
        text: 'Created by',
        align: 'start',
        sortable: true,
        value: 'createdBy'
      },
      {
        text: 'Created at',
        align: 'start',
        sortable: true,
        value: 'createdAt'
      },
      {
        text: 'Files',
        align: 'start',
        value: 'files'
      }
    ]
  }),
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true
    },
    versions: {
      type: Array as PropType<Array<AnnotationGuideline>>,
      required: true
    },
    ui: {
      type: Object as PropType<AnnotationUI>,
      required: true
    }
  },
  computed: {
    latestVersion(): AnnotationGuideline | undefined {
      return _maxBy(this.versions, 'version');
    },
    items(): Record<string, any>[] {
      const items = this.versions.map(version => ({
        ...version,
        version: version.version,
        createdBy: version.created_by?.name,
        createdAt: version.created_at,
        files: version.files
      }));
      const sortedAsc = _sortBy(items, 'version');
      return _reverse(sortedAsc);
    }
  },
  methods: {}
});
