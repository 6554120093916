


































































import Vue, { PropType } from 'vue';
import _sortBy from 'lodash/sortBy';
import { renderCsv, renderJson } from './exportUtils';
import { AnnotatedAsset, AnnotationRelease } from '@/types';
import { TaskAndKind } from '@/layers';
import _omit from 'lodash/omit';

const tabItems = {
  0: {
    name: 'JSON',
    render: renderJson,
    fileSuffix: '.json'
  },
  1: {
    name: 'CSV',
    render: renderCsv,
    fileSuffix: '.csv'
  }
};

export default Vue.extend({
  name: 'ExportAnnotationsButton',
  props: {
    // Either taskAndKind or release required
    taskAndKind: {
      type: Object as PropType<TaskAndKind>,
      required: false
    },
    release: {
      type: Object as PropType<AnnotationRelease>,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      showDialog: false,
      loading: false,
      error: null as string | null,
      annotations: null as { annotations: AnnotatedAsset[] } | null,
      batchNameStem: null as string | null,
      errorDownloading: null as string | null,
      tab: null as number | null,
      tabItems,
      filenameStemInput: null as string | null,
      filenameRules: [
        val => (!!val && val.length > 0) || 'Filename must be non-empty'
      ]
    };
  },
  computed: {
    inputValid(): boolean {
      return this.filenameRules.every(
        rule => rule(this.filenameStemInput) === true
      );
    },
    defaultFilenameStem(): string {
      const id = this.taskAndKind ? this.taskAndKind.task.id : this.release.id;
      if (this.batchNameStem !== null) {
        return `${this.batchNameStem}-${id}`;
      }
      return `annotations-${id}`;
    },
    fileSuffix(): string | undefined {
      if (this.tab === null) {
        return undefined;
      }
      const suffix = this.tabItems[this.tab].fileSuffix;
      return suffix;
    },
    downloadFilename(): string {
      return `${this.filenameStemInput}${this.fileSuffix}`;
    },
    fetchAnnotationsFn(): () => Promise<AnnotatedAsset[]> {
      return this.taskAndKind && this.taskAndKind.kind === 'AnnotationTask'
        ? () =>
            this.$api.tasks.getAnnotations({
              taskId: this.taskAndKind.task.id
            })
        : this.taskAndKind && this.taskAndKind.kind === 'ReviewTask'
        ? () =>
            this.$api.reviewTasks.getAnnotations({
              taskId: this.taskAndKind.task.id
            })
        : this.release
        ? () =>
            this.$api.annotationReleases.getAnnotations({
              releaseId: this.release.id
            })
        : undefined;
    }
  },
  methods: {
    async fetchAnnotations() {
      this.loading = true;
      this.error = null;
      const fetchFn = this.fetchAnnotationsFn;
      if (!fetchFn) {
        throw Error('Invalid configuration, expected task or release');
      }

      // First try load batch for resolving filename
      if (this.taskAndKind) {
        try {
          const batch = await this.$api.batches.getBatch({
            batchId: this.taskAndKind.task.batch.id
          });
          // https://stackoverflow.com/a/4250408/10561443
          this.batchNameStem =
            batch.filename?.replace(/\.[^/.]+$/, '') || `batch-${batch.id}`;
        } catch (err) {
          console.warn('Failed loading batch, proceeding without batch', err);
        }
      }

      try {
        const annotationArray = await fetchFn();
        const sorted: AnnotatedAsset[] = _sortBy(
          annotationArray,
          annotation => annotation.asset.created_at
        );
        const sortedWithoutTraceId: AnnotatedAsset[] = sorted.map(
          (annotation: AnnotatedAsset) => {
            return {
              ...annotation,
              labels: annotation.labels.map(label => _omit(label, 'trace_id'))
            };
          }
        );
        this.annotations = { annotations: sortedWithoutTraceId };
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
    async onDownload() {
      // console.log(`Downloading annotations for ${this.taskAndKind.task.id}`);
      this.errorDownloading = null;
      try {
        // https://forum.vuejs.org/t/saving-form-data/38714/2
        const asString = this.tabItems[this.tab].render(
          this.annotations.annotations
        );
        const blob = new Blob([asString], {
          type: 'text/plain;charset=utf-8;'
        });

        const maybeMsSaveBlob = (navigator as any).msSaveBlob;

        if (maybeMsSaveBlob) {
          // IE 10+
          maybeMsSaveBlob(blob, this.downloadFilename);
          return;
        }

        const link = document.createElement('a');
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', this.downloadFilename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        this.errorDownloading = err;
        throw err;
      }
    },
    onClose() {
      this.showDialog = false;
    }
  },
  watch: {
    showDialog: function(newValue) {
      if (newValue) {
        this.fetchAnnotations().then(() => {
          this.filenameStemInput = this.defaultFilenameStem;
        });
      }
    }
  }
});
