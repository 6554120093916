















import Vue from 'vue';
import _find from 'lodash/find';

import { ApiException } from '@/api/client';
import LoadingModal from '../LoadingModal.vue';

import ProjectAnnotationReleasesTable from '../ProjectAnnotationReleasesTable.vue';

import { AnnotationTask, Project, AnnotationRelease } from '@/types';

export default Vue.extend({
  components: {
    ProjectAnnotationReleasesTable,
    LoadingModal
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    orgId: {
      type: String,
      required: true
    }
  },
  created() {
    this.fetchData();
  },
  data: function() {
    return {
      loading: false,
      error: null as string | null,
      project: null as Project | null,
      tasks: null as AnnotationTask[] | null,
      releases: null as AnnotationRelease[] | null
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.error = null;
      try {
        const project = await this.$api.projects.getProject(this.projectId);
        this.project = project;

        const projectDatasets = await this.$api.projects.getProjectDatasets(
          this.projectId
        );

        this.tasks = await this.$api.projects.getTasks(this.projectId);
        this.tasks = this.tasks.map(task => {
          const expandedDataset = _find(
            projectDatasets,
            ds => ds.id === task.dataset.id
          );
          const expandedBatch = _find(
            expandedDataset.batches,
            batch => batch.id === task.batch.id
          );
          return {
            ...task,
            batch: expandedBatch,
            dataset: expandedDataset
          };
        });

        this.releases = await this.$api.projects.getAnnotationReleases({
          projectId: this.project.id
        });
      } catch (err) {
        if (!(err instanceof ApiException)) {
          console.error(err);
        }
        this.error = err;
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    projectId: function() {
      this.fetchData();
    }
  }
});
