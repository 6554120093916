var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.taskHeaders,"items":_vm.taskItems,"items-per-page":15,"sort-by":"createdAtRaw","sort-desc":true,"search":_vm.search,"group-by":"status"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-0",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Annotation tasks")]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){_vm.showSearch = !_vm.showSearch}}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)],1),_c('v-expand-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSearch),expression:"showSearch"}],staticClass:"flex-wrap py-1"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{staticClass:"mx-4 pt-0",attrs:{"label":"Search","dense":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{staticClass:"mx-4 pt-0",attrs:{"items":_vm.members,"item-text":"name","item-value":"name","dense":"","clearable":"","label":"Assignee"},model:{value:(_vm.assigneeSearch),callback:function ($$v) {_vm.assigneeSearch=$$v},expression:"assigneeSearch"}})],1)],1)],1)]},proxy:true},{key:"item.export",fn:function(ref){
var item = ref.item;
return [_c('export-annotations-button',{attrs:{"taskAndKind":_vm.toGeneric(item)}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('date-item',{attrs:{"date":new Date(item.created_at)}})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('id-link',{attrs:{"id":item.id,"to":_vm.taskDetailsRoute(item.id)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":"","close-on-content-click":false,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"text":"","small":"","color":"info","to":_vm.annotateTaskRoute(item.id)}},[_vm._v(" Annotate ")])],1),_c('v-list-item',[_c('export-annotations-button',{attrs:{"taskAndKind":_vm.toGeneric(item)}})],1),_c('v-list-item',[_c('submit-task-button',_vm._g({attrs:{"taskAndKind":_vm.toGeneric(item),"text":"","color":"info","small":""}},_vm.$listeners))],1),_c('v-list-item',[_c('decline-task-button',_vm._g({attrs:{"task":item,"text":"","color":"info","small":""}},_vm.$listeners))],1),_c('v-list-item',[_c('open-dialog-button',{attrs:{"text":"","title":"Edit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var closeDialog = ref.closeDialog;
return [_c('edit-task-card',_vm._g({attrs:{"task":item.task,"closeDialog":closeDialog}},_vm.$listeners))]}}],null,true)})],1),_c('v-list-item',[_c('delete-task-button',_vm._g({attrs:{"taskAndKind":_vm.toGeneric(item),"text":"","small":"","color":"info"}},_vm.$listeners))],1),_c('v-list-item',[_c('open-dialog-button',{attrs:{"text":"","title":"Create review task"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var closeDialog = ref.closeDialog;
return [_c('create-review-task-card',_vm._g({attrs:{"task":item.task,"closeDialog":closeDialog}},_vm.$listeners))]}}],null,true)})],1)],1)],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('delete-task-button',_vm._g({attrs:{"task":item,"project":_vm.project}},_vm.$listeners))]}},(_vm.tasks.length === 0)?{key:"no-data",fn:function(){return [_vm._v(" No tasks. ")]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }