











































import Vue from 'vue';
import { ApiException } from '@/api/client';

import LoadingModal from '../LoadingModal.vue';

export default Vue.extend({
  name: 'ProjectView',
  components: {
    LoadingModal
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    orgId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    project: null,
    loading: false,
    error: null
  }),
  created() {
    this.fetchData();
  },
  computed: {
    projectCreatedAt(): string {
      return this.project
        ? new Date(Date.parse(this.project.created_at)).toDateString()
        : null;
    },
    toProjects(): Record<string, any> {
      return {
        name: 'Projects',
        params: {
          orgId: this.orgId
        }
      };
    },
    items(): any[] {
      return [
        {
          text: 'Tasks',
          icon: 'mdi-pencil',
          route: this.makeRoute('ProjectTasks')
        },
        {
          text: 'Data',
          icon: 'mdi-database',
          route: this.makeRoute('ProjectData')
        },
        /*
        Remove until problems with scalability have been addressed.
        {
          text: 'Metrics',
          icon: 'mdi-chart-line',
          route: this.makeRoute('ProjectMetrics')
        },
        */
        {
          text: 'Configurations',
          icon: 'mdi-cog',
          route: this.makeRoute('ProjectConfigurations')
        },
        {
          text: 'Releases',
          icon: 'mdi-star',
          route: this.makeRoute('ProjectReleases')
        }
      ];
    }
  },
  methods: {
    makeRoute(name: string): any {
      return {
        name,
        props: {
          orgId: this.orgId,
          projectId: this.projectId
        }
      };
    },
    async fetchData() {
      this.loading = true;
      this.error = null;
      try {
        this.project = await this.$api.projects.getProject(this.projectId);
      } catch (err) {
        if (!(err instanceof ApiException)) {
          console.error(err);
        }
        this.error = err;
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    projectId: function() {
      this.fetchData();
    }
  }
});
