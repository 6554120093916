




















import {
  computed,
  defineComponent,
  ref,
  toRefs,
  watchEffect
} from '@vue/composition-api';
import { PropType } from 'vue';

import {
  AnnotationPipeline,
  Project,
  Dataset,
  BatchWithTasksAndPipelines
} from '@/types';
import BatchesTable from './BatchesTable.vue';
import useApi from '@/api/useApi';

export default defineComponent({
  name: 'ProjectDatasetItem',
  components: {
    BatchesTable
  },
  props: {
    dataset: {
      required: true,
      type: Object as PropType<Dataset>
    },
    project: {
      required: true,
      type: Object as PropType<Project>
    },
    pipelines: {
      required: true,
      type: Array as PropType<AnnotationPipeline[]>
    }
  },
  setup(props) {
    const { dataset, project, pipelines } = toRefs(props);

    const loading = ref(false);
    const errorLoading = ref<Error>(null);
    const api = useApi();

    const batches = ref<BatchWithTasksAndPipelines[]>([]);

    async function fetchData() {
      loading.value = true;
      errorLoading.value = null;
      try {
        const batches_ = await api.datasets.listBatches({
          datasetId: dataset.value.id
        });
        const batchesWithTasks: BatchWithTasksAndPipelines[] = await Promise.all(
          batches_.map(async batch => {
            const tasks = await api.batches.getTasksInBatch({
              batchId: batch.id
            });
            const tasksInProject = tasks.filter(
              task => task.project.id === project.value.id
            );
            const pipelinesForBatch = pipelines.value.filter(
              pipeline => pipeline.batch.id === batch.id
            );
            return {
              ...batch,
              tasks: tasksInProject,
              pipelines: pipelinesForBatch
            };
          })
        );

        batches.value = batchesWithTasks;
      } catch (err) {
        console.error(`Failed fetching dataset data`, err);
        errorLoading.value = err;
      } finally {
        loading.value = false;
      }
    }

    watchEffect(fetchData);

    const uploadBatchRoute = computed(() => ({
      name: 'UploadBatch',
      query: {
        projectId: project.value.id,
        datasetId: dataset.value.id
      }
    }));

    return { loading, errorLoading, batches, uploadBatchRoute };
  }
});
