





















































import {
  defineComponent,
  PropType,
  ref,
  toRefs,
  watchEffect
} from '@vue/composition-api';
import useApi from '@/api/useApi';
import { PersonalAccessToken } from '@/types';

export default defineComponent({
  name: 'DeletePersonalAccessTokenButton',
  props: {
    token: {
      required: true,
      type: Object as PropType<PersonalAccessToken>
    }
  },
  setup(props, { emit }) {
    const isOpen = ref(false);
    const { token } = toRefs(props);
    const success = ref(false);
    const isDeleting = ref(false);
    const deleteError = ref<Error>(null);

    const api = useApi();

    function closeMenu() {
      isOpen.value = false;
    }

    function cleanUp() {
      success.value = false;
      isDeleting.value = false;
      deleteError.value = null;
    }

    async function deletePersonalAccessToken(): Promise<void> {
      deleteError.value = null;
      success.value = false;
      isDeleting.value = true;
      try {
        await api.me.deletePersonalAccessToken({ tokenId: token.value.id });
        emit('refresh');
        success.value = true;
      } catch (err) {
        deleteError.value = err;
        console.error('Failed deleting personal access token', err);
      } finally {
        isDeleting.value = false;
      }
    }

    watchEffect(function() {
      if (!isOpen.value) {
        cleanUp();
      }
    });

    return {
      isOpen,
      success,
      isDeleting,
      deleteError,
      deletePersonalAccessToken,
      closeMenu
    };
  }
});
