






































































































import _sum from 'lodash/sum';
import {
  useDatasetBatches,
  useProjectDatasets,
  useProjectReviewTasks,
  useProjectTasks
} from '@/api/use';
import useApi from '@/api/useApi';
import {
  AnnotationRelease,
  AnnotationTask,
  Batch,
  Dataset,
  ReviewTask
} from '@/types';
import {
  computed,
  ComputedRef,
  defineComponent,
  ref,
  toRefs,
  watch
} from '@vue/composition-api';
import IdItem from '@/components/molecules/IdItem.vue';
import DateItem from '@/components/molecules/DateItem.vue';
import ReleaseTaskSelector from '@/components/project/ReleaseTaskSelector.vue';
import _fromPairs from 'lodash/fromPairs';
import _mapValues from 'lodash/mapValues';
import _sortBy from 'lodash/sortBy';
import { whenever } from '@vueuse/core';

type TaskAndKind =
  | {
      kind: 'AnnotationTask';
      value: AnnotationTask;
    }
  | {
      kind: 'ReviewTask';
      value: ReviewTask;
    };

/**
 * Sort annotation tasks and review tasks for batch
 * by their priority.
 * Prioritize by the number of annotations and the stage.
 */
function sortTasks(tasks: TaskAndKind[]): TaskAndKind[] {
  return _sortBy(tasks, [
    // Prioritize tasks with most annotations
    task => -task.value.counts.annotations,
    // Prioritize review tasks
    task => (task.kind === 'ReviewTask' ? 0 : 1)
  ]);
}

export default defineComponent({
  components: {
    IdItem,
    DateItem,
    ReleaseTaskSelector
  },
  props: {
    orgId: {
      required: true,
      type: String
    },
    projectId: {
      required: true,
      type: String
    }
  },
  setup(props, { root: { $router, $store } }) {
    const { projectId } = toRefs(props);
    const name = ref('');
    const description = ref('');

    const {
      datasets,
      error: errorLoadingDatasets,
      loading: loadingDatasets
    } = useProjectDatasets(projectId);

    const cancelRoute = computed(() => ({
      name: 'ProjectReleases',
      params: { projectId: projectId.value }
    }));

    const nameRules = [
      (v: string) =>
        !!v && v.length > 0 ? true : 'Name must have at least one character'
    ];

    const selectedDataset = ref(null as Dataset | null);

    watch(datasets, () => {
      if (datasets.value.length > 0) {
        selectedDataset.value = datasets.value[0];
      } else {
        selectedDataset.value = null;
      }
    });

    const datasetRules = [
      (d: Dataset | null) => (d ? true : 'Dataset must be selected')
    ];

    const inputValid = ref(false);

    const creating = ref(false);
    const errorCreating = ref(null as Error | null);

    const success = ref(false);

    const selectedDatasetId = computed(() => selectedDataset.value?.id);

    const {
      tasks,
      loading: loadingTasks,
      error: errorLoadingTasks
    } = useProjectTasks(projectId);

    const {
      tasks: reviewTasks,
      loading: loadingReviewTasks,
      error: errorLoadingReviewTasks
    } = useProjectReviewTasks(projectId);

    const {
      batches,
      loading: loadingBatches,
      error: errorLoadingBatches
    } = useDatasetBatches(selectedDatasetId);

    const tasksInDataset = computed(() =>
      tasks.value.filter(task => task.dataset.id === selectedDataset.value?.id)
    );

    const reviewTasksInDataset = computed(() =>
      reviewTasks.value.filter(
        task => task.dataset.id === selectedDataset.value?.id
      )
    );

    const tasksByBatch = computed(() => {
      const taskIdToTasks = batches.value.map(batch => ({
        id: batch.id,
        tasks: sortTasks([
          ...tasksInDataset.value
            .filter(task => task.batch.id === batch.id)
            .map(task => ({
              kind: 'AnnotationTask',
              value: task
            })),
          ...reviewTasksInDataset.value
            .filter(task => task.batch.id === batch.id)
            .map(task => ({
              kind: 'ReviewTask',
              value: task
            }))
        ] as Array<TaskAndKind>)
      }));
      return _fromPairs(taskIdToTasks.map(pair => [pair.id, pair.tasks]));
    }) as ComputedRef<Record<string, TaskAndKind[]>>;

    const batchTableItems = computed(() =>
      batches.value
        .map(batch => ({
          ...batch,
          created_at: new Date(
            Date.parse(batch.created_at)
          ).toLocaleDateString(),
          created_by: batch.created_by?.name,
          assets: batch.counts?.assets || 0,
          tasksForBatch: tasksByBatch.value[batch.id]
        }))
        .filter(batch => batch.tasksForBatch.length > 0)
    );

    const api = useApi();

    const error = computed(
      () =>
        errorLoadingDatasets.value ||
        errorLoadingBatches.value ||
        errorLoadingTasks.value ||
        errorLoadingReviewTasks.value
    );
    const loading = computed(
      () =>
        loadingDatasets.value ||
        loadingBatches.value ||
        loadingTasks.value ||
        loadingReviewTasks.value
    );

    const batchTableHeaders = [
      {
        text: 'ID',
        value: 'id'
      },
      {
        text: 'Filename',
        value: 'filename'
      },
      {
        text: 'Created by',
        value: 'created_by'
      },
      {
        text: 'Created at',
        value: 'created_at'
      },
      {
        text: 'Selected task',
        value: 'selectedTask'
      },
      {
        text: 'Assets',
        value: 'assets',
        align: 'center'
      }
    ];

    const selectedBatches = ref<Batch[]>([]);
    const selectedTaskByBatch = ref<Record<string, TaskAndKind | null>>({});

    // Fill in selected tasks automatically when tasks by batch updates
    whenever(tasksByBatch, function() {
      selectedTaskByBatch.value = _mapValues(tasksByBatch.value, function(
        tasks
      ) {
        return tasks.length > 0 ? tasks[0] : null;
      });
      selectedBatches.value = batches.value.filter(
        batch => tasksByBatch.value[batch.id]?.length > 0
      );
    });

    /** For each selected batch, find the corresponding selected annotation task */
    const exportedAnnotationTasks = computed(() => {
      const annotationTasks = selectedBatches.value
        .map(batch => selectedTaskByBatch.value[batch.id])
        .filter(taskOrNull => !!taskOrNull)
        .filter(selected => selected?.kind === 'AnnotationTask')
        .map(task => task.value);

      return annotationTasks;
    });

    /** For each selected batch, find the corresponding selected review task */
    const exportedReviewTasks = computed(() => {
      const reviewTasks = selectedBatches.value
        .map(batch => selectedTaskByBatch.value[batch.id])
        .filter(taskOrNull => !!taskOrNull)
        .filter(selected => selected?.kind === 'ReviewTask')
        .map(task => task.value);

      return reviewTasks;
    });

    const exportedAnnotationsAmount = computed(() => {
      const annotationNumbers = [
        ...exportedAnnotationTasks.value,
        ...exportedReviewTasks.value
      ].map(task => task.counts.annotations);
      return _sum(annotationNumbers);
    });

    const release = ref<AnnotationRelease>(null);

    async function submit() {
      creating.value = true;
      errorCreating.value = null;
      try {
        release.value = await api.projects.createAnnotationRelease({
          name: name.value,
          description: description.value,
          projectId: projectId.value,
          taskIds: exportedAnnotationTasks.value.map(task => task.id),
          reviewTaskIds: exportedReviewTasks.value.map(task => task.id)
        });
        success.value = true;
        setTimeout(() => {
          const route = cancelRoute.value;
          $router.push(route);
        }, 2000);
      } catch (err) {
        console.error(`Failed creating annotation release`, err);
        errorCreating.value = err;
      } finally {
        creating.value = false;
      }
    }

    whenever(release, function() {
      $store.dispatch('organizations/jobStarted', { id: release.value.job.id });
    });

    return {
      name,
      nameRules,
      description,
      loadingDatasets,
      datasets,
      cancelRoute,
      selectedDataset,
      error,
      inputValid,
      success,
      creating,
      loading,
      submit,
      datasetRules,
      batches,
      tasks,
      reviewTasks,
      batchTableHeaders,
      batchTableItems,
      selectedBatches,
      selectedTaskByBatch,
      exportedAnnotationTasks,
      exportedReviewTasks,
      exportedAnnotationsAmount
    };
  }
});
