






































import { computed, defineComponent, ref } from '@vue/composition-api';
import useApi from '@/api/useApi';
import { useSelectedOrganization } from '@/store/use';

export default defineComponent({
  name: 'CreateProject',
  setup(props, { root: { $store, $router } }) {
    const inputValid = ref(false);
    const submitting = ref(false);
    const success = ref(false);
    const error = ref(null as Error | null);

    const { selectedOrganization } = useSelectedOrganization($store);

    const routeToHome = { name: 'Home' };

    const name = ref('');

    const rules = {
      required: (value: string) => !!value || 'Required.'
    };

    const canSubmit = computed(
      () => inputValid.value && !!selectedOrganization.value
    );

    function routeToCreateDataset(projectId: string) {
      return {
        name: 'CreateDataset',
        query: {
          projectId
        }
      };
    }

    const api = useApi();

    async function submit() {
      if (!inputValid.value) {
        return;
      }

      if (!selectedOrganization.value) {
        return;
      }

      if (success.value) {
        return;
      }

      error.value = null;
      submitting.value = true;

      try {
        const project = await api.organizations.createProject({
          organization_id: selectedOrganization.value?.id,
          name: name.value
        });
        success.value = true;
        setTimeout(function() {
          $router.push(routeToCreateDataset(project.id));
        }, 2500);
      } catch (err) {
        error.value = err;
      } finally {
        submitting.value = false;
      }
    }

    const errors = computed(() =>
      [error].filter(err => !!err.value).map(err => err.value)
    );

    return {
      inputValid,
      submit,
      submitting,
      routeToHome,
      rules,
      name,
      success,
      canSubmit,
      selectedOrganization,
      errors
    };
  }
});
