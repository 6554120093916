import { AnnotationUI } from '../annotations/ui';
import { Asset, AssetMedia } from '@/types';
import {
  DEFAULT_CONFIG,
  resolveParameters,
  TextTranslationParameterSchema
} from '@/components/TextTranslation/utils';

const controller = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "text-translation" */ './TextTranslationController.vue'
  );

const canLabelAsset = (asset: Asset): boolean => {
  // Must be a text media and have at least two media fields
  const medias = Object.values(asset.media);
  return (
    medias.length > 1 &&
    medias.every((am: AssetMedia) => am.media_type.startsWith('text/'))
  );
};

const UI: AnnotationUI = {
  component: controller,
  name: 'TextTranslation',
  canLabelAsset: canLabelAsset,
  defaultConfig: DEFAULT_CONFIG,
  resolveParameters,
  configSchema: TextTranslationParameterSchema,
  supportsReview: true
};

export default UI;
