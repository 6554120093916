




















































import Vue, { PropType } from 'vue';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _maxBy from 'lodash/maxBy';
import { AnnotationConfiguration, Project } from '@/types';
import { AnnotationUI } from '@/components/annotations/ui';
import OpenDialogButton from '@/components/molecules/OpenDialogButton.vue';
import CreateAnnotationUiConfigView from '@/components/CreateAnnotationUIConfigView.vue';
import DateItem from '@/components/molecules/DateItem.vue';

export default Vue.extend({
  components: {
    OpenDialogButton,
    CreateAnnotationUiConfigView,
    DateItem
  },
  data: () => ({
    headers: [
      {
        text: 'Version',
        align: 'start',
        sortable: true,
        value: 'version'
      },
      {
        text: 'Created by',
        align: 'start',
        sortable: true,
        value: 'createdBy'
      },
      {
        text: 'Created at',
        align: 'start',
        sortable: true,
        value: 'createdAt'
      },
      {
        text: 'Parameters',
        value: 'data-table-expand',
        align: 'center',
        sortable: false
      }
    ]
  }),
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true
    },
    versions: {
      type: Array as PropType<Array<AnnotationConfiguration>>,
      required: true
    },
    ui: {
      type: Object as PropType<AnnotationUI>,
      required: true
    }
  },
  computed: {
    latestVersion(): AnnotationConfiguration | undefined {
      return _maxBy(this.versions, 'version');
    },
    items(): Record<string, any>[] {
      const items = this.versions.map(version => ({
        ...version,
        version: version.version,
        createdBy: version.created_by?.name,
        createdAt: version.created_at,
        parameters: version.parameters
      }));
      const sortedAsc = _sortBy(items, 'version');
      return _reverse(sortedAsc);
    }
  }
});
