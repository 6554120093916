import apiClient from './client';
import {
  AnnotatedAsset,
  AnnotationTask,
  AnnotationTaskMetrics,
  AssetInTask,
  Label,
  LabelGroup
} from '@/types';
import _omit from 'lodash/omit';

const slug = 'tasks';

const dropAnnotationsProperty = (annotation: AnnotatedAsset) => {
  /**
   * Drop 'annotations' property returned by the backend as
   * it's deprecated
   */
  return _omit(annotation, 'annotations');
};

export default {
  async getTask({ taskId }: { taskId: string }): Promise<AnnotationTask> {
    const task = await apiClient.get(`/${slug}/${taskId}`);
    return task;
  },
  async getAssets({ taskId }: { taskId: string }): Promise<AssetInTask[]> {
    const params = {
      url: `/${slug}/${taskId}/assets`,
      query: {},
      responseKey: 'assets'
    };
    return await apiClient.getPaginated(params);
  },
  async deleteTask({ taskId }: { taskId: string }): Promise<void> {
    await apiClient.delete(`/${slug}/${taskId}`);
  },
  async submitTask({ taskId }: { taskId: string }): Promise<void> {
    await apiClient.put(`/${slug}/${taskId}/submit`);
  },
  async declineTask({ taskId, reason }: { taskId: string; reason: string }) {
    await apiClient.put(`/${slug}/${taskId}/decline`, { reason });
  },
  async getAnnotations({ taskId }): Promise<AnnotatedAsset[]> {
    const res = await apiClient.get(`/${slug}/${taskId}/annotations`);
    return res.annotations.map(ann => dropAnnotationsProperty(ann));
  },
  async getPreAnnotations({ taskId }): Promise<AnnotatedAsset[]> {
    const res = await apiClient.get(`/${slug}/${taskId}/pre-annotations`);
    return res.annotations;
  },
  async getAnnotationForAsset({
    taskId,
    assetId
  }: {
    taskId: string;
    assetId: string;
  }): Promise<AnnotatedAsset | undefined> {
    const res = await apiClient.get(
      `/${slug}/${taskId}/annotations?asset=${assetId}`
    );
    console.debug(`Got task response`, res);
    const annotationsLength = res.annotations.length;
    if (annotationsLength > 1) {
      throw Error(`Did not expect ${annotationsLength} annotations`);
    }
    if (res.annotations.length == 0) {
      return undefined;
    }
    const annotation = res.annotations[0];
    if (annotation.asset.id !== assetId) {
      throw Error(
        `Got invalid asset id ${annotation.asset.id}, expected ${assetId}`
      );
    }
    return res.annotations.length > 0
      ? dropAnnotationsProperty(res.annotations[0])
      : undefined;
  },
  async getPreAnnotationForAsset({
    taskId,
    assetId
  }: {
    taskId: string;
    assetId: string;
  }): Promise<AnnotatedAsset | undefined> {
    const res = await apiClient.get(
      `/${slug}/${taskId}/pre-annotations?asset=${assetId}`
    );
    const predictionsLength = res.annotations.length;
    if (predictionsLength > 1) {
      throw Error(`Did not expect ${predictionsLength} pre-annotations`);
    }
    if (res.annotations.length == 0) {
      return undefined;
    }
    const prediction = res.annotations[0];
    if (prediction.asset.id !== assetId) {
      throw Error(
        `Got invalid asset id ${prediction.asset.id}, expected ${assetId}`
      );
    }
    return prediction;
  },
  async postAnnotationsForAsset({
    taskId,
    assetId,
    labels,
    duration,
    groups = []
  }: {
    taskId: string;
    assetId: string;
    labels: Label[];
    duration: string;
    groups: LabelGroup[];
  }): Promise<AnnotatedAsset> {
    const payload = {
      asset_id: assetId,
      groups,
      labels,
      duration
    };
    const path = `/${slug}/${taskId}/annotations`;
    const annotation = await apiClient.post(path, payload);
    return dropAnnotationsProperty(annotation);
  },
  async getTaskMetrics({
    taskId
  }: {
    taskId: string;
  }): Promise<AnnotationTaskMetrics> {
    const res = await apiClient.get(`/${slug}/${taskId}/metrics`);
    return res.metrics;
  },
  async editTask({
    taskId,
    assigneeEmail
  }: {
    taskId: string;
    assigneeEmail: string;
  }) {
    const payload = {
      assignee_email: assigneeEmail
    };
    const res = await apiClient.patch(`/${slug}/${taskId}`, payload);
    return res;
  }
};
