



















































































import { computed, defineComponent, toRefs } from '@vue/composition-api';
import { useProjectStatistics } from '@/api/use';
import { Project } from '@/types';
import { PropType } from 'vue';
import { trimToPrecision } from '@/utils';

export default defineComponent({
  props: {
    project: {
      required: true,
      type: Object as PropType<Project>
    }
  },
  setup(props) {
    const { project } = toRefs(props);
    const projectId = computed(() => project.value.id);
    const { statistics, loading, error } = useProjectStatistics({ projectId });

    const keyValuePairsLeft = computed(() => {
      if (!statistics.value) {
        return [];
      }
      const counts = statistics.value.counts;
      return [
        {
          key: 'Datasets',
          value: counts.datasets
        },
        {
          key: 'Batches',
          value: counts.batches
        },
        {
          key: 'Batches assigned',
          value: counts.batches_with_tasks
        },
        {
          key: 'Assets',
          value: counts.assets
        }
      ];
    });

    const keyValuePairsRight = computed(() => {
      if (!statistics.value) {
        return [];
      }
      const counts = statistics.value.counts;
      return [
        {
          key: 'Annotation tasks',
          value: counts.tasks
        },

        {
          key: 'Annotations',
          value: counts.annotations
        },
        {
          key: 'Assets annotated',
          value: counts.annotated_assets
        },
        {
          key: 'Annotations assigned',
          value: counts.annotations_assigned
        },
        {
          key: 'Annotations assigned done',
          value: counts.annotations_assigned_done
        }
      ];
    });

    const annotatedPercentage = computed(() => {
      if (!statistics.value) {
        return 0;
      }
      const counts = statistics.value.counts;
      return (counts.annotated_assets / counts.assets) * 100.0;
    });

    const assignedAnnotationsDonePercentage = computed(() => {
      if (!statistics.value) {
        return 0;
      }
      const counts = statistics.value.counts;
      return (
        (counts.annotations_assigned_done / counts.annotations_assigned) * 100.0
      );
    });

    return {
      statistics,
      keyValuePairsLeft,
      keyValuePairsRight,
      loading,
      error,
      annotatedPercentage,
      assignedAnnotationsDonePercentage,
      trimToPrecision
    };
  }
});
