



















import Vue, { PropType } from 'vue';

import UIs from './annotations/uis';
import { Project } from '../types';

export default Vue.extend({
  components: {},
  data: () => ({
    headers: [
      {
        text: 'UI name',
        align: 'start',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Configure',
        align: 'center',
        value: 'configure'
      }
    ]
  }),
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true
    }
  },
  computed: {
    items(): Record<string, any>[] {
      return UIs.map(ui => ({
        name: ui.name,
        ui
      }));
    }
  },
  methods: {
    toConfigure(item) {
      return {
        name: 'ConfigureUI',
        params: {
          orgId: this.project.organization.id,
          projectId: this.project.id,
          uiName: item.name
        }
      };
    }
  }
});
