import { AnnotationUI } from '../annotations/ui';
import {
  DEFAULT_CONFIG,
  resolveParameters,
  SignalAnnotatorParameterSchema
} from './utils';
import { Asset } from '@/types';

const controller = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "time-series-classification" */ './TimeSeriesClassificationController.vue'
  );

const canLabelAsset = (asset: Asset) => {
  const media = asset.media;
  const mediaTypes = Object.values(media)
    .map((val: any) => val.media_type)
    .join(',');
  return mediaTypes === 'text/csv';
};

const UI: AnnotationUI = {
  component: controller,
  name: 'TimeSeriesClassification',
  canLabelAsset,
  defaultConfig: DEFAULT_CONFIG,
  resolveParameters,
  configSchema: SignalAnnotatorParameterSchema,
  supportsReview: false
};

export default UI;
