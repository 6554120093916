<template>
  <v-container style="max-width: 1200px">
    <loading-modal v-model="loading" />
    <v-alert type="error" v-if="error">{{ error }}</v-alert>
    <v-card
      flat
      outlined
      class="mx-6 mt-4 px-0"
      background-color="transparent"
      v-if="!error && !loading && !!datasets"
    >
      <datasets-table
        :organizationId="selectedOrganizationId"
        :datasets="datasets"
        :onCreateDataset="onCreateDataset"
        :onCreateTask="onCreateTask"
        @refresh="fetchData"
      />
    </v-card>
  </v-container>
</template>

<script>
import DatasetsTable from '../components/DatasetsTable';
import LoadingModal from '../components/LoadingModal';

export default {
  name: 'DatasetManagement',
  components: { DatasetsTable, LoadingModal },
  data: () => ({
    loading: false,
    error: null
  }),
  created() {
    this.fetchData();
  },
  computed: {
    selectedOrganizationId() {
      return this.$route.params.orgId;
    }
  },
  methods: {
    async fetchData() {
      this.datasets = null;

      if (!this.selectedOrganizationId) {
        console.warn(`No organization ID selected, skipping fetching data`);
        return;
      }

      this.loading = true;
      this.error = null;
      try {
        this.datasets = await this.$api.organizations.getOrganizationDatasets(
          this.selectedOrganizationId
        );
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
    async onCreateDataset({ name, organization_id }) {
      console.log('Creating dataset', { name, organization_id });
      await this.$api.organizations.createDataset({
        name,
        organization_id  // eslint-disable-line
      });
      await this.fetchData();
    },
    async onUploadBatch({ datasetId, file }) {
      await this.$api.datasets.uploadBatchBlocking({ datasetId, file });
      await this.fetchData();
    },
    async onCreateTask(newTask) {
      console.log('Creating task: ', newTask);
      await this.$api.batches.createTask(newTask);
    }
  },
  watch: {
    selectedOrganizationId: function() {
      this.fetchData();
    }
  }
};
</script>

<style scoped></style>
