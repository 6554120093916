





















import Vue from 'vue';
import LoadingModal from '../components/LoadingModal.vue';
import ProjectsTable from '../components/ProjectsTable.vue';

export default Vue.extend({
  name: 'ProjectManagement',
  components: { ProjectsTable, LoadingModal },
  data: () => ({
    organization: null,
    projects: null,
    loading: false,
    error: null
  }),
  props: {
    orgId: {
      required: true,
      type: String
    }
  },
  created() {
    this.fetchData();
  },
  computed: {},
  methods: {
    async onCreateProject({ name, organization_id }) {
      console.log('Creating project', { name, organization_id });
      await this.$api.organizations.createProject({
        name,
        organization_id
      });
      await this.fetchData();
    },
    async onCreateDataset({ name, organization_id }) {
      console.log('Creating dataset', { name, organization_id });
      await this.$api.organizations.createDataset({
        name,
        organization_id
      });
      await this.fetchData();
    },
    async fetchData() {
      this.loading = true;
      this.error = null;
      this.projects = null;
      try {
        this.projects = await this.$api.organizations.getOrganizationProjects(
          this.orgId
        );
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    orgId: function() {
      this.fetchData();
    }
  }
});
