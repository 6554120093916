


























import Vue from 'vue';
import ChangePasswordButton from './ChangePasswordButton.vue';
import ChangeOrganization from './ChangeOrganization.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'SettingsAccount',
  components: {
    ChangePasswordButton,
    ChangeOrganization
  },
  data: () => ({
    redirect: false,
    timeout: 1500
  }),
  props: {},
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('organizations', [
      'userOrganizations',
      'selectedOrganization'
    ])
  },
  methods: {
    updateSelectedOrganization(organization) {
      console.log(
        `Updating selected organization`,
        JSON.stringify(organization)
      );
      this.$store.dispatch(
        'organizations/setSelectedOrganization',
        organization
      );
      this.redirect = true;
      setTimeout(() => {
        this.$router.push('/');
      }, this.timeout);
    }
  }
});
