

























import Vue, { PropType } from 'vue';
import { ApiException } from '../api/client';

import { ProjectItem } from '../types';

export default Vue.extend({
  props: {
    project: {
      required: true,
      type: Object as PropType<ProjectItem>
    },
    closeDialog: {
      required: true,
      type: Function as PropType<() => void>
    }
  },
  data: function() {
    return {
      deleting: false,
      renameError: null as string | null,
      inputValid: false,
      projectNameRules: [v => !!v || 'Project name is required'],
      newProjectName: ''
    };
  },
  computed: {
    errorMessages(): string[] {
      return (this.renameError && [this.renameError]) || [];
    }
  },
  methods: {
    async submit(): Promise<void> {
      console.log(`Renaming project ${this.project.id}`);
      this.renameError = null;
      try {
        await this.$api.projects.renameProject({
          projectId: this.project.id,
          projectName: this.newProjectName
        });
        this.$emit('refresh');
        this.onClose();
      } catch (err) {
        if (err instanceof ApiException) {
          this.renameError = err.message;
        } else {
          console.error('Failed renaming', err);
          throw err;
        }
      } finally {
        this.deleting = false;
      }
    },
    onClose(): void {
      this.closeDialog();
    }
  }
});
