var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{attrs:{"dense":"","items":_vm.items,"headers":_vm.headers,"items-per-page":10,"sort-by":"createdAtRaw","sort-desc":false},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_vm._v(" Batches ")])]},proxy:true},(_vm.items.length === 0)?{key:"no-data",fn:function(){return [_vm._v(" No batches. ")]},proxy:true}:null,{key:"item.tasks",fn:function(ref){
var item = ref.item;
return [_c('open-dialog-button',{staticClass:"py-0 my-2",attrs:{"title":"Assign","small":"","color":"accent","maxWidth":800},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var closeDialog = ref.closeDialog;
return [_c('create-task-card',_vm._g({attrs:{"batch":item,"projects":[_vm.project],"closeDialog":closeDialog}},_vm.$listeners))]}}],null,true)})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('date-item',{attrs:{"date":new Date(item.created_at)}})]}},{key:"item.nPipelines",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nPipelines)+" "),_c('v-icon',{staticClass:"ml-2",on:{"click":function($event){return _vm.onClickBatchPipelines(item.id)}}},[_vm._v(" mdi-application-export ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":"","close-on-content-click":false,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('open-dialog-button',{attrs:{"text":"","title":"Delete"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var closeDialog = ref.closeDialog;
return [_c('delete-batch-card',_vm._g({attrs:{"batch":item,"closeDialog":closeDialog}},_vm.$listeners))]}}],null,true)})],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }