


















































import { computed, defineComponent, toRefs } from '@vue/composition-api';
import IdItem from '@/components/molecules/IdItem.vue';
import DateItem from '@/components/molecules/DateItem.vue';
import { useReleaseExports } from '@/api/use';
import { AnnotationReleaseExport } from '@/types';
import { BACKEND_BASE_URL } from '@/api/config';

export default defineComponent({
  components: {
    IdItem,
    DateItem
  },
  props: {
    releaseId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { releaseId } = toRefs(props);
    const { error, loading, data: exports_ } = useReleaseExports(releaseId);

    const headers = [
      {
        text: 'ID',
        value: 'id'
      },
      {
        text: 'Version',
        value: 'version',
        align: 'center'
      },
      {
        text: 'Finished at',
        value: 'finished_at'
      },
      {
        text: 'Storage URI',
        value: 'storage_uri'
      },
      {
        text: 'ZIP filename',
        value: 'zip_filename'
      }
    ];

    const items = computed(() =>
      exports_.value.map(export_ => ({
        ...export_
      }))
    );

    function isFinished(exp: AnnotationReleaseExport): boolean {
      return exp.job.state == 'FINISHED';
    }

    return {
      error,
      loading,
      exports_,
      items,
      headers,
      isFinished
    };
  }
});
