import _flatMap from 'lodash/flatMap';
import _uniq from 'lodash/uniq';
import _fromPairs from 'lodash/fromPairs';
import _mapValues from 'lodash/mapValues';
import { AnnotatedAsset, Label } from '@/types';

interface RowDict {
  [key: string]: any;  // eslint-disable-line
}

const stringToCSVField = (s: string): string => {
  return `"${s.replace(/"/g, '""')}"`;
};

const dictsToCsv = (dicts: RowDict[]): string => {
  const allKeys = _flatMap(dicts, dict => Object.keys(dict));

  const headers = _uniq(allKeys);

  const headerLine = headers.join(',');
  const rows = dicts.map(dict => {
    const values = headers.map(header => dict[header]);
    return values.map(value =>
      typeof value === 'string'
        ? stringToCSVField(value)
        : typeof value === 'undefined'
        ? ''
        : stringToCSVField(JSON.stringify(value))
    );
  });
  return `${headerLine}\n${rows.join('\n')}`;
};

export const renderJson = (annotations: AnnotatedAsset[]): string => {
  return JSON.stringify({ annotations }, null, 2);
};

const labelsAreFeatureValuePairs = (labels: Label[]): boolean =>
  labels.every(lab => !!lab.feature && !!lab.value);

const labelsToRows = (labels: Label[]): RowDict[] => {
  if (!labelsAreFeatureValuePairs(labels)) {
    return labels;
  }
  // Special case:
  // Every annotation in asset has 'feature' and 'value', transform
  // annotations to a dictionary of feature-value pairs
  const featureToValue = _fromPairs(
    labels.map(lab => [lab.feature, lab.value])
  );

  const featureToCertainty = _fromPairs(
    labels
      .filter(label => typeof label.certainty !== 'undefined')
      .map(label => [`${label.feature}_certainty`, label.certainty])
  );

  return [
    {
      ...featureToValue,
      ...featureToCertainty
    }
  ];
};

export const renderCsv = (annotations: AnnotatedAsset[]) => {
  if (!annotations) {
    return '';
  }

  const flattened = _flatMap(annotations, annotatedAsset => {
    const labels = annotatedAsset.labels;
    const assetId = annotatedAsset.asset.id;
    const externalId = annotatedAsset.asset.external_id;
    const id = typeof externalId !== 'undefined' ? externalId : assetId;
    return labelsToRows(labels).map(row => ({ id, ...row }));
  });
  return dictsToCsv(flattened);
};
