


















import Vue from 'vue';

export default Vue.extend({
  name: 'OpenDialogButton',
  props: {
    title: {
      required: true,
      type: String
    },
    maxWidth: {
      required: false,
      type: Number,
      default: 1200
    }
  },
  data() {
    return {
      showDialog: false
    };
  }
});
