















































































































































import Vue, { PropType } from 'vue';
import _uniq from 'lodash/uniq';
import _fromPairs from 'lodash/fromPairs';
import { ApiException } from '@/api/client';
import {
  Asset,
  Batch,
  Project,
  ExtendedUser,
  AnnotationConfiguration,
  AnnotationGuideline
} from '@/types';
import AnnotationUIs from '@/components/annotations/uis';
import { AnnotationUI } from '@/components/annotations/ui';

export default Vue.extend({
  props: {
    batch: {
      required: true,
      type: Object as PropType<Batch>
    },
    projects: {
      required: true,
      type: Array as PropType<Array<Project>>
    },
    closeDialog: {
      required: true,
      type: Function as PropType<() => void>
    }
  },
  created() {
    this.initialize();
  },
  data: function() {
    return {
      projectId: null as string | null,
      assigneeId: null as string | null,
      annotationUI: null as AnnotationUI | null,
      configuration: null as string | null,
      guideline: null as string | null,
      uploading: false,
      createError: null as string | null,
      projectRules: [val => !!val || 'Choose project'],
      assigneeRules: [val => !!val || 'Choose assignee'],
      annotationUIRules: [val => !!val || 'Choose annotation UI'],
      configurationRules: [val => !!val || 'Choose configuration'],
      guidelineRules: [val => !!val || 'Choose guideline'],
      loading: false,
      errorLoading: null as string | null,
      assets: null as Array<Asset> | null,
      assignees: [] as Array<ExtendedUser>,
      annotationUIs: [] as Array<AnnotationUI>,
      configurations: {} as Record<string, AnnotationConfiguration | null>,
      guidelines: {} as Record<string, AnnotationGuideline | null>
    };
  },
  computed: {
    errorMessages(): string[] {
      return (this.createError && [this.createError]) || [];
    },
    inputValid(): boolean {
      return (
        !!this.projectId &&
        !!this.assigneeId &&
        this.isValidGuidelineInput &&
        this.isValidAnnotationUIInput &&
        this.isValidConfigurationInput
      );
    },
    isValidAnnotationUIInput(): boolean {
      return this.hasAnnotationUIs ? !!this.annotationUI : true;
    },
    isValidConfigurationInput(): boolean {
      return this.hasConfigurations ? !!this.configuration : true;
    },
    isValidGuidelineInput(): boolean {
      return this.hasGuidelines ? !!this.guideline : true;
    },
    currentUser(): ExtendedUser | null {
      return this.$store.getters['auth/currentUser'];
    },
    hasProjects(): boolean {
      return this.projects.length > 0;
    },
    hasAnnotationUIs(): boolean {
      return this.annotationUIs.length > 0;
    },
    hasConfigurations(): boolean {
      // Account for 'Always latest' option
      return this.configurationItems.length > 1;
    },
    configurationItems(): Array<string> {
      return Object.keys(this.configurations);
    },
    hasGuidelines(): boolean {
      // Account for 'Always latest' option
      return this.guidelineItems.length > 1;
    },
    guidelineItems(): Array<string> {
      return Object.keys(this.guidelines);
    },
    organizationId(): string | undefined {
      const organizationIds = _uniq(
        this.projects.map(project => project.organization.id)
      );
      if (organizationIds.length > 1) {
        throw Error(`Expected all projects to belong to one organization`);
      }
      return organizationIds.length === 1 ? organizationIds[0] : undefined;
    },
    firstAsset(): Asset | undefined {
      if (!this.assets || this.assets.length === 0) {
        return undefined;
      }
      return this.assets[0];
    },
    annotationUIParams(): Record<string, any> {
      return this.annotationUI ? { uiName: this.annotationUI.name } : {};
    },
    configurationParams(): Record<string, any> {
      const configuration = this.configurations[this.configuration];
      return configuration
        ? { configurationVersion: configuration.version }
        : {};
    },
    guidelineParams(): Record<string, any> {
      const guideline = this.guidelines[this.guideline];
      return guideline ? { guidelineVersion: guideline.version } : {};
    }
  },
  methods: {
    async initialize() {
      await this.fetchData();
      this.maybeSetProjectId();
    },
    async fetchData() {
      this.loading = true;
      this.errorLoading = null;

      try {
        this.assets = await this.$api.batches.getAssets({
          batchId: this.batch.id
        });
        this.assignees = await this.$api.organizations.getOrgMembers(
          this.organizationId
        );
      } catch (err) {
        this.errorLoading = err;
      } finally {
        this.loading = false;
      }
    },
    async fetchConfigurationsAndGuidelines() {
      this.loading = true;
      this.errorLoading = null;

      if (!this.projectId) {
        this.configurations = {};
        this.guidelines = {};
        return;
      }

      try {
        const configurations = await this.$api.projects.getAnnotationConfigurations(
          {
            projectId: this.projectId,
            uiName: this.annotationUI.name
          }
        );
        this.configurations = {
          'Always latest': null,
          ..._fromPairs(
            configurations.map(configuration => [
              `Version ${configuration.version}`,
              configuration
            ])
          )
        };
        const guidelines = await this.$api.projects.getAnnotationGuidelines({
          projectId: this.projectId,
          uiName: this.annotationUI.name
        });
        this.guidelines = {
          'Always latest': null,
          ..._fromPairs(
            guidelines.map(guideline => [
              `Version ${guideline.version}`,
              guideline
            ])
          )
        };
      } catch (err) {
        this.errorLoading = err;
      } finally {
        this.loading = false;
      }
    },
    maybeSetProjectId(): void {
      if (!this.projectId && this.projects.length === 1) {
        this.projectId = this.projects[0].id;
      }
    },
    getFullName(member: ExtendedUser): string {
      return member.id === this.currentUser.id
        ? member.name + ' (me)'
        : member.name;
    },
    async submit(): Promise<void> {
      console.log(
        `Creating task for batch ${this.batch.id} and assigning it to ${this.assigneeId} in project ${this.projectId}`
      );
      this.createError = null;
      try {
        await this.$api.batches.createTask({
          batchId: this.batch.id,
          assigneeId: this.assigneeId,
          projectId: this.projectId,
          ...this.annotationUIParams,
          ...this.configurationParams,
          ...this.guidelineParams
        });
        this.$emit('refresh');
        this.closeDialog();
      } catch (err) {
        if (err instanceof ApiException) {
          this.createError = err.message;
        } else {
          console.error('Failed uploading', err);
          throw err;
        }
      } finally {
        this.uploading = false;
        this.reset();
      }
    },
    reset() {
      this.assigneeId = null;
      this.annotationUI = null;
      this.configuration = null;
      this.guideline = null;
    }
  },
  watch: {
    projectId: function(newValue) {
      if (newValue) {
        this.fetchData();
        this.reset();
      }
    },
    firstAsset: function(newValue) {
      this.annotationUIs = this.firstAsset
        ? AnnotationUIs.filter((UI: AnnotationUI) => UI.canLabelAsset(newValue))
        : [];
    },
    annotationUI: function(newValue) {
      if (newValue) {
        this.fetchConfigurationsAndGuidelines();
      }
    },
    annotationUIs: function(newValue) {
      if (newValue) {
        this.annotationUI = newValue[0];
      }
    },
    configurations: function(newValue) {
      if (newValue) {
        this.configuration = Object.keys(newValue)[0];
      }
    },
    guidelines: function(newValue) {
      if (newValue) {
        this.guideline = Object.keys(newValue)[0];
      }
    }
  }
});
