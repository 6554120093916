




















import { useOrganizationProjects } from '@/api/use';
import { useSelectedOrganization } from '@/store/use';
import { computed, defineComponent } from '@vue/composition-api';
import LoadingModal from '@/components/LoadingModal.vue';
import ProjectCard from '@/components/home/ProjectCard.vue';

export default defineComponent({
  components: {
    LoadingModal,
    ProjectCard
  },
  props: {},
  setup(props, { root: { $store } }) {
    const { selectedOrganization } = useSelectedOrganization($store);
    const organizationId = computed(() => selectedOrganization.value?.id);
    const { loading, error, projects } = useOrganizationProjects(
      organizationId
    );

    const routeToCreateProject = {
      name: 'CreateProject'
    };

    return {
      loading,
      error,
      projects,
      routeToCreateProject
    };
  }
});
