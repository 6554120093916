/** Vue Composition API helpers */
import { computed } from '@vue/composition-api';
import { CompactUser, Organization } from '@/types';

export const useSelectedOrganization = store => {
  const selectedOrganization = computed(
    () =>
      store.getters['organizations/selectedOrganization'] as
        | undefined
        | Organization
  );

  const loadingSelectedOrganization = computed(
    () => store.getters['organizations/getLoadingState'] as undefined | boolean
  );

  const errorloadingSelectedOrganization = computed(
    () => store.getters['organizations/getError'] as undefined | Error
  );

  const setSelectedOrganization = (organization: Organization) => {
    store.dispatch('organizations/setSelectedOrganization', organization);
  };

  return {
    loadingSelectedOrganization,
    errorloadingSelectedOrganization,
    selectedOrganization,
    setSelectedOrganization
  };
};

export const useUserOrganizations = store => {
  const userOrganizations = computed(
    () => store.getters['organizations/userOrganizations'] as Organization[]
  );
  return {
    userOrganizations
  };
};

export const useCurrentUser = store => {
  const user = computed(
    () => store.getters['auth/currentUser'] as CompactUser | undefined
  );
  return { user };
};
