/**
 * Do not import anything importing paper.js from here!
 * It's too big a bundle to be loaded on first load.
 */
import { Asset } from '@/types';
import { AnnotationUI } from '../annotations/ui';
import {
  DEFAULT_CONFIG,
  ImageAnnotatorParameterSchema,
  resolveParameters
} from './config';

const controller = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "image-annotator" */
    './ImageAnnotationController.vue'
  );

const canLabelAsset = (asset: Asset) => {
  const media = asset.media;
  const mediaTypes = Object.values(media)
    .map(val => val.media_type)
    .filter(mediaType => mediaType.startsWith('image/'));
  return mediaTypes.length === 1;
};

const ImageAnnotationUI: AnnotationUI = {
  component: controller,
  name: 'ImageAnnotator',
  canLabelAsset,
  defaultConfig: DEFAULT_CONFIG,
  resolveParameters,
  configSchema: ImageAnnotatorParameterSchema,
  supportsReview: true
};

export default ImageAnnotationUI;
