import apiClient, { NotFound } from './client';
import { Asset, AssetMediaEmbedding } from '@/types';
import { arrayBufferToBase64 } from './utils';
const slug = 'assets';

export default {
  async getAsset({ assetId }): Promise<Asset> {
    const asset = await apiClient.get(`/${slug}/${assetId}`);
    return asset;
  },
  getMediaPath({ assetId, mediaSlug }: { assetId: string; mediaSlug: string }) {
    // Media path without base URL
    return `/${slug}/${assetId}/${mediaSlug}`;
  },
  /**
   * Fetch media as arraybuffer.
   * @param {Object} input
   * @param {string} input.assetId - Asset ID
   * @param {string} input.mediaSlug - Slug of the media in asset. For example, "image".
   * @param {string} input.mediaType - Expected media type. For example, image/jpeg.
   * @returns {Promise<any>} Response data
   */
  async getMedia({
    assetId,
    mediaSlug,
    mediaType
  }: {
    assetId: string;
    mediaSlug: string;
    mediaType: string;
  }): Promise<ArrayBuffer> {
    const path = this.getMediaPath({ assetId, mediaSlug });
    const data = await apiClient.request({
      url: path,
      headers: { accept: mediaType },
      responseType: 'arraybuffer'
    });
    return data;
  },
  /**
   * Fetch media thumbnail if exists.
   */
  async getMediaThumbnail({
    assetId,
    mediaSlug,
    mediaType
  }: {
    assetId: string;
    mediaSlug: string;
    mediaType: string;
  }): Promise<ArrayBuffer | undefined> {
    const path = `${this.getMediaPath({ assetId, mediaSlug })}/thumbnail`;
    try {
      const data = await apiClient.request({
        url: path,
        headers: { accept: mediaType },
        responseType: 'arraybuffer'
      });
      return data;
    } catch (NotFound) {
      return undefined;
    }
  },
  async listEmbeddings({
    assetId,
    mediaSlug
  }: {
    assetId: string;
    mediaSlug: string;
  }): Promise<AssetMediaEmbedding[]> {
    const res = await apiClient.get(
      `/${slug}/${assetId}/${mediaSlug}/embeddings`
    );
    return res.embeddings;
  }
};
