import {
  AnnotatedAsset,
  AssetInTask,
  Label,
  LabelGroup,
  ReviewTask
} from '@/types';
import apiClient from '@/api/client';

const slug = 'review-tasks';

export default {
  async getReviewTask({ taskId }): Promise<ReviewTask> {
    const task = await apiClient.get(`/${slug}/${taskId}`);
    return task;
  },
  async getAnnotations({ taskId }): Promise<AnnotatedAsset[]> {
    const json = await apiClient.get(`/${slug}/${taskId}/annotations`);
    return json.annotations;
  },
  async getAssets({ taskId }: { taskId: string }): Promise<AssetInTask[]> {
    const params = {
      url: `/${slug}/${taskId}/assets`,
      query: {},
      responseKey: 'assets'
    };
    return await apiClient.getPaginated(params);
  },
  async getAnnotationForAsset({
    taskId,
    assetId
  }: {
    taskId: string;
    assetId: string;
  }): Promise<AnnotatedAsset | undefined> {
    const res = await apiClient.get(
      `/${slug}/${taskId}/annotations?asset=${assetId}`
    );
    const annotationsLength = res.annotations.length;
    if (annotationsLength > 1) {
      throw Error(`Did not expect ${annotationsLength} annotations`);
    }
    if (res.annotations.length == 0) {
      return undefined;
    }
    const annotation = res.annotations[0];
    if (annotation.asset.id !== assetId) {
      throw Error(
        `Got invalid asset id ${annotation.asset.id}, expected ${assetId}`
      );
    }
    return annotation;
  },
  async postAnnotationsForAsset({
    taskId,
    assetId,
    labels,
    duration,
    groups = []
  }: {
    taskId: string;
    assetId: string;
    labels: Label[];
    duration: string;
    groups: LabelGroup[];
  }): Promise<AnnotatedAsset> {
    const payload = {
      asset_id: assetId,
      labels,
      duration,
      groups
    };
    const path = `/${slug}/${taskId}/annotations`;
    return apiClient.post(path, payload);
  },
  async submitTask({ taskId }: { taskId: string }): Promise<void> {
    await apiClient.put(`/${slug}/${taskId}/submit`);
  },
  async deleteTask({ taskId }: { taskId: string }): Promise<void> {
    await apiClient.delete(`/${slug}/${taskId}`);
  }
};
