






















import { CompactUser, Job, JobKind, JobState, Organization } from '@/types';
import {
  computed,
  defineComponent,
  toRefs,
  Ref,
  onMounted,
  ref,
  watch,
  onUnmounted
} from '@vue/composition-api';
import { Prop } from 'vue/types/options';
import api from '@/api';

function sameDay(d1: Date, d2: Date) {
  return (
    d1.getUTCFullYear() == d2.getUTCFullYear() &&
    d1.getUTCMonth() == d2.getUTCMonth() &&
    d1.getUTCDate() == d2.getUTCDate()
  );
}

const useJobs = (organizationId: Ref<string>) => {
  const fetcher = (organizationId: string) =>
    api.organizations.getJobs({ organizationId });

  const jobs = ref([] as Job[]);
  const loading = ref(false);
  const error = ref(null as string | null);

  async function fetchData() {
    loading.value = true;
    console.log(`Refreshing jobs...`);
    try {
      jobs.value = await fetcher(organizationId.value);
    } catch (err) {
      console.error(`Failed loading jobs`, err);
      error.value = err;
    } finally {
      loading.value = false;
    }
  }

  onMounted(fetchData);
  watch(organizationId, fetchData);

  return { loading, error, mutate: fetchData, jobs };
};

const useIntervalFn = ({
  update,
  interval,
  immediate
}: {
  update: () => void;
  interval: number;
  immediate: boolean;
}) => {
  let interval_ = null;

  if (immediate) {
    update();
  }

  function start() {
    if (!interval_) {
      interval_ = setInterval(update, interval);
    }
  }

  function clear() {
    if (interval_) {
      clearInterval(interval_);
      interval_ = null;
    }
  }

  return { start, clear };
};

const useNow = () => {
  const date = new Date();
  const now = ref(date) as Ref<Date>;

  const update = () => {
    now.value = new Date();
  };

  const { start, clear } = useIntervalFn({
    update,
    interval: 1000,
    immediate: true
  });

  start();
  onUnmounted(clear);

  return { now };
};

enum DerivedJobState {
  FINISHED = 'FINISHED',
  FAILED = 'FAILED',
  STARTED = 'STARTED',
  TIMEOUTED = 'TIMEOUTED'
}

const TIMEOUT_MS = 3600 * 1000;

const useFilteredJobs = ({
  jobs,
  now,
  maxJobs = 5
}: {
  jobs: Ref<Job[]>;
  maxJobs?: number;
  now: Ref<Date>;
}) => {
  function deriveState(job: Job, now: Date): DerivedJobState {
    const jobState = job.state;
    if (jobState == JobState.FINISHED) {
      return DerivedJobState.FINISHED;
    } else if (jobState === JobState.FAILED) {
      return DerivedJobState.FAILED;
    } else {
      const elapsedMs = job.created_at
        ? now.valueOf() - new Date(job.created_at).valueOf()
        : 0;
      return elapsedMs < TIMEOUT_MS
        ? DerivedJobState.STARTED
        : DerivedJobState.TIMEOUTED;
    }
  }

  function deriveJob(job: Job, now: Date) {
    return { ...job, derivedState: deriveState(job, now) };
  }

  const filteredJobs = computed(() => {
    return jobs.value.slice(0, maxJobs).map(job => deriveJob(job, now.value));
  });

  return { jobs: filteredJobs };
};

export interface DecoratedJob extends Job {
  derivedState: DerivedJobState;
}

const useJobsStarted = store => {
  const jobsStarted = computed(
    () => store.getters['organizations/jobsStarted'] as string[]
  );
  return { jobsStarted };
};

function getJobDescription(job: DecoratedJob): string {
  const kind =
    job.kind == JobKind.HANDLE_UPLOAD
      ? 'Upload'
      : job.kind == JobKind.CREATE_RELEASE
      ? 'Release creation'
      : job.kind == JobKind.CREATE_RELEASE_EXPORT
      ? 'Release export creation'
      : job.kind;
  let description = `${kind} job ${job.id.slice(
    0,
    8
  )}... ${job.derivedState.toLowerCase()}`;

  const now = new Date();

  if (job.updated_at) {
    const updatedAt = new Date(job.updated_at);
    description += ` at ${updatedAt.toLocaleTimeString()}`;

    if (!sameDay(now, updatedAt)) {
      description += ` on ${updatedAt.toLocaleDateString()}`;
    }
  }

  if (job.errors) {
    description += `, errors: ${job.errors.join(', ')}`;
  }
  return description;
}

export default defineComponent({
  props: {
    user: {
      required: true,
      type: Object as Prop<CompactUser>
    },
    organization: {
      required: true,
      type: Object as Prop<Organization>
    },
    notifications: {
      required: true,
      type: Number
    }
  },
  setup(props, { emit, root }) {
    const { jobsStarted } = useJobsStarted(root.$store);
    const { user, organization } = toRefs(props);

    const selectedOrganizationId = computed(() => organization.value.id);

    // Is there a better way for getting a reactive property?
    const userId = computed(() => user.value.id);

    const { loading, error, jobs, mutate } = useJobs(selectedOrganizationId);

    const { now } = useNow();

    const { jobs: filteredJobs } = useFilteredJobs({ jobs, now });

    const stateToIconMap = {
      [DerivedJobState.FINISHED]: `check-circle-outline`,
      [DerivedJobState.FAILED]: `alert-circle-outline`,
      [DerivedJobState.TIMEOUTED]: `timer-sand-empty`,
      [DerivedJobState.STARTED]: `progress-check`
    };

    const stateToIconColorMap = {
      [DerivedJobState.FINISHED]: `green`,
      [DerivedJobState.FAILED]: `red`,
      [DerivedJobState.TIMEOUTED]: `grey`,
      [DerivedJobState.STARTED]: `blue`
    };

    const runningJobs = computed(() =>
      filteredJobs.value.filter(
        job => job.derivedState === DerivedJobState.STARTED
      )
    );

    // Re-fetch if user uploads a batch
    watch(jobsStarted, mutate);

    const { start: startPolling, clear: clearPolling } = useIntervalFn({
      update: mutate,
      interval: 1000,
      immediate: false
    });

    // Update notifications value and start or clear polling
    watch(runningJobs, () => {
      emit('update:notifications', runningJobs.value.length);

      if (runningJobs.value.length > 0) {
        startPolling();
      } else if (runningJobs.value.length === 0) {
        clearPolling();
      }
    });

    onUnmounted(clearPolling);

    return {
      loading,
      error,
      jobs: filteredJobs,
      userId,
      selectedOrganizationId,
      stateToIconMap,
      stateToIconColorMap,
      getJobDescription
    };
  }
});
