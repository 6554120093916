import AuthApi from '../../api/auth';

export default {
  namespaced: true,
  state: {
    user: null,
    isAuth: false
  },
  getters: {
    currentUser(state) {
      return state.user;
    },
    isAuth(state) {
      return state.isAuth;
    }
  },
  actions: {
    async checkJwt(context) {
      try {
        const user = await AuthApi.checkJwt();
        context.commit('SET_USER', user);
        context.commit('SET_AUTH', true);
        return { auth: true };
      } catch (err) {
        console.error(err);
        context.commit('SET_USER', undefined);
        context.commit('SET_AUTH', false);
        return { auth: false };
      }
    },
    async register(_, payload) {
      await AuthApi.register(payload);
    },
    async login(context, payload) {
      try {
        const user = await AuthApi.login(payload);
        context.commit('SET_USER', user);
        context.commit('SET_AUTH', true);
      } catch (err) {
        context.commit('SET_USER', undefined);
        context.commit('SET_AUTH', false);
        throw err;
      }
    },
    async logout(context) {
      await AuthApi.logout();
      context.commit('SET_USER', undefined);
      context.commit('SET_AUTH', false);
      context.dispatch('organizations/resetState', null, { root: true });
    }
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_AUTH(state, auth) {
      state.isAuth = auth;
    }
  }
};
