











































import { computed, defineComponent, ref, toRefs } from '@vue/composition-api';
import { useProject } from '@/api/use';
import useApi from '@/api/useApi';
import { useSelectedOrganization } from '@/store/use';

export default defineComponent({
  name: 'CreateDataset',
  props: {
    projectId: {
      required: true,
      type: String
    }
  },
  setup(props, { root: { $store, $router } }) {
    const { projectId } = toRefs(props);
    const { project } = useProject(projectId);
    const inputValid = ref(false);
    const submitting = ref(false);
    const success = ref(false);
    const error = ref(null as Error | null);

    const { selectedOrganization } = useSelectedOrganization($store);

    const orgId = computed(() => selectedOrganization.value?.id);

    const routeToProject = computed(() => ({
      name: 'ProjectTasks',
      params: {
        projectId: projectId.value,
        orgId: orgId.value
      }
    }));

    const name = ref('');

    const rules = {
      required: (value: string) => !!value || 'Required.'
    };

    const canSubmit = computed(
      () => inputValid.value && !!selectedOrganization.value
    );

    function routeToUploadBatch(datasetId: string) {
      return {
        name: 'UploadBatch',
        query: {
          projectId: projectId.value,
          datasetId
        }
      };
    }

    const api = useApi();

    async function submit() {
      if (!inputValid.value) {
        return;
      }

      if (!selectedOrganization.value) {
        return;
      }

      if (success.value) {
        return;
      }

      error.value = null;
      submitting.value = true;

      try {
        const dataset = await api.projects.createDataset({
          projectId: projectId.value,
          name: name.value
        });
        success.value = true;
        setTimeout(function() {
          $router.push(routeToUploadBatch(dataset.id));
        }, 2500);
      } catch (err) {
        error.value = err;
      } finally {
        submitting.value = false;
      }
    }

    const errors = computed(() =>
      [error].filter(err => !!err.value).map(err => err.value)
    );

    return {
      inputValid,
      submit,
      submitting,
      routeToProject,
      rules,
      name,
      success,
      canSubmit,
      selectedOrganization,
      errors,
      project
    };
  }
});
