




























































import Vue, { PropType } from 'vue';
import { AnnotationGuideline, Project } from '../../types';
import { AnnotationUI } from '../annotations/ui';

const MAX_SIZE_MB = 1000;

export default Vue.extend({
  name: 'CreateAnnotatioGuidelineButton',
  props: {
    project: {
      required: true,
      type: Object as PropType<Project>
    },
    ui: {
      type: Object as PropType<AnnotationUI>,
      required: true
    },
    latest: {
      type: Object as PropType<AnnotationGuideline>,
      required: false
    }
  },
  data() {
    return {
      showDialog: false,
      inputFiles: null as File[] | null,
      loading: false,
      errorSaving: null as string | null,
      submitting: false,
      inputValid: null,
      fileRules: [
        (value: File[]) =>
          value.length === 0 ||
          value.every(file => file.size < MAX_SIZE_MB * 1024 * 1024) ||
          `Guideline size should be less than ${MAX_SIZE_MB} MB!`
      ]
    };
  },
  created() {
    this.inputFiles = [];
  },
  computed: {
    error(): string | undefined {
      return undefined;
    },
    fileErrorMessages(): string[] {
      return this.error ? [this.error] : [];
    },
    versionToCreate(): number {
      return this.latest ? this.latest.version + 1 : 0;
    }
  },
  methods: {
    async submit() {
      this.errorSaving = null;
      this.submitting = true;

      try {
        await this.$api.projects.saveAnnotationGuideline({
          projectId: this.project.id,
          uiName: this.ui.name,
          text: '', // TODO Support guideline text?
          files: this.inputFiles,
          version: this.versionToCreate
        });
        this.$emit('refresh');
        this.onClose();
      } catch (err) {
        this.errorSaving = err;
      } finally {
        this.submitting = false;
      }
    },
    onClose() {
      this.showDialog = false;
      (this.$refs.form as HTMLFormElement).reset();
    }
  },
  watch: {}
});
