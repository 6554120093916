

























import Vue, { PropType } from 'vue';
import { ApiException } from '../api/client';

import { DatasetItem } from '../types';

export default Vue.extend({
  props: {
    dataset: {
      required: true,
      type: Object as PropType<DatasetItem>
    },
    closeDialog: {
      required: true,
      type: Function as PropType<() => void>
    }
  },
  data: function() {
    return {
      deleting: false,
      renameError: null as string | null,
      inputValid: false,
      datasetNameRules: [v => !!v || 'Dataset name is required'],
      newDatasetName: ''
    };
  },
  computed: {
    errorMessages(): string[] {
      return (this.renameError && [this.renameError]) || [];
    }
  },
  methods: {
    async submit(): Promise<void> {
      console.log(`Renaming dataset ${this.dataset.id}`);
      this.renameError = null;
      try {
        await this.$api.datasets.renameDataset({
          datasetId: this.dataset.id,
          datasetName: this.newDatasetName
        });
        this.$emit('refresh');
        this.closeDialog();
      } catch (err) {
        if (err instanceof ApiException) {
          this.renameError = err.message;
        } else {
          console.error('Failed renaming', err);
          throw err;
        }
      } finally {
        this.deleting = false;
      }
    },
    onClose(): void {
      this.closeDialog();
    }
  }
});
