import apiClient from './client';

const slug = 'models';

export default {
  async getModel(name: string): Promise<ArrayBuffer> {
    return apiClient.getBinaryData(`/${slug}/${name}`);
  },
  getModelUrl(name: string): string {
    return `/${slug}/${name}`;
  }
};
