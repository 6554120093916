



















































import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  toRefs
} from '@vue/composition-api';
import { whenever } from '@vueuse/core';

import { AnnotationTask } from '@/types';
import { useDeclineDialog } from './functions';
import { TaskAndKind } from '@/layers';

export default defineComponent({
  name: 'DeclineTaskMenu',
  props: {
    task: {
      required: true,
      type: Object as PropType<AnnotationTask>
    }
  },
  setup(props, { emit }) {
    const { task } = toRefs(props);

    const taskAndKind = computed(() => ({
      kind: 'AnnotationTask',
      task: task.value
    })) as ComputedRef<TaskAndKind>;

    const {
      success,
      canDecline,
      closeDialog,
      declining,
      ...submitMenu
    } = useDeclineDialog(taskAndKind);

    const isDisabled = computed(() => !canDecline.value || declining.value);

    whenever(success, function() {
      setTimeout(() => {
        closeDialog();
        emit('refresh');
      }, 1000);
    });

    return { success, isDisabled, closeDialog, declining, ...submitMenu };
  }
});
