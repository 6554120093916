var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-2",attrs:{"loading":_vm.loading}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":15,"sort-by":"version","sort-desc":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-0",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Exports")]),_c('v-spacer')],1)]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('id-item',{attrs:{"id":item.id}})]}},{key:"item.finished_at",fn:function(ref){
var item = ref.item;
return [(_vm.isFinished(item))?_c('date-item',{attrs:{"date":new Date(item.finished_at)}}):_c('processing-icon')]}},{key:"item.storage_uri",fn:function(ref){
var item = ref.item;
return [(_vm.isFinished(item))?_c('id-item',{attrs:{"length":20,"id":item.storage_uri}}):_c('processing-icon')]}},{key:"item.zip_filename",fn:function(ref){
var item = ref.item;
return [(_vm.isFinished(item))?_c('a',{staticClass:"text-truncate",attrs:{"href":item.zip.url,"download":"","filename":item.zip.filename}},[_vm._v(" "+_vm._s(item.zip.filename)+" ")]):_c('processing-icon')]}},(_vm.items.length === 0)?{key:"no-data",fn:function(){return [_vm._v(" No exports ")]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }