














import { defineComponent, toRefs } from '@vue/composition-api';
import { useCreateUserInOrganization } from '@/api/use';
import OpenDialogButton from '@/components/molecules/OpenDialogButton.vue';
import CreateUserCard from '@/components/molecules/CreateUserCard.vue';

export default defineComponent({
  name: 'CreateUserButton',
  components: {
    OpenDialogButton,
    CreateUserCard
  },
  props: {
    organizationId: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const { organizationId } = toRefs(props);

    const { saving, error, createUser } = useCreateUserInOrganization(
      organizationId
    );

    return {
      saving,
      error,
      createUser
    };
  }
});
