
























import { defineComponent, ref, toRefs, watch } from '@vue/composition-api';
import { PropType } from 'vue';
import { ReviewStageDefProps } from './pipeline-types';
import LoadingModal from '@/components/LoadingModal.vue';
import { useOrganizationMembers } from '@/api/use';

export default defineComponent({
  name: 'CreateReviewStage',
  components: {
    LoadingModal
  },
  props: {
    value: {
      type: Object as PropType<ReviewStageDefProps>,
      required: false
    },
    organizationId: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const { organizationId } = toRefs(props);
    const stageDefProps = ref({
      assignee: null as string | null
    } as ReviewStageDefProps);
    const { members, loading, error } = useOrganizationMembers(organizationId);
    const inputValid = ref(false);

    function assigneeValid(value: string) {
      return !!value;
    }

    function emitInput() {
      if (inputValid.value) {
        emit('input', stageDefProps.value);
      } else {
        emit('input', null);
      }
    }

    /** Need to watch both as inputValid.value may still be false when stageDefProps.value changes to valid. */
    watch([stageDefProps, inputValid], emitInput, {
      deep: true,
      immediate: true
    });

    return {
      stageDefProps,
      members,
      loading,
      error,
      inputValid,
      assigneeValid
    };
  }
});
