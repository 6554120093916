































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    view: {
      required: true,
      type: String
    },
    isAdmin: {
      required: true,
      type: Boolean
    }
  }
});
