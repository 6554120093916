














































import Vue, { PropType } from 'vue';
import { Project } from '@/types';

export default Vue.extend({
  props: {
    project: {
      type: Object as PropType<Project>
    }
  },
  data() {
    return {
      showDialog: false,
      datasetName: null as string | null,
      submitting: false,
      error: null as string | null,
      nameErrors: null as string[] | null,
      inputValid: false,
      rules: [val => !!val || 'Dataset name must be non-empty']
    };
  },
  methods: {
    async submit() {
      this.submitting = true;
      this.error = null;
      try {
        const dataset = await this.$api.organizations.createDataset({
          organization_id: this.project.organization.id,
          name: this.datasetName
        });
        await this.$api.projects.linkDataset({
          projectId: this.project.id,
          datasetId: dataset.id
        });
        this.$emit('refresh');
        this.onClose();
      } catch (err) {
        this.error = err;
      } finally {
        this.submitting = false;
      }
    },
    onClose() {
      (this.$refs.form as HTMLFormElement).reset();
      this.showDialog = false;
    }
  }
});
