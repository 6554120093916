







import { defineComponent } from '@vue/composition-api';
import { useSelectedOrganization, useCurrentUser } from '@/store/use';
import { useHasPermission } from '@/api/use';

export default defineComponent({
  name: 'RequirePermission',
  props: {
    permission: {
      required: true,
      type: String
    }
  },
  setup({ permission }, { root }) {
    const { selectedOrganization } = useSelectedOrganization(root.$store);
    const { user } = useCurrentUser(root.$store);

    const { hasPermission, loading, error } = useHasPermission({
      organization: selectedOrganization,
      permission,
      user
    });

    return { selectedOrganization, hasPermission, loading, error, user };
  }
});
