import apiClient from '@/api/client';
import {
  AnnotationConfiguration,
  AnnotationGuideline,
  AnnotationRelease,
  AnnotationProjectMetrics,
  AnnotationTask,
  Dataset,
  Project,
  AnnotationPipeline,
  ProjectStatistics,
  ReviewTask
} from '@/types';

const slug = 'projects';

export default {
  async getProject(projectId: string): Promise<Project> {
    const project = await apiClient.get(`/${slug}/${projectId}`);
    return project;
  },
  async renameProject({
    projectId,
    projectName
  }: {
    projectId: string;
    projectName: string;
  }) {
    return apiClient.patch(`/${slug}/${projectId}`, {
      name: projectName
    });
  },
  async deleteProject({ projectId }: { projectId: string }): Promise<void> {
    await apiClient.delete(`/${slug}/${projectId}`);
  },
  async getProjectDatasets(projectId: string): Promise<Dataset[]> {
    const resJson = await apiClient.get(`/${slug}/${projectId}/datasets`);
    return resJson.datasets;
  },
  async getTasks(projectId: string): Promise<AnnotationTask[]> {
    const resJson = await apiClient.get(`/${slug}/${projectId}/tasks`);
    return resJson.tasks;
  },
  async getReviewTasks(projectId: string): Promise<ReviewTask[]> {
    const resJson = await apiClient.get(`/${slug}/${projectId}/review-tasks`);
    return resJson.review_tasks;
  },
  async getProjectMetrics(
    projectId: string
  ): Promise<AnnotationProjectMetrics> {
    const resJson = await apiClient.get(`/${slug}/${projectId}/metrics`);
    return resJson.metrics;
  },
  async getStatistics({
    projectId
  }: {
    projectId: string;
  }): Promise<ProjectStatistics> {
    const resJson = await apiClient.get(`/${slug}/${projectId}/statistics`);
    return resJson.statistics;
  },
  async getPipelines(projectId: string): Promise<AnnotationPipeline[]> {
    const resJson = await apiClient.get(`/${slug}/${projectId}/pipelines`);
    return resJson.pipelines;
  },
  async createDataset({
    name,
    projectId
  }: {
    name: string;
    projectId: string;
  }) {
    console.log('Creating dataset in project', name, projectId);
    return await apiClient.post(`/${slug}/${projectId}/datasets`, {
      name
    });
  },
  async linkDataset({
    datasetId,
    projectId
  }: {
    datasetId: string;
    projectId: string;
  }) {
    console.log('Linking dataset', datasetId, projectId);
    await apiClient.post(`/project-dataset-memberships`, {
      dataset_id: datasetId,
      project_id: projectId
    });
  },
  async unlinkDataset({
    datasetId,
    projectId
  }: {
    datasetId: string;
    projectId: string;
  }) {
    console.log('Unlinking dataset', datasetId, projectId);
    await apiClient.delete(
      `/project-dataset-memberships?project_id=${projectId}&dataset_id=${datasetId}`
    );
  },
  async getAnnotationReleases({
    projectId
  }: {
    projectId: string;
  }): Promise<AnnotationRelease[]> {
    const res = await apiClient.get(`/${slug}/${projectId}/releases`);
    return res.releases;
  },
  async createReviewTask({
    projectId,
    taskIds,
    assigneeId
  }: {
    projectId: string;
    taskIds: string[];
    assigneeId: string;
  }) {
    const reviewTask = await apiClient.post(
      `/${slug}/${projectId}/review-tasks`,
      {
        assignee_id: assigneeId,
        task_ids: taskIds
      }
    );
    return reviewTask;
  },
  async createAnnotationRelease({
    name,
    description = '',
    projectId,
    taskIds,
    reviewTaskIds
  }: {
    name: string;
    description: string;
    projectId: string;
    taskIds: string[];
    reviewTaskIds: string[];
  }): Promise<AnnotationRelease> {
    const release = await apiClient.post(`/${slug}/${projectId}/releases`, {
      name,
      description,
      task_ids: taskIds,
      review_task_ids: reviewTaskIds
    });
    return release;
  },
  async getLatestAnnotationConfigurationOrUndefined({
    projectId,
    uiName
  }: {
    projectId: string;
    uiName: string;
  }): Promise<AnnotationConfiguration | undefined> {
    console.log(
      `Fetching annotation configuration for project ${projectId} and UI ${uiName}`
    );
    const configPayload = await apiClient.get(
      `/${slug}/${projectId}/uis/${uiName}/configurations?version=latest`
    );
    return configPayload;
  },
  async getAnnotationConfigurations({
    projectId,
    uiName
  }: {
    projectId: string;
    uiName: string;
  }): Promise<AnnotationConfiguration[]> {
    console.log(
      `Fetching annotation configurations for project ${projectId} and UI ${uiName}`
    );
    const configPayload = await apiClient.get(
      `/${slug}/${projectId}/uis/${uiName}/configurations`
    );
    return configPayload.configurations;
  },
  async getAnnotationGuidelines({
    projectId,
    uiName
  }: {
    projectId: string;
    uiName: string;
  }): Promise<AnnotationGuideline[]> {
    console.log(
      `Fetching annotation guidelines for project ${projectId} and UI ${uiName}`
    );
    const configPayload = await apiClient.get(
      `/${slug}/${projectId}/uis/${uiName}/guidelines`
    );
    return configPayload.guidelines;
  },
  async saveAnnotationConfiguration({
    projectId,
    uiName,
    parameters,
    version
  }: {
    projectId: string;
    uiName: string;
    parameters: Record<string, any>;
    version: number;
  }): Promise<AnnotationConfiguration> {
    console.log(
      `Putting annotation configuration for project ${projectId}, ui ${uiName}, uiConfig ${JSON.stringify(
        parameters
      )}, revision ${version}`
    );

    const maybeGuideline = {}; // TODO Guideline version

    const body = { parameters, ...maybeGuideline };
    const configPayload = await apiClient.put(
      `/${slug}/${projectId}/uis/${uiName}/configurations?version=${version}`,
      body
    );
    return configPayload;
  },
  async getLatestAnnotationGuidelineOrUndefined({
    projectId,
    uiName
  }: {
    projectId: string;
    uiName: string;
  }): Promise<AnnotationGuideline | undefined> {
    console.log(
      `Fetching annotation guideline for project ${projectId} and UI ${uiName}`
    );
    const configPayload = await apiClient.get(
      `/${slug}/${projectId}/uis/${uiName}/guidelines?version=latest`
    );
    return configPayload;
  },
  async saveAnnotationGuideline({
    projectId,
    uiName,
    text,
    files,
    version
  }: {
    projectId: string;
    uiName: string;
    text: string;
    files: File[];
    version: number;
  }): Promise<AnnotationGuideline> {
    console.log(
      `Uploading ${files.length} files to project ${projectId} for ${uiName}`
    );
    const endpoint = `/${slug}/${projectId}/uis/${uiName}/guidelines`;
    const formData = new FormData();
    formData.append('text', text);
    formData.append('version', version.toString());
    for (const file of files) {
      formData.append('files', file);
    }
    const res = await apiClient.post(endpoint, formData);
    return res;
  },
  async createPipeline({
    projectId,
    batchId,
    definition
  }: {
    projectId: string;
    batchId: string;
    definition: any; // TODO
  }): Promise<AnnotationPipeline> {
    console.log(
      `Creating pipeline for project ${projectId} and batch ${batchId}`
    );
    const pipeline = await apiClient.post(`/${slug}/${projectId}/pipelines`, {
      batch_id: batchId,
      definition
    });
    return pipeline;
  }
};
