import * as z from 'zod';
import { AssetMedia } from '@/types';
import _isEmpty from 'lodash/isEmpty';

export interface TextTranslationAnnotationLabel {
  correctText: string | null;
}

export interface TextTranslationAnnotation {
  labels: TextTranslationAnnotationLabel[];
}

export type SaveTextTranslationAnnotationLabels = () => void;

export const TextTranslationParameterSchema = z.object({
  originalTextAttribute: z.string(),
  translatedTextAttribute: z.string()
});

export type TextTranslationParameters = z.infer<
  typeof TextTranslationParameterSchema
>;
export const DEFAULT_CONFIG: TextTranslationParameters = {
  originalTextAttribute: 'text',
  translatedTextAttribute: 'translated_text'
};

const checkParameters = (params: any): params is TextTranslationParameters => {
  try {
    return !!TextTranslationParameterSchema.parse(params);
  } catch (err) {
    console.error(`Invalid parameters`, params);
    return false;
  }
};

export const resolveParameters = (params: any): TextTranslationParameters => {
  if (!_isEmpty(params) && !checkParameters(params)) {
    console.warn('Invalid parameters obtained, proceed with caution');
  }
  return { ...DEFAULT_CONFIG, ...params };
};

export interface TextMediaSlugs {
  [key: string]: AssetMedia;
}

export const findTextMediaSlugs = (
  medias: Record<string, AssetMedia>
): TextMediaSlugs => {
  const keys = {};
  for (const [k, v] of Object.entries(medias)) {
    if (k.toLowerCase().endsWith('_text') || k.toLowerCase() == 'text') {
      keys[k] = v;
    }
  }
  return keys;
};

export type ActiveText = {
  originalText: string;
  translatedText: string;
};

export const addLineBreaks = (s: string): string => {
  return s.replace(/<br \/>/g, '\n').replace(/\\n/g, '\n');
};
