






































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'SettingsNav',
  components: {},
  props: {},
  data: () => ({
    items: [
      {
        text: 'Account',
        icon: ' mdi-account-circle-outline',
        route: { name: 'SettingsAccount' }
      },
      {
        text: 'Personal access tokens',
        icon: ' mdi-key-variant',
        route: { name: 'PersonalAccessTokens' }
      }
    ]
  }),
  computed: {
    ...mapGetters('auth', ['currentUser'])
  },
  methods: {
    navigateBack(): void {
      this.$router.back();
    }
  }
});
