






































import useApi from '@/api/useApi';
import { computed, defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'CreateOrganization',
  setup(props, { root: { $store, $router } }) {
    const inputValid = ref(false);
    const submitting = ref(false);
    const success = ref(false);
    const error = ref(null as Error | null);

    const name = ref('');

    const rules = {
      required: (value: string) => !!value || 'Required.'
    };

    const canSubmit = computed(() => inputValid.value);

    const api = useApi();

    const routeToHome = { name: 'Home' };
    const nextRoute = { name: 'CreateProject' };

    async function submit() {
      if (!inputValid.value) {
        return;
      }

      error.value = null;
      submitting.value = true;

      try {
        const organization = await api.organizations.createOrganization({
          name: name.value
        });
        success.value = true;
        setTimeout(async function() {
          await $store.dispatch('organizations/getUserOrganizations');
          await $store.dispatch(
            'organizations/setSelectedOrganization',
            organization
          );
          $router.push(nextRoute);
        }, 2000);
      } catch (err) {
        error.value = err;
      } finally {
        submitting.value = false;
      }
    }

    const errors = computed(() =>
      [error].filter(err => !!err.value).map(err => err.value)
    );

    return {
      inputValid,
      submit,
      submitting,
      nextRoute,
      rules,
      name,
      success,
      canSubmit,
      errors,
      routeToHome
    };
  }
});
