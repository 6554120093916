



























































import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRefs
} from '@vue/composition-api';
import { or, whenever } from '@vueuse/core';

import { useOrganizationMembers } from '@/api/use';
import { useCurrentUser } from '@/store/use';
import { AnnotationTask, CompactUser } from '@/types';
import useApi from '@/api/useApi';

export default defineComponent({
  props: {
    task: {
      required: true,
      type: Object as PropType<AnnotationTask>
    },
    closeDialog: {
      required: true,
      type: Function as PropType<() => void>
    }
  },
  setup(props, { emit, root: { $store: store } }) {
    const { task } = toRefs(props);
    const closeDialog = props.closeDialog;
    const assignee = computed(() => task.value.annotator);
    const organizationId = computed(() => task.value.organization.id);
    const {
      members: availableAssignees,
      loading,
      error: errorLoadingMembers
    } = useOrganizationMembers(organizationId);
    const { user } = useCurrentUser(store);

    const selectedAssignee = ref<CompactUser>(assignee.value);

    function getVisibleName(member: CompactUser): string {
      return member.id === user.value?.id ? `${member.name} (me)` : member.name;
    }

    const inputValid = ref(false);
    const success = ref(false);
    const errorSubmitting = ref<Error>(null);
    const submitting = ref(false);

    const api = useApi();

    const hasUnsavedChanges = computed(
      () => selectedAssignee.value.id !== assignee.value.id
    );

    async function submit() {
      if (success.value) {
        return;
      }
      submitting.value = true;
      errorSubmitting.value = null;
      try {
        await api.tasks.editTask({
          taskId: task.value.id,
          assigneeEmail: selectedAssignee.value.email
        });
        success.value = true;
      } catch (err) {
        console.error(`Error editing task`, err);
        errorSubmitting.value = err;
      } finally {
        submitting.value = false;
      }
    }

    whenever(success, function() {
      setTimeout(() => {
        closeDialog();
        emit('refresh');
      }, 1500);
    });

    const error = or(errorLoadingMembers, errorSubmitting);

    return {
      availableAssignees,
      error,
      loading,
      getVisibleName,
      selectedAssignee,
      inputValid,
      submit,
      success,
      submitting,
      hasUnsavedChanges
    };
  }
});
