import {
  Project,
  Dataset,
  CompactUser,
  Job,
  OrganizationMember
} from '@/types';
import apiClient from './client';
import ProjectsApi from './projects';
import _includes from 'lodash/includes';

const slug = 'organizations';

const getOrganizationProjects = (orgId: string): Promise<Project[]> => {
  return apiClient.get(`/${slug}/${orgId}/projects`);
};

const getOrganizationDatasets = async (orgId: string): Promise<Dataset[]> => {
  const responseJson = await apiClient.get(`/${slug}/${orgId}/datasets`);
  return responseJson.datasets;
};

const getOrganizationMembers = (orgId: string): Promise<CompactUser[]> => {
  return apiClient.get(`/${slug}/${orgId}/members`);
};

const testPermissions = async ({
  organizationId,
  permissions
}: {
  organizationId: string;
  permissions: string[];
}): Promise<string[]> => {
  const payload = { permissions };
  const resJson = await apiClient.post(
    `/${slug}/${organizationId}/test-permissions`,
    payload
  );
  console.debug(`Permissions response`, resJson);
  return resJson.permissions;
};

export default {
  async addUserAsMember({
    email,
    organizationId
  }: {
    email: string;
    organizationId: string;
  }) {
    const body = { email };
    return apiClient.post(`/${slug}/${organizationId}/members`, body);
  },
  async removeUserAsMember({
    email,
    organizationId
  }: {
    email: string;
    organizationId: string;
  }) {
    return apiClient.delete(
      `/${slug}/${organizationId}/members?email=${email}`
    );
  },
  getOrgMembers(orgId: string): Promise<OrganizationMember[]> {
    return apiClient.get(`/${slug}/${orgId}/members`);
  },
  createOrganization(organization) {
    return apiClient.post(`/${slug}/`, organization);
  },
  getOrganization(organizationId: string) {
    return apiClient.get(`/${slug}/${organizationId}`);
  },
  async getOrganizationDatasets(organizationId: string): Promise<Dataset[]> {
    const responseJson = await apiClient.get(
      `/${slug}/${organizationId}/datasets`
    );
    return responseJson.datasets;
  },
  async getProjectsAndTheirDatasets(organizationId: string) {
    const projects = await getOrganizationProjects(organizationId);

    const projectsWithDatasets = await Promise.all(
      projects.map(async proj => {
        const datasets = await ProjectsApi.getProjectDatasets(proj.id);
        return { ...proj, datasets };
      })
    );
    return projectsWithDatasets;
  },
  async extendOrganization(organization) {
    return {
      ...organization,
      projects: await this.getProjectsAndTheirDatasets(organization.id),
      members: await getOrganizationMembers(organization.id),
      datasets: await getOrganizationDatasets(organization.id)
    };
  },
  async getJobs({
    organizationId
  }: {
    organizationId: string;
  }): Promise<Job[]> {
    const jobsResponse = await apiClient.get(`/${slug}/${organizationId}/jobs`);
    return jobsResponse.jobs;
  },
  createProject({ organization_id, ...project }): Promise<Project> {
    return apiClient.post(`/${slug}/${organization_id}/projects`, project);
  },
  createDataset({ organization_id, ...dataset }): Promise<Dataset> {
    return apiClient.post(`/${slug}/${organization_id}/datasets`, dataset);
  },
  getOrganizationProjects,
  testPermissions,
  async testPermission({
    organizationId,
    permission
  }: {
    organizationId: string;
    permission: string;
  }): Promise<boolean> {
    const allowedActions = await testPermissions({
      organizationId,
      permissions: [permission]
    });

    return _includes(allowedActions, permission);
  },
  createAdminStatus({
    userId,
    organizationId
  }: {
    userId: string;
    organizationId: string;
  }) {
    return apiClient.post(`/${slug}/${organizationId}/admins`, {
      user_id: userId
    });
  },
  deleteAdminStatus({
    userId,
    organizationId
  }: {
    userId: string;
    organizationId: string;
  }) {
    return apiClient.delete(
      `/${slug}/${organizationId}/admins?user_id=${userId}`
    );
  },
  createUserInOrganization({
    name,
    email,
    password,
    organizationId
  }: {
    name: string;
    email: string;
    password: string;
    organizationId: string;
  }) {
    return apiClient.post(`/${slug}/${organizationId}/users`, {
      name,
      email,
      password
    });
  }
};
