

















































































import Vue, { PropType } from 'vue';
import CreateResourceButton from '@/components/CreateResourceButton.vue';
import EditProjectCard from '@/components/EditProjectCard.vue';
import DeleteProjectCard from '@/components/DeleteProjectCard.vue';
import { Project } from '@/types';
import OpenDialogButton from '@/components/molecules/OpenDialogButton.vue';
import DateItem from '@/components/molecules/DateItem.vue';
import IdItem from '@/components/molecules/IdItem.vue';

type CreateProjectFn = ({
  name,
  organization_id // eslint-disable-line
}: {
  name: string;
  organization_id: string;
}) => Promise<void>;

export default Vue.extend({
  components: {
    CreateResourceButton,
    EditProjectCard,
    DeleteProjectCard,
    OpenDialogButton,
    DateItem,
    IdItem
  },
  data: () => ({
    createProjectFields: [
      {
        name: 'name',
        label: `Name of the project. For example: 'Sentiment classification' or 'Person detection'.`
      }
    ],
    projectHeaders: [
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'name'
      },
      {
        text: 'ID',
        align: 'start',
        sortable: true,
        value: 'id'
      },
      {
        text: 'Created by',
        value: 'createdBy',
        sortable: false
      },
      {
        text: 'Created at',
        value: 'createdAt',
        sortable: false
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        align: 'center'
      }
    ]
  }),
  props: {
    organizationId: {
      required: true,
      type: String
    },
    projects: {
      required: true,
      type: Array as PropType<Array<Project>>
    },
    onCreateProject: {
      required: true,
      type: Function as PropType<CreateProjectFn>
    }
  },
  computed: {
    projectItems(): Record<string, any>[] {
      return this.projects.map(project => ({
        ...project,
        name: project.name,
        id: project.id,
        createdBy: project.created_by ? project.created_by.name : undefined,
        createdAtRaw: project.created_at,
        mDatasets: project.dataset_count
      }));
    },
    noProjects(): boolean {
      return this.projects.length === 0;
    }
  },
  methods: {
    toProject(item: any) {
      return {
        name: 'ProjectTasks',
        params: {
          orgId: this.organizationId,
          projectId: item.id
        }
      };
    },
    async createProject({ name }) {
      await this.onCreateProject({
        name,
        organization_id: this.organizationId  // eslint-disable-line
      });
    }
  }
});
