var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"silo-dark white--text app-bar",attrs:{"app":"","clipped-left":"","height":"50"}},[_c('div',{staticClass:"d-flex align-center"},[_c('router-link',{staticClass:"my-link custom-link custom-tab",attrs:{"to":"/"}},[_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"Silo AI Logo","contain":"","src":require("../assets/Silo-stamp-white.png"),"width":"25"}})],1)],1)],1),(_vm.selectedOrganization)?_c('div',{staticClass:"current-organisation ml-2"},[_c('div',{staticClass:"font-weight-medium"},[_c('p',{staticClass:"font-weight-bold text-uppercase",domProps:{"textContent":_vm._s(_vm.selectedOrganization.name)}})])]):_vm._e(),_c('v-spacer'),_c('require-permission',{staticClass:"ml-2",attrs:{"permission":"organizations.listDatasets"}},[_c('router-link',{staticClass:"custom-link custom-tab",class:{ 'grey--text': _vm.noOrganizations },attrs:{"to":{ name: 'Datasets', params: { orgId: _vm.selectedOrganizationId } },"event":!!_vm.selectedOrganization ? 'click' : ''}},[_c('nav-tooltip',{attrs:{"disabled":!!_vm.selectedOrganization}},[_vm._v(" Datasets ")])],1)],1),_c('require-permission',{staticClass:"ml-2",attrs:{"permission":"organizations.listProjects"}},[_c('router-link',{staticClass:"custom-link custom-tab",class:{ 'grey--text': _vm.noOrganizations },attrs:{"to":{ name: 'Projects', params: { orgId: _vm.selectedOrganizationId } },"event":!!_vm.selectedOrganization ? 'click' : ''}},[_c('nav-tooltip',{attrs:{"disabled":!!_vm.selectedOrganization}},[_vm._v(" Projects ")])],1)],1),_c('require-permission',{staticClass:"ml-2",attrs:{"permission":"organizations.listMembers"}},[_c('router-link',{staticClass:"custom-link custom-tab",class:{ 'grey--text': _vm.noOrganizations },attrs:{"to":{
        name: 'OrganizationUsers',
        params: { orgId: _vm.selectedOrganizationId }
      },"event":!!_vm.selectedOrganization ? 'click' : ''}},[_c('nav-tooltip',{attrs:{"disabled":!!_vm.selectedOrganization}},[_vm._v(" Users ")])],1)],1),_c('v-divider',{staticClass:"ml-4",attrs:{"vertical":"","color":"white","inset":""}}),_c('require-permission',{attrs:{"permission":"organizations.listJobs"}},[_c('v-menu',{attrs:{"transition":"slide-x-transition","offset-y":"","eager":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var menu = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary elevation-0"}},'v-btn',attrs,false),Object.assign({}, menu)),[_c('v-badge',{attrs:{"value":_vm.notifications,"color":"blue","overlap":"","small":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white","size":10,"width":1}})]},proxy:true}],null,true)},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-bell ")])],1)],1)]}}])},[(_vm.selectedOrganization)?_c('jobs-notifications',{attrs:{"user":_vm.currentUser,"organization":_vm.selectedOrganization,"notifications":_vm.notifications},on:{"update:notifications":function($event){_vm.notifications=$event}}}):_vm._e()],1)],1),(_vm.currentUser)?_c('div',[_c('v-menu',{attrs:{"transition":"slide-x-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-0 px-0",attrs:{"color":"transparent elevation-0"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-account-circle ")]),_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,1585871150)},[_c('v-list',[_c('v-list-item',{staticClass:"display-user",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"line-gray--text text-h6 text-uppercase",domProps:{"textContent":_vm._s(_vm.currentUser.name)}}),_c('v-list-item-subtitle',{staticClass:"line-gray--text",domProps:{"textContent":_vm._s(_vm.currentUser.email)}})],1)],1),_c('v-divider'),_vm._l((_vm.menuItems),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.route,"disabled":item.name === 'create_organization' && !_vm.canCreateOrganization}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)})],2)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }