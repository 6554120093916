



















































import Vue from 'vue';
import _differenceBy from 'lodash/differenceBy';
import _intersectionBy from 'lodash/intersectionBy';
import { ApiException } from '@/api/client';
import CreateDatasetInProject from './CreateDatasetInProject.vue';
import LinkDatasetToProjectButton from '../LinkDatasetToProjectButton.vue';
import UnlinkDatasetFromProjectButton from '../UnlinkDatasetFromProjectButton.vue';
import LoadingModal from '../LoadingModal.vue';
import ProjectDatasetItem from './ProjectDatasetItem.vue';

import { AnnotationPipeline, Dataset, Project } from '@/types';

export default Vue.extend({
  components: {
    LoadingModal,
    CreateDatasetInProject,
    LinkDatasetToProjectButton,
    UnlinkDatasetFromProjectButton,
    ProjectDatasetItem
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    orgId: {
      type: String,
      required: true
    }
  },
  computed: {},
  created() {
    this.fetchData();
  },
  data: function() {
    return {
      loading: false,
      error: null as string | null,
      project: null as Project | null,
      projectDatasets: null as Dataset[] | null,
      projectPipelines: null as AnnotationPipeline[] | null,
      organizationDatasets: null as Dataset[] | null,
      linkableDatasets: null as Dataset[] | null,
      unlinkableDatasets: null as Dataset[] | null
    };
  },
  methods: {
    projectPipelinesForDataset(datasetId: string): AnnotationPipeline[] {
      if (!this.projectPipelines) {
        return [];
      }
      return this.projectPipelines.filter(
        pipeline => pipeline.dataset.id == datasetId
      );
    },
    async fetchData() {
      this.loading = true;
      this.error = null;
      try {
        const project = await this.$api.projects.getProject(this.projectId);
        this.project = project;
        this.projectDatasets = await this.$api.projects.getProjectDatasets(
          this.projectId
        );
        this.projectPipelines = await this.$api.projects.getPipelines(
          this.projectId
        );
        this.organizationDatasets = await this.$api.organizations.getOrganizationDatasets(
          this.orgId
        );
        this.linkableDatasets = _differenceBy(
          this.organizationDatasets,
          this.projectDatasets,
          dataset => dataset.id
        );
        this.unlinkableDatasets = _intersectionBy(
          this.organizationDatasets,
          this.projectDatasets,
          dataset => dataset.id
        );
      } catch (err) {
        if (!(err instanceof ApiException)) {
          console.error(err);
        }
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
    async onLinkDataset({ projectId, datasetId }) {
      console.log('Linking dataset', datasetId, projectId);
      await this.$api.projects.linkDataset({
        datasetId,
        projectId
      });
      await this.fetchData();
    }
  },
  watch: {
    projectId: function() {
      this.fetchData();
    }
  }
});
