import TimeSeriesClassifier from '@/components/TimeSeriesClassification';
import TextClassifier from '@/components/TextClassification';
import ImageAnnotator from '@/components/ImageAnnotator';
import ImageClassification from '@/components/ImageClassification';
import TextTranslation from '@/components/TextTranslation/';
// import VideoAnnotation from '@/components/VideoAnnotation';

export default [
  TimeSeriesClassifier,
  TextClassifier,
  ImageAnnotator,
  ImageClassification,
  TextTranslation
  // Hide video annotation until it's usable or in active development
  // VideoAnnotation
];
