import {
  computed,
  reactive,
  Ref,
  ref,
  toRefs,
  unref,
  watch
} from '@vue/composition-api';
import _find from 'lodash/find';

import {
  Dataset,
  Organization,
  AnnotationTask,
  CompactUser,
  Project,
  ProjectStatistics,
  Asset,
  ReviewTask,
  AnnotationConfiguration,
  AnnotationGuideline,
  AnnotatedAsset,
  AssetInTask,
  UserPreferences,
  PlatformPermissions,
  Batch,
  OrganizationMember,
  PersonalAccessToken,
  AnnotationRelease,
  AnnotationReleaseExport
} from '@/types';
import { ApiError, BadRequest } from '@/api/client';
import useApi from './useApi';
import { AnnotatorApi } from '.';
import { TaskAndKind, TaskKind } from '@/layers';

export const usePlatformPermissions = (user: Ref<CompactUser>) => {
  const api = useApi();
  const fetcher = () => api.me.getPlatformPermissions();

  const platformPermissions = ref(null as PlatformPermissions);
  const loading = ref(false);
  const error = ref(null as Error | null);

  async function fetchData() {
    if (!user.value) {
      platformPermissions.value = null;
      return;
    }

    loading.value = true;
    error.value = null;
    console.log(`Fetching platform permissions..`);
    try {
      platformPermissions.value = await fetcher();
    } catch (err) {
      console.error(`Failed platform permissions`, err);
      error.value = err;
    } finally {
      loading.value = false;
    }
  }

  fetchData();
  watch(user, fetchData);

  return { loading, error, mutate: fetchData, platformPermissions };
};

export const useUserPreferences = (user: Ref<CompactUser>) => {
  const api = useApi();
  const fetcher = () => api.me.getPreferences();

  const userPreferences = ref(null as UserPreferences);
  const loading = ref(false);
  const error = ref(null as Error | null);

  async function fetchData() {
    if (!user.value) {
      userPreferences.value = null;
      return;
    }

    loading.value = true;
    error.value = null;
    console.log(`Fetching preferences..`);
    try {
      userPreferences.value = await fetcher();
    } catch (err) {
      console.error(`Failed loading organizations`, err);
      error.value = err;
    } finally {
      loading.value = false;
    }
  }

  fetchData();
  watch(user, fetchData);

  return { loading, error, mutate: fetchData, userPreferences };
};

export const useOrganizationDatasets = (organizationId: Ref<string>) => {
  const api = useApi();
  const fetcher = (organizationId: string) =>
    api.organizations.getOrganizationDatasets(organizationId);

  const datasets = ref([] as Dataset[]);
  const loading = ref(false);
  const error = ref(null as Error | null);

  async function fetchData() {
    error.value = null;
    loading.value = true;
    try {
      datasets.value = await fetcher(organizationId.value);
    } catch (err) {
      console.error(`Failed loading datasets`, err);
      error.value = err;
    } finally {
      loading.value = false;
    }
  }

  watch(organizationId, fetchData);

  fetchData();

  return { loading, error, mutate: fetchData, datasets };
};

export const useOrganizationProjects = (organizationId: Ref<string>) => {
  const api = useApi();
  const fetcher = (organizationId: string) =>
    api.organizations.getOrganizationProjects(organizationId);

  const projects = ref([] as Project[]);
  const loading = ref(false);
  const error = ref(null as Error | null);

  async function fetchData() {
    error.value = null;
    loading.value = true;

    if (!organizationId.value) {
      projects.value = [];
      loading.value = false;
      return;
    }

    try {
      projects.value = await fetcher(organizationId.value);
    } catch (err) {
      console.error(`Failed loading projects`, err);
      error.value = err;
    } finally {
      loading.value = false;
    }
  }

  watch(organizationId, fetchData, { immediate: true });

  return { loading, error, mutate: fetchData, projects };
};

export const useOrganizationMembers = (organizationId: Ref<string>) => {
  const api = useApi();
  const fetcher = (organizationId: string) =>
    api.organizations.getOrgMembers(organizationId);

  const state = reactive({
    members: [] as OrganizationMember[],
    loading: false,
    error: null as Error | null
  });

  async function fetchData() {
    state.error = null;
    state.loading = true;
    try {
      state.members = await fetcher(organizationId.value);
    } catch (err) {
      console.error(`Failed loading members`, err);
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch(organizationId, fetchData, { immediate: true });

  return { ...toRefs(state), mutate: fetchData };
};

export const useAddUserToOrganization = (organizationId: Ref<string>) => {
  const api = useApi();
  const state = reactive({
    saving: false,
    error: null as Error | null
  });
  /** Add user to organization */
  async function addUser({ email }: { email: string }) {
    state.error = null;
    state.saving = true;
    try {
      await api.organizations.addUserAsMember({
        email,
        organizationId: organizationId.value
      });
    } catch (err) {
      state.error = err;
    } finally {
      state.saving = false;
    }
  }

  return { ...toRefs(state), addUser };
};

export const useCreateUserInOrganization = (organizationId: Ref<string>) => {
  const api = useApi();
  const state = reactive({
    saving: false,
    error: null as Error | null
  });
  /** Add user to organization */
  async function createUser({
    name,
    email,
    password
  }: {
    email: string;
    name: string;
    password: string;
  }) {
    state.error = null;
    state.saving = true;
    try {
      await api.organizations.createUserInOrganization({
        name,
        email,
        password,
        organizationId: organizationId.value
      });
    } catch (err) {
      state.error = err;
    } finally {
      state.saving = false;
    }
  }

  return { ...toRefs(state), createUser };
};

export const useManageAdminUsers = (organizationId: Ref<string>) => {
  const api = useApi();
  const state = reactive({
    saving: false,
    error: null as Error | null
  });
  async function createAdminStatus({ userId }: { userId: string }) {
    state.error = null;
    state.saving = true;
    try {
      await api.organizations.createAdminStatus({
        userId,
        organizationId: organizationId.value
      });
    } catch (err) {
      state.error = err;
    } finally {
      state.saving = false;
    }
  }

  async function deleteAdminStatus({ userId }: { userId: string }) {
    state.error = null;
    state.saving = true;
    try {
      await api.organizations.deleteAdminStatus({
        userId,
        organizationId: organizationId.value
      });
    } catch (err) {
      state.error = err;
    } finally {
      state.saving = false;
    }
  }

  return { ...toRefs(state), createAdminStatus, deleteAdminStatus };
};

export const useCurrentUserOrganizations = (user: Ref<CompactUser>) => {
  const api = useApi();
  const fetcher = () => api.me.getOrganizations();

  const organizations = ref([] as Organization[]);
  const loading = ref(false);
  const error = ref(null as Error | null);

  async function fetchData() {
    if (!user.value) {
      organizations.value = [];
      return;
    }
    error.value = null;
    loading.value = true;
    console.log(`Fetching organizations..`);
    try {
      organizations.value = await fetcher();
    } catch (err) {
      console.error(`Failed loading organizations`, err);
      error.value = err;
    } finally {
      loading.value = false;
    }
  }

  fetchData();

  watch(user, fetchData);

  return { loading, error, mutate: fetchData, organizations };
};

export const useCurrentUserTasks = (user: Ref<CompactUser>) => {
  const api = useApi();
  const fetcher = () => api.me.getTasks();

  const data = ref([] as AnnotationTask[]);
  const loading = ref(false);
  const error = ref(null as Error | null);

  async function fetchData() {
    if (!user.value) {
      data.value = [];
      return;
    }
    error.value = null;
    loading.value = true;
    console.log(`Fetching tasks..`);
    try {
      data.value = await fetcher();
    } catch (err) {
      console.error(`Failed loading tasks`, err);
      error.value = err;
    } finally {
      loading.value = false;
    }
  }

  fetchData();
  watch(user, fetchData);

  return { loading, error, mutate: fetchData, tasks: data };
};

export const useCurrentUserReviewTasks = (user: Ref<CompactUser>) => {
  const api = useApi();
  const fetcher = () => api.me.getReviewTasks();

  const data = ref([] as ReviewTask[]);
  const loading = ref(false);
  const error = ref(null as Error | null);

  async function fetchData() {
    if (!user.value) {
      data.value = [];
      return;
    }
    error.value = null;
    loading.value = true;
    console.log(`Fetching review tasks..`);
    try {
      data.value = await fetcher();
    } catch (err) {
      console.error(`Failed loading review tasks`, err);
      error.value = err;
    } finally {
      loading.value = false;
    }
  }

  fetchData();
  watch(user, fetchData);

  return { loading, error, mutate: fetchData, review_tasks: data };
};

export const useHasPermission = ({
  organization,
  user,
  permission
}: {
  organization: Ref<Organization | undefined>;
  user: Ref<CompactUser>;
  permission: string;
}) => {
  const api = useApi();
  const state = reactive({
    error: null as Error | null,
    loading: false,
    hasPermission: false,
    loadedForUser: null as CompactUser | null
  });

  async function mutate() {
    state.loading = true;
    state.error = null;
    state.loadedForUser = null;

    if (!user.value) {
      state.hasPermission = false;
      state.loading = false;
      state.loadedForUser = null;
      return;
    }

    if (!organization.value) {
      console.log(`No organization selected, skipping checking permission`);
      state.hasPermission = false;
      state.loading = false;
      state.loadedForUser = user.value;
      return;
    }

    try {
      console.debug(
        `Checking permission ${permission} on organization ${organization.value.id}`
      );
      state.hasPermission = await api.organizations.testPermission({
        organizationId: organization.value.id,
        permission
      });
      state.loadedForUser = user.value;
      console.debug(
        `Got response ${state.hasPermission} for permission ${permission}`
      );
    } catch (err) {
      console.error(`Error checking permissions`, err);
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([organization, user], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export function useIsAdmin({
  organization,
  user
}: {
  organization: Ref<Organization | undefined>;
  user: Ref<CompactUser>;
}) {
  const { hasPermission: isAdmin, loading, error } = useHasPermission({
    organization,
    user,
    permission: 'organizations.get'
  });
  return { isAdmin, loading, error };
}

export const useBatchTasks = ({ batchId }: { batchId: Ref<string> }) => {
  const api = useApi();
  const state = reactive({
    error: null as Error | null,
    loading: false,
    tasks: [] as AnnotationTask[]
  });

  async function mutate() {
    state.loading = true;
    state.error = null;

    try {
      console.debug(`Fetching tasks for batch ${batchId.value}`);
      state.tasks = await api.batches.getTasksInBatch({
        batchId: batchId.value
      });
      console.debug(
        `Got ${state.tasks.length} tasks for batch ${batchId.value}`
      );
    } catch (err) {
      console.error(`Error downloading tasks`, err);
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([batchId], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export const useProjectStatistics = ({
  projectId
}: {
  projectId: Ref<string>;
}) => {
  const api = useApi();
  const state = reactive({
    error: null as Error | null,
    loading: false,
    statistics: null as ProjectStatistics | null
  });

  async function mutate() {
    state.loading = true;
    state.error = null;

    try {
      console.debug(`Fetching statistics for project ${projectId.value}`);
      state.statistics = await api.projects.getStatistics({
        projectId: projectId.value
      });
    } catch (err) {
      console.error(`Error downloading statistics`, err);
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([projectId], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export const useTask = ({ taskId }: { taskId: Ref<string> }) => {
  const api = useApi();
  const state = reactive({
    error: null as Error | null,
    loading: false,
    task: null as AnnotationTask | null
  });

  async function mutate() {
    state.loading = true;
    state.error = null;

    try {
      console.debug(`Fetching task ${taskId.value}`);
      state.task = await api.tasks.getTask({
        taskId: taskId.value
      });
    } catch (err) {
      console.error(`Error downloading task`, err);
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([taskId], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

/** At the moment **works only for annotation tasks** */
export const useDeclineTask = ({
  taskAndKind
}: {
  taskAndKind: Ref<TaskAndKind>;
}) => {
  const state = reactive({
    error: null as Error | null,
    loading: false,
    success: false
  });
  const api = useApi();
  async function declineTask(reason: string) {
    state.loading = true;
    state.error = null;
    state.success = false;
    if (!taskAndKind.value) {
      return;
    }

    if (taskAndKind.value.kind !== 'AnnotationTask') {
      throw Error(`Cannot decline task other than AnnotationTask yet`);
    }

    try {
      const taskId = taskAndKind.value.task.id;
      console.debug('Declining task submission for task', taskId);
      await api.tasks.declineTask({ taskId, reason });
      state.success = true;
    } catch (err) {
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  return { ...toRefs(state), declineTask };
};

export const useSubmitTask = (taskId: Ref<string>) => {
  const state = reactive({
    error: null as Error | null,
    loading: false,
    success: false
  });
  const api = useApi();
  async function submitTask() {
    state.loading = true;
    state.error = null;
    state.success = false;
    try {
      console.debug('Submitting task', taskId.value);
      await api.tasks.submitTask({ taskId: taskId.value });
      state.success = true;
    } catch (err) {
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  return { ...toRefs(state), submitTask };
};

export type AsyncOperation<I extends any[], O> = (
  api: AnnotatorApi,
  ...i: I
) => Promise<O>;

export const useAsyncOperation = <I extends any[], O>({
  operation
}: {
  operation: AsyncOperation<I, O>;
}) => {
  const state = reactive({
    error: null as Error | null,
    loading: false,
    success: false
  });

  const api = useApi();

  async function execute(...i: I) {
    state.error = null;
    state.loading = true;
    state.success = false;
    try {
      console.debug('Executing operation');
      const result = await operation(api, ...i);
      state.success = true;
      return result;
    } catch (err) {
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  return { ...toRefs(state), execute };
};

export const useSubmitGenericTask = (taskAndKind: Ref<TaskAndKind>) => {
  const task = computed(() => taskAndKind.value?.task);
  const taskId = computed(() => task.value?.id);

  const operation = async (api: AnnotatorApi) => {
    if (!taskId.value) {
      return;
    }

    const kind = taskAndKind.value.kind;
    console.debug(`Submitting ${kind}`, taskId.value);
    if (kind === 'AnnotationTask') {
      await api.tasks.submitTask({ taskId: taskId.value });
    } else if (kind === 'ReviewTask') {
      await api.reviewTasks.submitTask({ taskId: taskId.value });
    } else {
      throw new Error(`Unknown task kind: ${kind}, cannot submit`);
    }
  };

  const { execute: submitTask, ...state } = useAsyncOperation({
    operation
  });

  return { ...state, submitTask };
};

export function useCreateReviewTask({
  projectId,
  taskIds,
  assigneeId
}: {
  projectId: Ref<string>;
  taskIds: Ref<string[]>;
  assigneeId: Ref<string>;
}) {
  const state = reactive({
    creating: false,
    error: null as Error | null,
    success: false
  });

  const api = useApi();

  async function create() {
    state.success = false;

    if (
      !projectId.value ||
      !taskIds.value ||
      taskIds.value.length === 0 ||
      !assigneeId.value
    ) {
      state.error = new Error(
        `Cannot create review task when no tasks or assignee given`
      );
      return;
    }

    state.creating = true;

    try {
      await api.projects.createReviewTask({
        projectId: projectId.value,
        taskIds: taskIds.value,
        assigneeId: assigneeId.value
      });
      state.success = true;
    } catch (err) {
      state.error = err;
    } finally {
      state.creating = false;
    }
  }

  return { ...toRefs(state), create };
}

export const useBatchAssets = ({ batchId }: { batchId: Ref<string> }) => {
  const api = useApi();
  const state = reactive({
    error: null as Error | null,
    loading: false,
    assets: [] as Asset[] | null
  });

  async function mutate() {
    state.error = null;

    if (!batchId.value) {
      state.assets = [];
      return;
    }

    state.loading = true;

    try {
      console.debug(`Fetching assets for batch ${batchId.value}`);
      const assets = await api.batches.getAssets({
        batchId: batchId.value
      });
      state.assets = assets;
    } catch (err) {
      console.error(`Error downloading assets`, err);
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([batchId], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export const useGenericTaskAssets = (
  taskKind: Ref<TaskKind>,
  taskId: Ref<string>
) => {
  const api = useApi();
  const state = reactive({
    error: null as Error | null,
    loading: false,
    assets: [] as AssetInTask[] | null,
    loaded: false
  });

  async function mutate() {
    state.loaded = false;
    state.error = null;

    if (!taskId.value) {
      state.assets = [];
      state.loaded = false;
      return;
    }

    state.loading = true;

    try {
      console.debug(
        `Fetching assets for task ${taskId.value} of kind ${taskKind.value}`
      );
      let assets: AssetInTask[];
      if (taskKind.value === 'AnnotationTask') {
        assets = await api.tasks.getAssets({
          taskId: taskId.value
        });
      } else if (taskKind.value === 'ReviewTask') {
        assets = await api.reviewTasks.getAssets({
          taskId: taskId.value
        });
      } else {
        throw new Error(
          `Unknown task kind ${taskKind.value}, cannot list assets`
        );
      }

      state.assets = assets;
      state.loaded = true;
    } catch (err) {
      console.error(`Error downloading assets`, err);
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([taskKind, taskId], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export const useFirstAssetInBatch = ({ batchId }: { batchId: Ref<string> }) => {
  const state = reactive({
    error: null as Error | null,
    loading: false,
    asset: null as Asset | null
  });

  const api = useApi();

  async function mutate() {
    if (!batchId.value) {
      state.asset = null;
      return;
    }

    state.loading = true;

    try {
      state.asset = await api.batches.getFirstAsset({ batchId: batchId.value });
    } catch (err) {
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch(batchId, mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export const useProject = (projectId: Ref<string>) => {
  const api = useApi();
  const state = reactive({
    error: null as Error | null,
    loading: false,
    project: null as Project | null
  });

  async function mutate() {
    state.error = null;

    if (!projectId.value) {
      state.project = null;
      return;
    }

    state.loading = true;

    try {
      console.debug(`Fetching project ${projectId.value}`);
      const project = await api.projects.getProject(projectId.value);
      state.project = project;
    } catch (err) {
      console.error(`Error getting project`, err);
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([projectId], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export const useRelease = (releaseId: Ref<string>) => {
  const api = useApi();
  const state = reactive({
    error: null as Error | null,
    loading: false,
    release: null as AnnotationRelease | null
  });

  async function mutate() {
    state.error = null;

    if (!releaseId.value) {
      state.release = null;
      return;
    }

    state.loading = true;

    try {
      console.debug(`Fetching release ${releaseId.value}`);
      const release = await api.annotationReleases.getRelease({
        releaseId: releaseId.value
      });
      state.release = release;
    } catch (err) {
      console.error(`Error getting release`, err);
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([releaseId], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export const useReleaseExports = (releaseId: Ref<string>) => {
  const api = useApi();
  const state = reactive({
    error: null as Error | null,
    loading: false,
    data: [] as AnnotationReleaseExport[]
  });

  async function mutate() {
    state.error = null;

    if (!releaseId.value) {
      state.data = [];
      return;
    }

    state.loading = true;

    try {
      console.debug(`Fetching release exports for release: ${releaseId.value}`);
      const exports_ = await api.annotationReleases.getReleaseExports({
        releaseId: releaseId.value
      });
      state.data = exports_;
    } catch (err) {
      console.error(`Error getting release exports`, err);
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([releaseId], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export const useProjectDatasets = (projectId: Ref<string>) => {
  const api = useApi();
  const state = reactive({
    error: null as Error | null,
    loading: false,
    datasets: [] as Dataset[] | null
  });

  async function mutate() {
    state.error = null;
    if (!projectId.value) {
      state.datasets = [];
      return;
    }

    state.loading = true;

    try {
      console.debug(`Fetching datasets for project ${projectId.value}`);
      const datasets = await api.projects.getProjectDatasets(projectId.value);
      state.datasets = datasets;
    } catch (err) {
      console.error(`Error getting datasets with batches`, err);
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([projectId], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export const useDatasetBatches = (datasetId: Ref<string>) => {
  const api = useApi();
  const state = reactive({
    error: null as Error | null,
    loading: false,
    batches: [] as Batch[] | null
  });

  async function mutate() {
    state.error = null;
    if (!datasetId.value) {
      state.batches = [];
      return;
    }

    state.loading = true;

    try {
      console.debug(`Fetching batches for dataset ${datasetId.value}`);
      const batches = await api.datasets.listBatches({
        datasetId: datasetId.value
      });
      state.batches = batches;
    } catch (err) {
      console.error(`Error listing batches for datasets`, err);
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([datasetId], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export const useDataset = (datasetId: Ref<string>) => {
  const api = useApi();
  const state = reactive({
    error: null as Error | null,
    loading: false,
    data: null as Dataset | null
  });

  async function mutate() {
    state.error = null;
    if (!datasetId.value) {
      state.data = null;
      return;
    }

    state.loading = true;

    try {
      console.debug(`Fetching dataset ${datasetId.value}`);
      const dataset = await api.datasets.getDataset({
        datasetId: datasetId.value
      });
      state.data = dataset;
    } catch (err) {
      console.error(`Error fetching dataset`, err);
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([datasetId], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export const useProjectDatasetsWithBatches = (projectId: Ref<string>) => {
  const api = useApi();
  const state = reactive({
    error: null as Error | null,
    loading: false,
    datasets: [] as Dataset[] | null
  });

  async function mutate() {
    state.error = null;
    if (!projectId.value) {
      state.datasets = [];
      return;
    }

    state.loading = true;

    try {
      console.debug(
        `Fetching datasets with batches for project ${projectId.value}`
      );
      const datasets = await api.projects.getProjectDatasets(projectId.value);
      const datasetsWithBatches = await Promise.all(
        datasets.map(async dataset => {
          const batches = await api.datasets.listBatches({
            datasetId: dataset.id
          });
          return { ...dataset, batches };
        })
      );
      state.datasets = datasetsWithBatches;
    } catch (err) {
      console.error(`Error getting datasets with batches`, err);
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([projectId], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export const useProjectTasks = (projectId: Ref<string>) => {
  const api = useApi();
  const state = reactive({
    error: null as Error | null,
    loading: false,
    tasks: [] as AnnotationTask[] | null
  });

  async function mutate() {
    state.error = null;
    if (!projectId.value) {
      state.tasks = [];
      return;
    }

    state.loading = true;

    try {
      console.debug(`Fetching tasks for project ${projectId.value}`);
      const tasks = await api.projects.getTasks(projectId.value);
      state.tasks = tasks;
    } catch (err) {
      console.error(`Error getting tasks`, err);
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([projectId], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export const useProjectTasksExpanded = (projectId: Ref<string>) => {
  const { tasks, loading, error, mutate } = useProjectTasks(projectId);
  const { datasets } = useProjectDatasetsWithBatches(projectId);

  /** TODO This is silly and hacky, clean this up */
  const expandedTasks = computed(() => {
    return tasks.value
      .map(task => {
        const dataset = _find(datasets.value, ds => ds.id === task.dataset.id);
        const batch = dataset
          ? _find(dataset.batches, batch => batch.id === task.batch.id)
          : undefined;
        return {
          ...task,
          dataset,
          batch
        };
      })
      .filter(task => !!task.dataset && !!task.batch);
  });
  return { tasks: expandedTasks, error, loading, mutate };
};

export const useProjectReviewTasks = (projectId: Ref<string>) => {
  const api = useApi();
  const state = reactive({
    error: null as Error | null,
    loading: false,
    tasks: [] as ReviewTask[]
  });

  async function mutate() {
    state.error = null;

    if (!projectId.value) {
      state.tasks = [];
      return;
    }

    state.loading = true;

    try {
      console.debug(`Fetching review tasks for project ${projectId.value}`);
      const tasks = await api.projects.getReviewTasks(projectId.value);
      state.tasks = tasks;
    } catch (err) {
      console.error(`Error getting review tasks`, err);
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([projectId], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export const useUiConfigurations = ({
  projectId,
  uiName
}: {
  projectId: Ref<string>;
  uiName: Ref<string | undefined>;
}) => {
  const api = useApi();
  const state = reactive({
    error: null as Error | null,
    loading: false,
    configurations: [] as AnnotationConfiguration[],
    loaded: false
  });

  async function mutate() {
    state.loaded = false;
    state.error = null;

    if (!uiName.value || !projectId.value) {
      state.loaded = false;
      state.configurations = [];
      return;
    }

    state.loading = true;

    try {
      console.debug(
        `Fetching UI configurations for project ${projectId.value} and UI ${uiName.value}`
      );
      const configurations = await api.projects.getAnnotationConfigurations({
        projectId: projectId.value,
        uiName: uiName.value
      });
      state.configurations = configurations;
      state.loaded = true;
    } catch (err) {
      console.error(`Error getting configurations`, err);
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([projectId, uiName], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export const useUiGuidelines = ({
  projectId,
  uiName
}: {
  projectId: Ref<string>;
  uiName: Ref<string | undefined>;
}) => {
  const api = useApi();
  const state = reactive({
    error: null as Error | null,
    loading: false,
    guidelines: [] as AnnotationGuideline[]
  });

  async function mutate() {
    state.error = null;

    if (!uiName.value) {
      state.guidelines = [];
      return;
    }

    state.loading = true;

    try {
      console.debug(
        `Fetching UI configurations for project ${projectId.value} and UI ${uiName.value}`
      );
      const guidelines = await api.projects.getAnnotationGuidelines({
        projectId: projectId.value,
        uiName: uiName.value
      });
      state.guidelines = guidelines;
    } catch (err) {
      console.error(`Error getting guidelines`, err);
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([projectId, uiName], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export const useReviewTaskAnnotationForAsset = ({
  taskId,
  assetId
}: {
  taskId: Ref<string>;
  assetId: Ref<string>;
}) => {
  const state = reactive({
    annotation: null as AnnotatedAsset | null,
    loading: false,
    error: null as Error | null,
    loadedForAssetId: null as string | null
  });

  const api = useApi();

  async function mutate() {
    if (!taskId.value || !assetId.value) {
      state.loadedForAssetId = null;
      state.annotation = null;
      return;
    }

    state.loading = true;
    state.error = null;

    const assetId_ = unref(assetId);

    try {
      const annotation = await api.reviewTasks.getAnnotationForAsset({
        taskId: taskId.value,
        assetId: assetId_
      });
      if (assetId.value === assetId_) {
        state.annotation = annotation;
        state.loadedForAssetId = assetId.value;
      }
    } catch (err) {
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([taskId, assetId], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export const useAnnotationForAsset = ({
  taskId,
  assetId
}: {
  taskId: Ref<string>;
  assetId: Ref<string>;
}) => {
  const state = reactive({
    annotation: null as AnnotatedAsset | null,
    loading: false,
    error: null as Error | null,
    loadedForAssetId: null as string | null
  });

  const api = useApi();

  async function mutate() {
    if (!taskId.value || !assetId.value) {
      state.loadedForAssetId = null;
      state.annotation = null;
      return;
    }

    state.loading = true;
    state.error = null;

    const assetId_ = unref(assetId);

    try {
      console.log(`Loading annotation for asset: ${assetId.value}`);
      const annotation = await api.tasks.getAnnotationForAsset({
        taskId: taskId.value,
        assetId: assetId_
      });
      if (assetId.value === assetId_) {
        state.annotation = annotation;
        state.loadedForAssetId = assetId.value;
      }
      console.log(`Got annotation for asset`, state.annotation);
    } catch (err) {
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([taskId, assetId], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export const useGenericAnnotationForAsset = ({
  taskId,
  assetId,
  loadAnnotation
}: {
  taskId: Ref<string>;
  assetId: Ref<string>;
  loadAnnotation: Ref<
    ({ assetId }: { assetId: string }) => Promise<AnnotatedAsset | undefined>
  >;
}) => {
  const state = reactive({
    annotation: null as AnnotatedAsset | null,
    loading: false,
    error: null as Error | null,
    loadedForAssetId: null as string | null
  });

  async function mutate() {
    if (!taskId.value || !assetId.value || !loadAnnotation.value) {
      state.loadedForAssetId = null;
      state.annotation = null;
      return;
    }

    state.loading = true;
    state.error = null;

    const assetId_ = unref(assetId);

    try {
      console.log(`Loading annotation for asset: ${assetId.value}`);
      const annotation = await loadAnnotation.value({
        assetId: assetId_
      });
      if (assetId.value === assetId_) {
        state.annotation = annotation;
        state.loadedForAssetId = assetId.value;
      }
      console.log(`Got annotation for asset`, state.annotation);
    } catch (err) {
      state.error = err;
    } finally {
      state.loading = false;
    }
  }

  watch([taskId, assetId, loadAnnotation], mutate, { immediate: true });

  return { ...toRefs(state), mutate };
};

export function useCanCreateOrganization(user: Ref<CompactUser>) {
  const {
    error: errorPlatformPermissions,
    platformPermissions
  } = usePlatformPermissions(user);

  const error = computed(() => errorPlatformPermissions.value);
  const canCreateOrganization = computed(
    () => platformPermissions.value?.can_create_organizations
  );

  return { canCreateOrganization, error };
}

export function useBadRequestErrors(
  error: Ref<Error | null>
): Ref<Record<string, string[]>> {
  return computed(() => {
    if (!(error.value instanceof BadRequest)) {
      return {};
    }
    const errors = error.value.errors;

    function reducer(acc: Record<string, string[]>, val: ApiError) {
      const errorPointer = val.source?.pointer;
      if (!errorPointer) {
        return acc;
      }
      const title = val.title;
      if (!title) {
        return acc;
      }
      return {
        ...acc,
        [errorPointer]: [...(acc[errorPointer] || []), title]
      };
    }

    return errors.reduce(reducer, {});
  });
}

export const useCurrentUserPersonalAccessTokens = ({
  user
}: {
  user: Ref<CompactUser>;
}) => {
  const tokens = ref<PersonalAccessToken[]>([]);

  const operation = async (api: AnnotatorApi) => {
    console.log(`Fetching personal access tokens...`);
    tokens.value = await api.me.getPersonalAccessTokens();
  };

  const { execute, ...state } = useAsyncOperation({
    operation
  });

  watch(user, execute, { immediate: true });

  return { ...state, mutate: execute, tokens };
};
