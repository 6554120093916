





































import useApi from '@/api/useApi';
import {
  computed,
  defineComponent,
  ref,
  toRefs,
  watch
} from '@vue/composition-api';
import ConfigureUiVersions from '@/components/project/ConfigureUIVersions.vue';
import ConfigureUiGuidelines from '@/components/project/ConfigureUIGuidelines.vue';

export default defineComponent({
  name: 'ConfigureUI',
  components: {
    ConfigureUiVersions,
    ConfigureUiGuidelines
  },
  props: {
    orgId: {
      required: true,
      type: String
    },
    projectId: {
      required: true,
      type: String
    },
    uiName: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const { projectId, orgId } = toRefs(props);

    const projectName = ref(null as string | null);

    const loading = ref(false);
    const error = ref(null as Error | null);
    const tab = ref(0);

    const api = useApi();

    async function fetchData() {
      try {
        const project = await api.projects.getProject(projectId.value);
        projectName.value = project.name;
      } catch (err) {
        error.value = err;
      }
    }

    watch([projectId], fetchData, { immediate: true });

    const routeBack = computed(() => ({
      name: 'ProjectConfigurations',
      props: {
        projectId: projectId.value,
        orgId: orgId.value
      }
    }));

    return {
      projectName,
      loading,
      tab,
      routeBack
    };
  }
});
