import { computed, ref, Ref } from '@vue/composition-api';

import { useDeclineTask, useSubmitGenericTask } from '@/api/use';
import { AnnotationTask } from '@/types';
import { TaskAndKind } from '@/layers';

export function useSubmitDialog(taskAndKind: Ref<TaskAndKind>) {
  const task = computed(() => taskAndKind.value?.task);

  const isOpen = ref(false);
  const {
    error,
    loading: submitting,
    submitTask: submitTask_,
    success
  } = useSubmitGenericTask(taskAndKind);

  function closeDialog() {
    isOpen.value = false;
  }

  async function submit() {
    await submitTask_();
  }

  const canSubmit = computed(
    () =>
      !!task.value &&
      !task.value.submitted_at &&
      task.value.counts.annotations === task.value.counts.assets
  );

  return {
    success,
    isOpen,
    error,
    submitting,
    submit,
    canSubmit,
    closeDialog
  };
}

export const useDeclineDialog = (taskAndKind: Ref<TaskAndKind>) => {
  const isOpen = ref(false);

  const {
    error,
    loading: declining,
    declineTask: declineTaskWithReason,
    success
  } = useDeclineTask({ taskAndKind });

  function closeDialog() {
    isOpen.value = false;
  }

  async function declineTask() {
    await declineTaskWithReason('N/A');
  }

  const canDecline = computed(
    () =>
      taskAndKind.value?.kind === 'AnnotationTask' &&
      !!taskAndKind.value?.task.submitted_at
  );

  return {
    success,
    isOpen,
    error,
    declining,
    declineTask,
    canDecline,
    closeDialog
  };
};
