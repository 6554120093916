import { AnnotationTask, Asset, Batch, Job } from '@/types';
import apiClient from '@/api/client';

const slug = 'batches';

export default {
  async getBatch({ batchId }): Promise<Batch> {
    const batch = await apiClient.get(`/${slug}/${batchId}`);
    return batch;
  },
  async deleteBatch({ batchId }): Promise<void> {
    await apiClient.delete(`/${slug}/${batchId}`);
  },
  async uploadFile({
    batchId,
    file,
    onUploadProgress
  }: {
    batchId: string;
    file: File;
    onUploadProgress?: (progressEvent: ProgressEvent) => void;
  }): Promise<void> {
    const headers = { 'Content-Type': 'application/octet-stream' };
    const data = await file.arrayBuffer();
    console.log(
      `Uploading file of size: ${(data.byteLength / (1024 * 1024)).toFixed(
        1
      )} MB`
    );
    await apiClient.put(
      `/${slug}/${batchId}/upload`,
      data,
      headers,
      onUploadProgress
    );
  },
  async triggerProcessing({ batchId }: { batchId: string }): Promise<Job> {
    const response = await apiClient.put(`/${slug}/${batchId}/upload/trigger`);
    return response.job;
  },
  async getTasksInBatch({ batchId }): Promise<AnnotationTask[]> {
    const res = await apiClient.get(`/${slug}/${batchId}/tasks`);
    return res.tasks;
  },
  async getAssets({ batchId }): Promise<Asset[]> {
    const params = {
      url: `/${slug}/${batchId}/assets`,
      query: {},
      responseKey: 'assets'
    };

    return await apiClient.getPaginated(params);
  },
  async getFirstAsset({ batchId }): Promise<Asset | null> {
    const url = `/${slug}/${batchId}/assets?page=1&per_page=1`;
    const res = await apiClient.get(url);
    const assets = res.assets;
    return assets.length > 0 ? assets[0] : null;
  },
  async createTask(newTask): Promise<AnnotationTask> {
    const endpoint = `/${slug}/${newTask.batchId}/tasks`;
    const annotationUI = newTask.uiName ? { ui_name: newTask.uiName } : {};
    const configuration = Object.keys(newTask).includes('configurationVersion')
      ? { configuration_version: newTask.configurationVersion }
      : {};
    const guideline = Object.keys(newTask).includes('guidelineVersion')
      ? { guideline_version: newTask.guidelineVersion }
      : {};
    return await apiClient.post(endpoint, {
      project_id: newTask.projectId,
      annotator_id: newTask.assigneeId,
      ...annotationUI,
      ...configuration,
      ...guideline
    });
  }
};
