import OrgApi from '../../api/organizations';
import MeApi from '@/api/me';

function getDefaultState() {
  return {
    userOrganizations: [],
    selectedOrganization: undefined,
    loading: false,
    error: null as Error | null
  };
}

export default {
  namespaced: true,
  state: {
    userOrganizations: [],
    selectedOrganization: undefined,
    jobsStarted: [],
    loading: false,
    error: null as Error | null
  },
  getters: {
    userOrganizations(state) {
      return state.userOrganizations;
    },
    selectedOrganization(state) {
      return state.selectedOrganization;
    },
    getOrganizationById: state => id => {
      return state.userOrganizations.find(org => org.id === id);
    },
    jobsStarted(state) {
      return state.jobsStarted;
    },
    getLoadingState(state) {
      return state.loading;
    },
    getError(state) {
      return state.error;
    }
  },
  actions: {
    async getUserOrganizations(context) {
      if (!context.rootState.auth.isAuth) {
        // User not logged in, reset and return
        console.debug('User not logged in, resetting state');
        context.commit('RESET_STATE');
        return;
      }
      // User logged in, proceed
      console.debug('User logged in, proceeding to initialize state');
      context.commit('SET_LOADING', true);
      try {
        const userOrgs = await MeApi.getOrganizations();
        context.commit('SET_USER_ORGANIZATIONS', userOrgs);

        if (userOrgs.length === 0) {
          console.debug('There is no organization being created yet. ');
          return;
        }

        const userPreference = await MeApi.getPreferences();
        if (userPreference.organization == null) {
          context.commit('SET_SELECTED_ORGANIZATION', userOrgs[0]);
        } else {
          const userPreferredOrganization = userOrgs.find(
            org => org.id === userPreference.organization.id
          );
          const selectedOrganization = userPreferredOrganization || userOrgs[0];
          context.commit('SET_SELECTED_ORGANIZATION', selectedOrganization);
        }
      } catch (error) {
        context.commit('SET_USER_ORGANIZATIONS', []);
        context.commit('SET_ERROR', error);
        throw error;
      } finally {
        context.commit('SET_LOADING', false);
      }
    },
    async createOrganization(context, newOrganization) {
      try {
        const newOrg = await OrgApi.createOrganization(newOrganization);
        context.commit('ADD_NEW_ORGANIZATION', newOrg);
      } catch (err) {
        console.error('Failed to create org: ', err);
        throw err;
      }
    },
    async editOrganization(_, { id, edited }) {
      console.log(`Skipping editing organization ${id}`, edited);
    },
    async removeOrganization(_, { id }) {
      console.log(`Skipping deleting organization ${id}`);
    },
    async setSelectedOrganization(context, organization) {
      try {
        await MeApi.setPreferredOrganization(organization.id);
        context.commit('SET_SELECTED_ORGANIZATION', organization);
      } catch (err) {
        console.error('Failed update preferred organization: ', err);
        throw err;
      }
    },
    resetState(context) {
      context.commit('RESET_STATE');
    },
    async jobStarted(context, { id }) {
      context.commit('INCREMENT_JOBS_STARTED', { id });
    }
  },
  mutations: {
    SET_USER_ORGANIZATIONS: (state, userOrgs) => {
      state.userOrganizations = userOrgs;
    },
    SET_SELECTED_ORGANIZATION: (state, organization) => {
      state.selectedOrganization = organization;
    },
    ADD_NEW_ORGANIZATION: (state, newOrg) => {
      state.userOrganizations = [...state.userOrganizations, newOrg];
    },
    RESET_STATE: state => {
      Object.assign(state, getDefaultState());
    },
    INCREMENT_JOBS_STARTED: (state, { id }) => {
      state.jobsStarted = [...state.jobsStarted, id];
    },
    SET_LOADING: (state, loading) => {
      state.loading = loading;
    },
    SET_ERROR: (state, error) => {
      state.error = error;
    }
  }
};
