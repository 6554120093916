






































import { mapGetters, mapState } from 'vuex';
import Nav from './components/Nav.vue';
import _find from 'lodash/find';
import { defineComponent } from '@vue/composition-api';
import LoadingModal from '@/components/LoadingModal.vue';

export default defineComponent({
  name: 'App',
  data: () => ({
    showCreateOrganization: false,
    showPlatformPermissionsError: null
  }),
  components: {
    Nav,
    LoadingModal
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('organizations', [
      'userOrganizations',
      'selectedOrganization'
    ]),
    showNavigation(): boolean {
      return this.$route.name !== 'Welcome' && this.$route.name !== 'Logout';
    },
    ...mapState('organizations', ['loading', 'error'])
  },
  methods: {
    updateSelectedOrganization(organization) {
      console.log(
        `Updating selected organization`,
        JSON.stringify(organization)
      );
      this.$store.dispatch(
        'organizations/setSelectedOrganization',
        organization
      );
      if (this.$route.params.orgId) {
        const newRoute = this.$route.path.replace(
          this.$route.params.orgId,
          organization.id
        );
        if (this.$route.path != newRoute) {
          this.$router.push(newRoute);
        }
      }
    },
    async logout() {
      await this.$store.dispatch('organizations/resetState');
      this.$router.push('/welcome');
    },
    async initialize() {
      if (this.$route.params.orgId) {
        const orgId = this.$route.params.orgId;
        const organization = _find(
          // This is a store getter
          this.userOrganizations,
          org => org.id === orgId
        );
        this.updateSelectedOrganization(organization);
      }
    },
    showPlatformPermissionErrorMessage(error: Error) {
      this.showPlatformPermissionsError = error;
    }
  },
  created() {
    this.initialize();
  },
  watch: {
    currentUser: {
      handler: async function(newUser) {
        if (newUser) {
          await this.initialize();
        }
        if (newUser) {
          await this.$store.dispatch('organizations/getUserOrganizations');
        } else {
          await this.$store.dispatch('organizations/resetState');
        }
      },
      immediate: true
    }
  },
  setup(props, { root: { $store: store } }) {
    async function initOrganizations() {
      await store.dispatch('organizations/getUserOrganizations');
    }
    initOrganizations();
  }
});
