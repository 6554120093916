var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"sort-by":"createdAtRaw","sort-desc":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Annotation releases")]),_c('v-spacer'),_c('refresh-button',{on:{"refresh":function($event){return _vm.$emit('refresh')}}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"accent","to":_vm.createReleaseRoute,"small":""}},[_vm._v(" Create release ")])],1),_c('div',{staticClass:"text-caption px-4"},[_c('p',[_vm._v(" Annotation releases are immutable snapshots of annotations gathered from multiple annotation tasks. ")])])]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.toRelease(item)}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.export",fn:function(ref){
var item = ref.item;
return [_c('export-annotations-button',{attrs:{"release":_vm.releaseForId(item.id),"disabled":_vm.isProcessing(item)}})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('id-item',{attrs:{"id":item.id}})]}},{key:"item.nAnnotations",fn:function(ref){
var item = ref.item;
return [(_vm.isProcessing(item))?_c('span',[_c('tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-progress-circular',_vm._g(_vm._b({attrs:{"size":20,"width":3,"color":"gray","indeterminate":""}},'v-progress-circular',Object.assign({}, attrs),false),Object.assign({}, tooltip)))]}}],null,true)},[_c('span',[_vm._v("Processing...")])])],1):_c('span',[_vm._v(" "+_vm._s(item.nAnnotations)+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('date-item',{attrs:{"date":item.createdAt}})]}},(_vm.releases.length === 0)?{key:"no-data",fn:function(){return [_vm._v(" No releases. ")]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }