var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.taskItems,"items-per-page":10,"sort-by":"created_at","sort-desc":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Review tasks")])],1)]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('date-item',{attrs:{"date":new Date(item.created_at)}})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('id-link',{attrs:{"id":item.id,"to":_vm.taskDetailsRoute(item.id)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":"","close-on-content-click":false,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"text":"","small":"","color":"info","to":_vm.annotateReviewTaskRoute(item.id)}},[_vm._v(" Review ")])],1),_c('v-list-item',[_c('export-annotations-button',{attrs:{"taskAndKind":_vm.toGeneric(item)}})],1),_c('v-list-item',[_c('submit-task-button',_vm._g({attrs:{"taskAndKind":_vm.toGeneric(item),"text":"","small":"","color":"info"}},_vm.$listeners))],1),_c('v-list-item',[_c('delete-task-button',_vm._g({attrs:{"taskAndKind":_vm.toGeneric(item),"text":"","small":"","color":"info"}},_vm.$listeners))],1)],1)],1)]}},(_vm.tasks.length === 0)?{key:"no-data",fn:function(){return [_vm._v(" No tasks. ")]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }