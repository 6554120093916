import apiClient from './client';
import OrganizationsApi from './organizations';
import { PolicyBinding, PolicyScope, PolicyRole } from '@/types';

// TODO Fetch available roles from the backend
const roles: PolicyRole[] = [{ name: 'Admin' }];

export default {
  async getAllScopes({ organizationId }): Promise<PolicyScope[]> {
    console.log(`Fetching scopes for ${organizationId}`);
    const organization = await OrganizationsApi.getOrganization(organizationId);
    // TODO Add here also projects and datasets so
    // users can assign roles per project and dataset
    // once the UI supports that
    return [
      {
        kind: 'Organization',
        name: organization.name,
        id: organization.id
      }
    ];
  },
  async getAllBindings({ organizationId }): Promise<PolicyBinding[]> {
    console.log(`Fetching all bindings for ${organizationId}`);
    const response = await apiClient.get(
      `/organizations/${organizationId}/all-policy-bindings`
    );
    return response.bindings;
  },
  async addRoleBindingForScope({ scope, email, role }): Promise<void> {
    console.log(
      `Adding role binding to ${scope.kind} ${scope.id} for ${email} and role ${role}`
    );
    const collection =
      scope.kind == 'Organization'
        ? 'organizations'
        : scope.kind == 'Project'
        ? 'projects'
        : scope.kind == 'Dataset'
        ? 'datasets'
        : null;

    if (!collection) {
      throw Error(`Unknown scope kind: ${scope.kind}`);
    }

    return await apiClient.post(`/${collection}/${scope.id}/policy-bindings`, {
      role,
      subject: `email:${email}`
    });
  },
  async getAllRoles({ organizationId }): Promise<PolicyRole[]> {
    console.log(`Fetching roles for ${organizationId}`);
    return roles;
  }
};
