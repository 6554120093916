import {
  AnnotationTask,
  ReviewTask,
  Organization,
  UserPreferences,
  PlatformPermissions,
  CreatedPersonalAccessToken,
  PersonalAccessToken
} from '@/types';
import apiClient from './client';

const slug = 'me';

export default {
  async getTasks(): Promise<AnnotationTask[]> {
    const res = await apiClient.get(`/${slug}/tasks`);
    return res.tasks;
  },
  async getReviewTasks(): Promise<ReviewTask[]> {
    const res = await apiClient.get(`/${slug}/review-tasks`);
    return res.review_tasks;
  },
  async getOrganizations(): Promise<Organization[]> {
    const res = await apiClient.get(`/${slug}/organizations`);
    return res.organizations;
  },
  async getPreferences(): Promise<UserPreferences> {
    const res = await apiClient.get(`/${slug}/preferences`);
    return res;
  },
  async setPreferredOrganization(
    organization_id: string
  ): Promise<UserPreferences> {
    const body = { organization_id };
    const res = await apiClient.patch(`/${slug}/preferences`, body);
    return res;
  },
  async setPassword({ old_password, new_password }): Promise<void> {
    await apiClient.post(`/${slug}/set-password`, {
      old_password,
      new_password
    });
  },
  async getPlatformPermissions(): Promise<PlatformPermissions> {
    const res = await apiClient.get(`/${slug}/platform-permissions`);
    return res;
  },
  async getPersonalAccessTokens(): Promise<PersonalAccessToken[]> {
    const res = await apiClient.get(`/${slug}/personal-access-tokens`);
    return res.tokens;
  },
  async createPersonalAccessToken({
    name
  }: {
    name: string;
  }): Promise<CreatedPersonalAccessToken> {
    const res = await apiClient.post(`/${slug}/personal-access-tokens`, {
      name
    });
    return res;
  },
  async deletePersonalAccessToken({ tokenId }): Promise<void> {
    await apiClient.delete(`/${slug}/personal-access-tokens/${tokenId}`);
  }
};
