


















































































import Vue from 'vue';
import { BadRequest } from '@/api/client';

export default Vue.extend({
  name: 'ChangePasswordButton',
  components: {},
  props: {},
  data: () => ({
    showDialog: false,
    inputValid: false,
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    passwordRules: [v => (!!v && v.length >= 8) || 'Invalid password'],
    oldPasswordErrors: [] as string[],
    newPasswordErrors: [] as string[]
  }),
  computed: {},
  methods: {
    passwordConfirmationRule(): boolean | string {
      return (
        this.newPassword === this.confirmNewPassword || 'Password must match'
      );
    },
    async submit(): Promise<void> {
      try {
        await this.setPassword();
        await this.logout();
        this.onClose();
      } catch (err) {
        if (err instanceof BadRequest) {
          this.oldPasswordErrors = err.errors
            .filter(err => err.source.pointer === 'old_password')
            .map(err => err.title);
          this.newPasswordErrors = err.errors
            .filter(err => err.source.pointer === 'new_password')
            .map(err => err.title);
        }
      }
    },
    async setPassword(): Promise<void> {
      await this.$api.me.setPassword({
        old_password: this.oldPassword,
        new_password: this.newPassword
      });
    },
    async logout(): Promise<void> {
      this.$router.push('/logout');
    },
    onClose(): void {
      this.showDialog = false;
      (this.$refs.form as HTMLFormElement).reset();
    }
  }
});
