









































import Vue, { PropType } from 'vue';
import _find from 'lodash/find';
import _fromPairs from 'lodash/fromPairs';

interface Field {
  name: string;
  label: string;
}

type SubmitFn = (values: Record<string, string>) => void;

const emptyFieldValues = (fields: Field[]) =>
  _fromPairs(fields.map(value => [value.name, '']));

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true
    },
    info: {
      type: String,
      required: false
    },
    fields: {
      type: Array as PropType<Field[]>,
      required: true
    },
    onSubmit: {
      type: Function as PropType<SubmitFn>,
      required: true
    }
  },
  data: function() {
    return {
      showDialog: false,
      rules: [v => (v && v.length > 0) || 'This field cannot be empty'],
      fieldValues: emptyFieldValues(this.fields),
      errorsByField: {}
    };
  },
  computed: {
    fieldNames(): string[] {
      return this.fields.map(field => field.name);
    },
    formIsValid(): boolean {
      return this.rules.every(rule =>
        Object.values(this.fieldValues).every(v => rule(v) === true)
      );
    }
  },
  methods: {
    async submit() {
      try {
        await this.onSubmit(this.fieldValues);
        this.closeDialog();
      } catch (err) {
        if (err.errors) {
          // Check if error source pointers match names
          this.errorsByField = _fromPairs(
            this.fieldNames.map(name => {
              const maybeError = _find(
                err.errors,
                err => err.source && err.source.pointer === name
              );
              const error = maybeError ? maybeError.title : undefined;
              return [name, error];
            })
          );
          return;
        }
        // TODO Handle error by showing
        throw err;
      }
    },
    closeDialog() {
      this.showDialog = false;
      this.fieldValues = emptyFieldValues(this.fields);
      this.errorsByField = {};
    }
  },
  watch: {
    fields: function() {
      this.fieldValues = emptyFieldValues(this.fields);
    }
  }
});
