import apiClient from '@/api/client';
import ProjectsApi from './projects';
import DatasetsApi from './datasets';
import TasksApi from './tasks';
import BatchesApi from './batches';
import OrganizationsApi from './organizations';
import annotationReleases from './annotationReleases';
import jobs from './jobs';
import me from './me';
import auth from './auth';
import assets from './assets';
import policies from './policies';
import reviewTasks from './reviewTasks';
import models from './models';

function getBinaryDataFromServer(url: string): Promise<ArrayBuffer> {
  return apiClient.getBinaryData(url);
}

const annotatorApi = {
  datasets: DatasetsApi,
  projects: ProjectsApi,
  organizations: OrganizationsApi,
  tasks: TasksApi,
  batches: BatchesApi,
  annotationReleases,
  jobs,
  me,
  auth,
  assets,
  policies,
  reviewTasks,
  getBinaryDataFromServer,
  models
};

export default annotatorApi;

export type AnnotatorApi = typeof annotatorApi;

export const AnnotatorApiPlugin = {
  install: function(Vue) {
    Vue.prototype.$api = annotatorApi;
  }
};
