














































import {
  defineComponent,
  onMounted,
  PropType,
  ref,
  toRefs
} from '@vue/composition-api';
import { whenever } from '@vueuse/core';

import { useAddUserToOrganization, useBadRequestErrors } from '@/api/use';
import { BadRequest } from '@/api/client';
import { validateEmail } from '@/utils';

export default defineComponent({
  name: 'AddUserToOrganizationCard',
  components: {},
  props: {
    organizationId: {
      required: true,
      type: String
    },
    closeDialog: {
      required: true,
      type: Function as PropType<() => void>
    }
  },
  setup(props, { emit, refs }) {
    const { organizationId, closeDialog } = toRefs(props);
    const email = ref('');
    const inputValid = ref(false);
    const emailErrorMessages = ref<string[]>([]);

    const form = ref<HTMLFormElement>(null);
    const success = ref(false);
    const unexpectedError = ref<Error>(null);

    const { addUser, saving, error } = useAddUserToOrganization(organizationId);

    const badRequestErrors = useBadRequestErrors(error);

    whenever(
      () => badRequestErrors.value.email?.length > 0,
      function() {
        emailErrorMessages.value = badRequestErrors.value.email;
      }
    );

    onMounted(function() {
      form.value = refs.form as HTMLFormElement;
    });

    function close() {
      form.value?.reset();
      emit('refresh');
      closeDialog.value();
    }

    const emailRules = [
      (v: string) => validateEmail(v) || 'Invalid e-mail address'
    ];

    async function submit() {
      await addUser({ email: email.value });
      if (!error.value) {
        success.value = true;
        setTimeout(() => {
          emit('refresh');
          close();
        }, 1000);
      } else if (!(error.value instanceof BadRequest)) {
        unexpectedError.value = error.value;
      }
    }

    return {
      email,
      emailErrorMessages,
      inputValid,
      form,
      close,
      submit,
      saving,
      unexpectedError,
      success,
      emailRules,
      badRequestErrors
    };
  }
});
