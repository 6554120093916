






































































































































import { PropType } from 'vue';
import { computed, defineComponent, toRefs } from '@vue/composition-api';
import _find from 'lodash/find';
import _orderBy from 'lodash/orderBy';

import ExportAnnotationsButton from '@/components/molecules/ExportAnnotationsButton.vue';
import DateItem from '@/components/molecules/DateItem.vue';
import IdLink from '@/components/molecules/IdLink.vue';
import OpenDialogButton from '@/components/molecules/OpenDialogButton.vue';
import CreateReviewTaskCard from '@/components/CreateReviewTaskCard.vue';
import EditTaskCard from '@/components/EditTaskCard.vue';
import {
  AnnotationTask,
  ExpandedAnnotationTask,
  Project,
  TaskStatus
} from '@/types';
import { useOrganizationMembers } from '@/api/use';
import SubmitTaskButton from './SubmitTaskButton.vue';
import DeclineTaskButton from './DeclineTaskButton.vue';
import { TaskAndKind } from '@/layers';
import DeleteTaskButton from '@/components/molecules/DeleteTaskButton.vue';

const progressSortFn = (a: string, b: string) => {
  const [aNumStr, aTotStr] = a.split(' % (')[1].split('/');
  const [bNumStr, bTotStr] = b.split(' % (')[1].split('/');
  const aNum = parseFloat(aNumStr);
  const aTot = parseFloat(aTotStr);
  const bNum = parseFloat(bNumStr);
  const bTot = parseFloat(bTotStr);
  const aPerc = aNum / aTot;
  const bPerc = bNum / bTot;
  return aPerc - bPerc;
};

export default defineComponent({
  components: {
    ExportAnnotationsButton,
    DateItem,
    IdLink,
    OpenDialogButton,
    CreateReviewTaskCard,
    SubmitTaskButton,
    DeclineTaskButton,
    DeleteTaskButton,
    EditTaskCard
  },
  data: () => ({
    search: '',
    assigneeSearch: '',
    showSearch: false
  }),
  props: {
    tasks: {
      required: true,
      type: Array as PropType<Array<ExpandedAnnotationTask>>
    },
    project: {
      required: true,
      type: Object as PropType<Project>
    },
    orgId: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const { project } = toRefs(props);
    const organizationId = computed(() => project.value.organization.id);
    const { members } = useOrganizationMembers(organizationId);

    const toGeneric = (task: AnnotationTask): TaskAndKind => ({
      task,
      kind: 'AnnotationTask'
    });
    return { members, toGeneric };
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Dataset',
          value: 'datasetName'
        },
        {
          text: 'Batch',
          value: 'batch'
        },
        {
          text: 'Assignee',
          value: 'annotator',
          filter: (value: string) => {
            const assigneeSearch = this.assigneeSearch;
            return !assigneeSearch || assigneeSearch === value;
          }
        },
        {
          text: 'Task type',
          value: 'uiName'
        },
        {
          text: 'Assigned at',
          value: 'createdAt'
        },

        {
          text: 'Progress',
          value: 'progress',
          align: 'center',
          sort: progressSortFn
        },
        {
          text: 'Priority',
          value: 'priority',
          align: 'center'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center'
        }
      ];
    },
    hasPrioritizedTasks(): boolean {
      const prioritized = this.tasks.filter(task => task.priority > 0);
      return prioritized.length > 0;
    },
    hasCompletedAllTasks(): boolean {
      return (
        this.tasks.length > 0 &&
        this.taskItems.every(item => item.status === TaskStatus.Complete)
      );
    },
    taskHeaders(): Array<Record<string, any>> {
      return !this.hasPrioritizedTasks
        ? this.headers.filter(header => header.value !== 'priority')
        : this.headers;
    },
    taskItems(): Array<any> {
      const taskItems = this.tasks.map(task => ({
        ...task,
        task,
        id: task.id,
        annotator: task.annotator ? task.annotator.name : null,
        assets: task.counts.assets,
        datasetName: task.dataset.name,
        annotations: task.counts.annotations,
        batch: task.batch.filename,
        priority: task.priority,
        createdBy: task.created_by ? task.created_by.name : undefined,
        createdAt: new Date(Date.parse(task.created_at)).toLocaleDateString(),
        createdAtRaw: task.created_at,
        progress: `${(
          (task.counts.annotations / task.counts.assets) *
          100.0
        ).toFixed(0)} % (${task.counts.annotations}/${task.counts.assets})`,
        status: task.submitted_at
          ? TaskStatus.Complete
          : task.counts.annotations === task.counts.assets
          ? TaskStatus.Ready
          : TaskStatus.Pending,
        uiName: task.ui?.name
      }));
      return this.hasPrioritizedTasks
        ? _orderBy(taskItems, 'priority', 'desc')
        : taskItems;
    }
  },
  methods: {
    taskForId(taskId: string): AnnotationTask {
      return _find(this.tasks, task => task.id === taskId);
    },
    taskDetailsRoute(taskId: string) {
      return {
        name: 'AnnotationTaskDetails',
        params: { projectId: this.project.id, taskId, orgId: this.orgId }
      };
    },
    annotateTaskRoute(taskId: string) {
      return {
        name: 'TaskAnnotate',
        params: { projectId: this.project.id, taskId }
      };
    }
  }
});
