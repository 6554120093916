var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{staticClass:"caption task-selector",attrs:{"item-text":function (task_) { return ((task_.kind) + ": " + (task_.id)); },"items":_vm.tasks,"hint":("" + (_vm.selectedTask
        ? 'Selected task with ' +
          _vm.selectedTask.value.counts.annotations +
          ' annotations'
        : 'Select task to export')),"dense":"","persistent-hint":"","id":_vm.batchId,"return-object":"","item-disabled":function (item) { return item.value.counts.annotations === 0; }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('v-card',{staticClass:"caption task-selection pa-1 mb-1"},[_c('div',[_c('b',[_vm._v(_vm._s(item.kind === 'ReviewTask' ? 'Review task' : 'Annotation task'))]),_vm._v(" by "+_vm._s((item.value.annotator || item.value.assignee).name)+" ("+_vm._s(item.value.counts.annotations)+"/"+_vm._s(item.value.counts.assets)+") ")])])]}},{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('v-card',{staticClass:"caption task-card pa-2 my-2",attrs:{"disabled":item.value.counts.annotations === 0}},[_c('div',[_c('b',[_vm._v("Task ID")]),_vm._v(": "+_vm._s(item.value.id))]),_c('div',[_c('b',[_vm._v("Kind")]),_vm._v(": "+_vm._s(item.kind === 'AnnotationTask' ? 'Annotation task' : 'Review task')+" ")]),_c('div',[_c('b',[_vm._v("Annotations")]),_vm._v(": "+_vm._s(item.value.counts.annotations)+"/"+_vm._s(item.value.counts.assets)+" ")]),_c('div',[_c('b',[_vm._v("Assignee")]),_vm._v(": "+_vm._s((item.value.annotator || item.value.assignee).name)+" ")]),_c('div',[_c('b',[_vm._v("Created at")]),_vm._v(": "+_vm._s(_vm._f("toDate")(item.value.created_at))+" ")]),_c('div',[_c('b',[_vm._v("Created by")]),_vm._v(": "+_vm._s(item.value.created_by.name)+" ")])])]}}]),model:{value:(_vm.selectedTaskLocal),callback:function ($$v) {_vm.selectedTaskLocal=$$v},expression:"selectedTaskLocal"}})}
var staticRenderFns = []

export { render, staticRenderFns }