var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.taskItems,"items-per-page":10,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_vm._v(" Finished tasks "),_c('v-spacer')],1)]},proxy:true},{key:"item.submitted_at",fn:function(ref){
var item = ref.item;
return [_c('date-item',{attrs:{"date":new Date(item.submitted_at)}})]}},{key:"item.annotate",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":"","close-on-content-click":false,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('export-annotations-button',{attrs:{"taskAndKind":_vm.toGeneric(item)}})],1),(_vm.isAnnotationTask(item))?_c('v-list-item',[_c('open-dialog-button',{attrs:{"text":"","title":"Create review task"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var closeDialog = ref.closeDialog;
return [_c('create-review-task-card',_vm._g({attrs:{"task":_vm.taskForId(item.id).task,"closeDialog":closeDialog}},_vm.$listeners))]}}],null,true)})],1):_vm._e()],1)],1)]}},(_vm.noTasks)?{key:"no-data",fn:function(){return [_vm._v(" No tasks. ")]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }