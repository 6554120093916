import Vue from 'vue';
import Vuex from 'vuex';
import Auth from './modules/Auth';
import Organizations from './modules/Organizations';
import Projects from './modules/Projects';

Vue.use(Vuex);

export const store = {
  state() {
    return { isLoading: false };
  },
  actions: {
    setLoading(context, value) {
      context.commit('SET_LOADING', value);
    }
  },
  mutations: {
    SET_LOADING: (state, value) => {
      state.isLoading = value;
    }
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    }
  },
  modules: {
    auth: Auth,
    organizations: Organizations,
    projects: Projects
  }
};

export default new Vuex.Store(store);
