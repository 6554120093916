import * as z from 'zod';
import _isEmpty from 'lodash/isEmpty';

// Parsed below to validate type
export const DEFAULT_CONFIG_ = {
  tasks: [
    {
      name: 'Quality',
      choices: [
        { name: 'Good', color: '#4CAF50' },
        { name: 'Bad', color: '#f08686' }
      ]
    }
  ]
};

const choiceSchema = z.object({ name: z.string(), color: z.string() });

const choicesSchema = z.array(choiceSchema);

export type Choice = z.infer<typeof choiceSchema>;

const taskSchema = z.object({
  name: z.string(),
  choices: choicesSchema
});

export type ClassificationTask = z.infer<typeof taskSchema>;

export const ImageClassificationParameterSchema = z.object({
  tasks: z.array(taskSchema).nonempty()
});

export const DEFAULT_CONFIG = ImageClassificationParameterSchema.parse(
  DEFAULT_CONFIG_
);

export type ImageClassificationParameters = z.infer<
  typeof ImageClassificationParameterSchema
>;

const checkParameters = (
  params: any
): params is ImageClassificationParameters => {
  try {
    return !!ImageClassificationParameterSchema.parse(params);
  } catch (err) {
    console.error(`Invalid parameters`, params);
    return false;
  }
};

export const resolveParameters = (
  params: any
): ImageClassificationParameters => {
  if (!_isEmpty(params) && !checkParameters(params)) {
    console.warn('Invalid parameters obtained, proceed with caution');
  }
  return { ...DEFAULT_CONFIG, ...params };
};

export interface ImageClassificationLabel {
  feature: string;
  value: string;
}

export const parseLabel = (label: any): ImageClassificationLabel => {
  if (!label.feature) {
    throw Error(`Missing field 'feature' in label: ${JSON.stringify(label)}`);
  }

  if (!label.value) {
    throw Error(`Missing field 'value' in label: ${JSON.stringify(label)}`);
  }

  return {
    feature: label.feature,
    value: label.value
  };
};

export interface SelectedLabelByTaskMap {
  [task: string]: string | undefined;
}
