























































































import YAML from 'yaml';
import Vue, { PropType } from 'vue';
import { AnnotationConfiguration, Project } from '@/types';
import { AnnotationUI } from '@/components/annotations/ui';
import * as z from 'zod';

export default Vue.extend({
  name: 'CreateAnnotationUIConfigView',
  props: {
    ui: {
      type: Object as PropType<AnnotationUI>,
      required: true
    },
    project: {
      required: true,
      type: Object as PropType<Project>
    },
    latest: {
      required: false,
      type: Object as PropType<AnnotationConfiguration>
    },
    closeDialog: {
      required: true,
      type: Function
    }
  },
  data() {
    return {
      inputParameters: null as string | null,
      loading: false,
      errorSaving: null as string | null,
      submitting: false,
      renderYaml: true
    };
  },
  computed: {
    latestParametersResolved(): any {
      const previous = (this.latest && this.latest.parameters) || {};
      const params = this.ui.resolveParameters(previous);
      return params;
    },
    errorMessages(): string {
      return '';
    },
    defaultParameters(): Record<string, any> {
      return this.ui.defaultConfig;
    },
    defaultParametersKeys(): string[] {
      return Object.keys(this.defaultParameters);
    },
    inputAsJson(): Record<string, any> | undefined {
      try {
        return this.parseStringToObject(this.inputParameters);
      } catch {
        return undefined;
      }
    },
    inputValid(): boolean {
      return this.errors.length === 0;
    },
    existingVersion(): number | undefined {
      return this.latest ? this.latest.version : undefined;
    },
    error(): string | undefined {
      if (!this.inputAsJson) {
        return `Invalid ${this.renderYaml ? 'YAML' : 'JSON'}`;
      }

      try {
        this.ui.configSchema.parse(this.inputAsJson);
      } catch (error) {
        if (error instanceof z.ZodError) {
          return error.message;
        }
      }
      return undefined;
    },
    errors(): string[] {
      return this.error ? [this.error] : [];
    },
    versionInfo(): string {
      return typeof this.existingVersion !== 'undefined'
        ? `version ${this.existingVersion}`
        : `default`;
    }
  },
  methods: {
    close() {
      this.closeDialog();
    },
    renderObject(obj: any): string {
      return this.renderYaml
        ? this.stringifyYaml(obj)
        : this.stringifyJson(obj);
    },
    parseStringToObject(inputString: string): any {
      return this.renderYaml
        ? YAML.parse(inputString)
        : JSON.parse(inputString);
    },
    stringifyJson(obj: any) {
      return JSON.stringify(obj, null, 2);
    },
    stringifyYaml(obj: any) {
      return YAML.stringify(obj);
    },
    prettify() {
      if (!this.inputAsJson) {
        return;
      }
      if (this.renderYaml) {
        this.inputParameters = this.stringifyYaml(this.inputAsJson);
      } else {
        this.inputParameters = this.stringifyJson(this.inputAsJson);
      }
    },
    async submit() {
      const input = this.inputAsJson;
      this.errorSaving = null;
      this.submitting = true;
      try {
        await this.$api.projects.saveAnnotationConfiguration({
          projectId: this.project.id,
          uiName: this.ui.name,
          parameters: input,
          version:
            typeof this.existingVersion !== 'undefined'
              ? this.existingVersion + 1
              : 0
        });
        this.$emit('refresh');
        this.close();
      } catch (err) {
        this.errorSaving = err;
      } finally {
        this.submitting = false;
      }
    }
  },
  watch: {
    latestParametersResolved: {
      immediate: true,
      handler: function() {
        this.inputParameters = this.renderObject(this.latestParametersResolved);
      }
    },
    renderYaml: {
      handler: function(newVal) {
        if (newVal) {
          const asJson = JSON.parse(this.inputParameters);
          this.inputParameters = this.stringifyYaml(asJson);
        } else {
          const asJson = YAML.parse(this.inputParameters);
          this.inputParameters = this.stringifyJson(asJson);
        }
      }
    }
  }
});
