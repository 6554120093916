























































import { computed, defineComponent } from '@vue/composition-api';
import _concat from 'lodash/concat';
import LoadingModal from '@/components/LoadingModal.vue';
import MyTasks from '@/components/MyTasks.vue';
import MyFinishedTasks from '@/components/MyFinishedTasks.vue';
import {
  useSelectedOrganization,
  useCurrentUser,
  useUserOrganizations
} from '@/store/use';
import {
  useCurrentUserTasks,
  useCurrentUserReviewTasks,
  useOrganizationProjects
} from '@/api/use';
import HomeProjects from '@/components/home/HomeProjects.vue';

export default defineComponent({
  name: 'Home',
  components: {
    LoadingModal,
    MyTasks,
    MyFinishedTasks,
    HomeProjects
  },
  props: {},
  setup(props, { root: { $store: store } }) {
    const { user } = useCurrentUser(store);

    const {
      loading: loadingTasks,
      tasks,
      error: errorLoadingTasks,
      mutate: mutateTasks
    } = useCurrentUserTasks(user);

    const {
      loading: loadingReviewTasks,
      review_tasks,
      error: errorLoadingReviewTasks,
      mutate: mutateReviewTasks
    } = useCurrentUserReviewTasks(user);

    const { userOrganizations } = useUserOrganizations(store);
    const hasOrganizations = computed(() => userOrganizations.value.length > 0);

    const { selectedOrganization } = useSelectedOrganization(store);

    const selectedOrganizationId = computed(
      () => selectedOrganization.value?.id
    );

    const {
      projects,
      loading: loadingProjects,
      error: errorLoadingProjects
    } = useOrganizationProjects(selectedOrganizationId);

    const hasProjects = computed(() => projects.value?.length > 0);

    const loading = computed(
      () =>
        loadingTasks.value || loadingReviewTasks.value || loadingProjects.value
    );
    const error = computed(
      () =>
        errorLoadingTasks.value ||
        errorLoadingReviewTasks.value ||
        errorLoadingProjects.value
    );

    const myTasksAndKind = computed(() =>
      tasks.value
        .filter(task => task.organization.id === selectedOrganization.value?.id)
        .map(task => ({ task: task as any, kind: 'AnnotationTask' }))
    );

    const myReviewTasksAndKind = computed(() =>
      review_tasks.value
        .filter(task => task.organization.id === selectedOrganization.value?.id)
        .map(task => ({ task: task as any, kind: 'ReviewTask' }))
    );

    const allTasksAndKind = computed(() => {
      return _concat(myTasksAndKind.value, myReviewTasksAndKind.value);
    });

    async function onRefresh() {
      mutateTasks();
      mutateReviewTasks();
    }

    const routeToCreateProject = {
      name: 'CreateProject'
    };

    return {
      myTasksAndKind,
      myReviewTasksAndKind,
      allTasksAndKind,
      mutateTasks,
      mutateReviewTasks,
      selectedOrganization,
      selectedOrganizationId,
      hasOrganizations,
      currentUser: user,
      onRefresh,
      loading,
      error,
      hasProjects,
      routeToCreateProject
    };
  }
});
