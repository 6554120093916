import {
  AnnotatedAsset,
  AnnotationRelease,
  AnnotationReleaseExport
} from '@/types';
import apiClient from './client';

const slug = 'annotation-releases';

export default {
  async getAnnotations({ releaseId }): Promise<AnnotatedAsset[]> {
    const res = await apiClient.get(`/${slug}/${releaseId}/annotations`);
    return res.annotations;
  },
  getRelease({ releaseId }: { releaseId: string }): Promise<AnnotationRelease> {
    return apiClient.get(`/${slug}/${releaseId}`);
  },
  async getReleaseExports({
    releaseId
  }: {
    releaseId: string;
  }): Promise<AnnotationReleaseExport[]> {
    const res = await apiClient.get(`/${slug}/${releaseId}/exports`);
    return res.exports;
  }
};
