



























import { defineComponent, toRefs } from '@vue/composition-api';
import _find from 'lodash/find';

import { AnnotationGuideline } from '@/types';
import { ApiException } from '@/api/client';
import LoadingModal from '@/components/LoadingModal.vue';
import UIs from '@/components/annotations/uis';
import { AnnotationUI } from '@/components/annotations/ui';
import UiGuidelinesTable from '@/components/UiGuidelinesTable.vue';

export default defineComponent({
  name: 'ConfigureUIGuidelines',
  components: {
    LoadingModal,
    UiGuidelinesTable
  },
  props: {
    orgId: {
      required: true,
      type: String
    },
    projectId: {
      required: true,
      type: String
    },
    uiName: {
      required: true,
      type: String
    }
  },
  data: () => ({
    project: null,
    loading: false,
    error: null,
    dataLoaded: true,
    versions: null as Array<AnnotationGuideline> | null
  }),
  setup(props) {
    const { orgId, projectId, uiName } = toRefs(props);

    return {
      currentOrganizationId: orgId,
      currentProjectId: projectId,
      currentUiName: uiName
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    ui(): AnnotationUI {
      return _find(UIs, ui => ui.name === this.currentUiName);
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.error = null;
      try {
        this.project = await this.$api.projects.getProject(
          this.currentProjectId
        );
        this.versions = await this.$api.projects.getAnnotationGuidelines({
          projectId: this.currentProjectId,
          uiName: this.currentUiName
        });
        this.dataLoaded = true;
      } catch (err) {
        if (!(err instanceof ApiException)) {
          console.error(err);
        }
        this.error = err;
      } finally {
        this.loading = false;
      }
    }
  }
});
