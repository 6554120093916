























































































import {
  computed,
  defineComponent,
  Ref,
  ref,
  toRefs,
} from "@vue/composition-api";
import { whenever } from "@vueuse/core";
import { useDataset } from "@/api/use";
import useApi from "@/api/useApi";
import { useSelectedOrganization } from "@/store/use";
import { Job } from "@/types";

const MAX_SIZE_MB = 10000;

function useUploadBatch({ datasetId }: { datasetId: Ref<string> }) {
  const submitting = ref(false);
  const uploading = ref(false);
  const success = ref(false);
  const error = ref<Error>(null);
  const job = ref<Job>(null);
  const uploadPercentage = ref(0);

  const api = useApi();

  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    const percent = Math.floor((loaded * 100) / total);
    if (percent <= 100) {
      uploadPercentage.value = percent;
      console.debug(`${loaded} bytes of ${total} bytes. ${percent}%`);
    }
  };

  async function upload({ file }: { file: File }) {
    submitting.value = true;
    uploading.value = false;
    success.value = false;
    job.value = null;
    error.value = null;

    try {
      const filename = file.name;
      console.log(`Creating batch with filename: ${filename}`);
      const batch = await api.datasets.createBatch({
        datasetId: datasetId.value,
        filename,
      });
      uploading.value = true;
      console.log(`Uploading file: ${file.name}`);
      await api.batches.uploadFile({
        batchId: batch.id,
        file,
        onUploadProgress,
      });
      uploading.value = false;
      console.log(`Triggering processing for batch: ${batch.id}`);
      job.value = await api.batches.triggerProcessing({ batchId: batch.id });
      success.value = true;
    } catch (err) {
      console.error("Error uploading batch", err);
      error.value = err;
    } finally {
      submitting.value = false;
      uploading.value = false;
    }
  }

  return {
    submitting,
    uploading,
    success,
    upload,
    error,
    job,
    uploadPercentage,
  };
}

function useShowUploadingToast({
  success,
  uploading,
}: {
  success: Ref<boolean>;
  uploading: Ref<boolean>;
}) {
  const showUploadingToast = ref(false);
  whenever(uploading, () => {
    showUploadingToast.value = true;
  });
  whenever(success, () => {
    showUploadingToast.value = false;
  });
  return { showUploadingToast };
}

export default defineComponent({
  name: "UploadBatch",
  props: {
    projectId: {
      required: false,
      type: String,
    },
    datasetId: {
      required: true,
      type: String,
    },
  },
  setup(props, { root: { $store } }) {
    const { datasetId, projectId } = toRefs(props);

    const { data: dataset } = useDataset(datasetId);
    const inputValid = ref(false);

    const { selectedOrganization } = useSelectedOrganization($store);

    const orgId = computed(() => selectedOrganization.value?.id);

    const routeToContinue = computed(() => {
      if (projectId.value) {
        return {
          name: "ProjectData",
          params: {
            projectId: projectId.value,
            orgId: orgId.value,
          },
        };
      } else {
        return {
          name: "Datasets",
          params: {
            orgId: orgId.value,
          },
        };
      }
    });

    const file = ref<File>(null);

    const fileRules = [
      (value: File) => !!value || "Choose file.",
      (value: File) =>
        !value ||
        value.size < MAX_SIZE_MB * 1024 * 1024 ||
        `Upload size must be less than ${MAX_SIZE_MB} MB.`,
    ];

    const {
      upload,
      submitting,
      uploading,
      success,
      error,
      job,
      uploadPercentage,
    } = useUploadBatch({
      datasetId,
    });

    const canSubmit = computed(
      () =>
        inputValid.value &&
        !!selectedOrganization.value &&
        !!datasetId.value &&
        !submitting.value &&
        !success.value
    );

    const errorMessages = computed(() =>
      error.value ? [error.value.message] : []
    );

    async function submit() {
      if (!inputValid.value || success.value) {
        return;
      }
      await upload({ file: file.value });
    }

    const errors = computed(() =>
      [error].filter((err) => !!err.value).map((err) => err.value)
    );

    // Notify other components that job was created
    whenever(job, function () {
      $store.dispatch("organizations/jobStarted", { id: job.value.id });
    });

    const { showUploadingToast } = useShowUploadingToast({
      success,
      uploading,
    });

    return {
      inputValid,
      submit,
      submitting,
      routeToContinue,
      file,
      fileRules,
      success,
      canSubmit,
      selectedOrganization,
      errors,
      dataset,
      errorMessages,
      uploading,
      job,
      showUploadingToast,
      uploadPercentage,
    };
  },
});
