











































import useApi from '@/api/useApi';
import { computed, defineComponent, ref, toRefs } from '@vue/composition-api';
import { PropType } from 'vue';
import { ApiException, BadRequest } from '@/api/client';

import { Batch } from '@/types';
import { useBatchTasks } from '@/api/use';

export default defineComponent({
  props: {
    batch: {
      required: true,
      type: Object as PropType<Batch>
    },
    closeDialog: {
      required: true,
      type: Function as PropType<() => void>
    }
  },
  setup(props, { emit }) {
    const { batch } = toRefs(props);
    const deleting = ref(false);
    const deleteError = ref(null as Error | null);

    const batchId = computed(() => batch.value.id);
    const { loading, error: errorLoading, tasks } = useBatchTasks({ batchId });

    const taskCount = computed(() => tasks.value.length);

    const errorMessage = computed(() => {
      if (deleteError.value == null) {
        return '';
      }

      if (deleteError.value instanceof BadRequest) {
        return deleteError.value.joined();
      } else {
        return deleteError.value.message;
      }
    });

    const api = useApi();

    function onClose() {
      deleteError.value = null;
      props.closeDialog();
    }

    async function onSubmit(): Promise<void> {
      console.log(`Deleting batch ${batchId.value}`);
      deleteError.value = null;
      deleting.value = true;
      try {
        await api.batches.deleteBatch({ batchId: batchId.value });
        emit('refresh');
        onClose();
      } catch (err) {
        console.error(`Error deleting batch`, err);
        if (err instanceof ApiException) {
          deleteError.value = err;
        } else {
          deleteError.value = err;
          throw err;
        }
      } finally {
        deleting.value = false;
      }
    }

    const dateToLong = (date: Date): string => {
      const formatter = new Intl.DateTimeFormat('default', {
        dateStyle: 'full',
        timeStyle: 'long'
      } as any);

      return formatter.format(date);
    };

    const rows = computed(() => [
      { name: 'ID', value: batch.value.id },
      { name: 'Filename', value: batch.value.filename },
      {
        name: 'Created by',
        value: batch.value.created_by?.name
      },
      {
        name: 'Created at',
        value: dateToLong(new Date(batch.value.created_at))
      }
    ]);

    return {
      taskCount,
      loading,
      errorLoading,
      deleting,
      deleteError,
      errorMessage,
      onSubmit,
      onClose,
      rows
    };
  }
});
