







































































import {
  defineComponent,
  PropType,
  toRefs,
  ref,
  onMounted
} from '@vue/composition-api';
import { whenever } from '@vueuse/core';

import { useCreateUserInOrganization } from '@/api/use';
import { ResourceConflict } from '@/api/client';
import { validateEmail } from '@/utils';

function useGeneratedPassword() {
  const LENGTH = 32;
  function generatePassword() {
    /**
     * https://stackoverflow.com/a/43020177/10561443
     */
    return new Array(LENGTH)
      .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
      .map(x =>
        (function(chars) {
          const umax = Math.pow(2, 32),
            r = new Uint32Array(1),
            max = umax - (umax % chars.length);
          do {
            crypto.getRandomValues(r);
          } while (r[0] > max);
          return chars[r[0] % chars.length];
        })(x)
      )
      .join('');
  }
  const password = ref(generatePassword());

  function regeneratePassword() {
    password.value = generatePassword();
  }

  return { password, regeneratePassword };
}

function useCopyToClipboard() {
  const copied = ref(false);
  const error = ref<Error>(null);

  async function copyToClipBoard(text: string) {
    copied.value = false;
    error.value = null;

    const result = await navigator.permissions.query({
      name: 'clipboard-write'
    } as any);

    if (result.state !== 'granted' && result.state !== 'prompt') {
      error.value = new Error(`Cannot copy to clip-board`);
    }

    try {
      await navigator.clipboard.writeText(text);
      copied.value = true;
    } catch (err) {
      error.value = err;
    }
  }

  return { copied, error, copyToClipBoard };
}

export default defineComponent({
  name: 'CreateUserCard',
  components: {},
  props: {
    organizationId: {
      required: true,
      type: String
    },
    closeDialog: {
      required: true,
      type: Function as PropType<() => void>
    },
    isOpen: {
      required: true,
      type: Boolean
    }
  },
  setup(props, { emit, refs }) {
    const { closeDialog, organizationId, isOpen } = toRefs(props);

    const {
      saving,
      error: errorCreating,
      createUser
    } = useCreateUserInOrganization(organizationId);

    const unexpectedError = ref<Error>(null);

    const inputValid = ref(false);
    const success = ref(false);

    const name = ref('');
    const nameRules = [(v: string) => !!v || 'Name cannot be empty'];
    const email = ref('');
    const emailRules = [
      (v: string) => validateEmail(v) || 'Invalid e-mail address'
    ];

    const emailErrorMessages = ref<string[]>([]);

    const { password, regeneratePassword } = useGeneratedPassword();
    const passwordRules = [
      (v: string) =>
        (!!v && v.length >= 8) || 'Password must have at least 8 characters'
    ];

    const form = ref<HTMLFormElement>(null);

    onMounted(function() {
      form.value = refs.form as HTMLFormElement;
    });

    function reset() {
      form?.value.reset();
      errorCreating.value = null;
      success.value = false;
      unexpectedError.value = null;
      regeneratePassword();
    }

    whenever(isOpen, function() {
      reset();
    });

    async function submit() {
      unexpectedError.value = null;
      await createUser({
        name: name.value,
        email: email.value,
        password: password.value
      });

      emit('refresh');

      if (!errorCreating.value) {
        success.value = true;
      } else if (errorCreating.value instanceof ResourceConflict) {
        emailErrorMessages.value = ['User with e-mail already exists'];
      } else {
        unexpectedError.value = errorCreating.value;
      }
    }

    function close() {
      form.value?.reset();
      closeDialog.value();
    }

    const { copied, copyToClipBoard } = useCopyToClipboard();

    function copyPasswordToClipBoard() {
      copyToClipBoard(password.value);
    }

    return {
      saving,
      unexpectedError,
      createUser,
      submit,
      name,
      nameRules,
      email,
      emailRules,
      password,
      passwordRules,
      inputValid,
      close,
      success,
      copyPasswordToClipBoard,
      copied,
      regeneratePassword,
      emailErrorMessages
    };
  }
});
