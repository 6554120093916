

















import Vue from 'vue';
import { ApiException } from '@/api/client';
import ProjectUisTable from '../ProjectUisTable.vue';
import LoadingModal from '../LoadingModal.vue';

import { Project } from '@/types';

export default Vue.extend({
  components: {
    ProjectUisTable,
    LoadingModal
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    orgId: {
      type: String,
      required: true
    }
  },
  created() {
    this.fetchData();
  },
  data: function() {
    return {
      loading: false,
      error: null as string | null,
      project: null as Project | null
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.error = null;
      try {
        const project = await this.$api.projects.getProject(this.projectId);
        this.project = project;
      } catch (err) {
        if (!(err instanceof ApiException)) {
          console.error(err);
        }
        this.error = err;
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    projectId: function() {
      this.fetchData();
    }
  }
});
