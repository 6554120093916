
























































































import { PropType } from 'vue';
import _find from 'lodash/find';
import {
  computed,
  defineComponent,
  ref,
  toRefs,
  watch
} from '@vue/composition-api';
import { or } from '@vueuse/core';

import { useCreateReviewTask, useOrganizationMembers } from '@/api/use';
import { AnnotationTask, CompactUser } from '@/types';
import { firstTruthy } from '@/utils';
import AnnotationUIs from '@/components/annotations/uis';

export default defineComponent({
  props: {
    task: {
      required: true,
      type: Object as PropType<AnnotationTask>
    },
    closeDialog: {
      required: true,
      type: Function as PropType<() => void>
    }
  },
  setup(props, { emit }) {
    const assignee = ref(null as CompactUser | null);
    const { task, closeDialog } = toRefs(props);
    const projectId = computed(() => task.value?.project.id);
    const tasks = computed(() => (task.value ? [task.value] : []));
    const organizationId = computed(() => task.value?.organization.id);
    const {
      members: availableAssignees,
      loading: loadingMembers,
      error: errorLoadingMembers
    } = useOrganizationMembers(organizationId);

    const selectedTask = ref(null as AnnotationTask | null);

    watch(task, () => (selectedTask.value = task.value), { immediate: true });

    const uiName = computed(() => task.value?.ui.name);
    const selectedUi = computed(() =>
      _find(AnnotationUIs, UI => UI.name === uiName.value)
    );
    const uiSupportsReview = computed(
      () => selectedUi.value?.supportsReview || false
    );

    const inputValid = ref(false);

    const taskIds = computed(() => tasks.value.map(task => task.id));
    const assigneeId = computed(() => assignee.value?.id);

    const {
      create: createReviewTask,
      creating,
      error: errorCreating,
      success
    } = useCreateReviewTask({ projectId, taskIds, assigneeId });

    async function submit() {
      await createReviewTask();
      setTimeout(() => {
        closeDialog.value();
        emit('refresh');
      }, 1500);
    }

    const loading = or(loadingMembers, creating);
    const error = firstTruthy(errorLoadingMembers, errorCreating);

    return {
      assignee,
      availableAssignees,
      loading,
      error,
      tasks,
      selectedTask,
      inputValid,
      submit,
      success,
      uiSupportsReview,
      selectedUi
    };
  }
});
