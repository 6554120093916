
























































import { computed, defineComponent, toRefs, watch } from '@vue/composition-api';
import LoadingModal from '@/components/LoadingModal.vue';
import {
  useSelectedOrganization,
  useCurrentUser,
  useUserOrganizations
} from '@/store/use';
import {
  useCanCreateOrganization,
  useCurrentUserOrganizations,
  useHasPermission
} from '@/api/use';
import HomeViewSelector from '@/components/molecules/HomeViewSelector.vue';
import AnnotatorHomeView from '@/components/home/AnnotatorHomeView.vue';
import AdminHomeView from '@/components/home/AdminHomeView.vue';
import { until } from '@vueuse/core';

export default defineComponent({
  name: 'Home',
  components: {
    LoadingModal,
    HomeViewSelector,
    AnnotatorHomeView,
    AdminHomeView
  },
  props: {
    view: {
      required: false,
      type: String // admin or annotator
    }
  },
  setup(props, { root: { $store: store, $router } }) {
    const { view } = toRefs(props);
    const { user } = useCurrentUser(store);

    const {
      loading: loadingOrganizations,
      organizations,
      error: errorLoadingOrganizations
    } = useCurrentUserOrganizations(user);

    const { userOrganizations } = useUserOrganizations(store);
    const hasOrganizations = computed(() => userOrganizations.value.length > 0);

    const { selectedOrganization } = useSelectedOrganization(store);

    const selectedOrganizationId = computed(
      () => selectedOrganization.value?.id
    );

    const {
      hasPermission: isAdmin,
      loading: loadingIsAdmin,
      error: errorLoadingIsAdmin,
      loadedForUser: loadedIsAdminForUser
    } = useHasPermission({
      organization: selectedOrganization,
      user,
      permission: 'organizations.get'
    });

    const loading = computed(
      () => loadingOrganizations.value || loadingIsAdmin.value
    );
    const error = computed(
      () => errorLoadingOrganizations.value || errorLoadingIsAdmin.value
    );

    function setView(value: string) {
      $router.replace({ query: { view: value } });
    }

    const { canCreateOrganization } = useCanCreateOrganization(user);

    const routeToCreateOrganization = {
      name: 'CreateOrganization'
    };

    // Set default home view based on whether user is admin or not
    watch(
      [view, loadedIsAdminForUser],
      async function() {
        if (!user.value || !selectedOrganization.value) {
          return;
        }

        const currentViewValue = view.value;

        await until(loadedIsAdminForUser).toMatch(
          v => v?.id === user.value?.id
        );

        if (currentViewValue !== view.value) {
          return;
        }

        if (view.value !== 'admin' && view.value !== 'annotator') {
          const newView = isAdmin.value ? 'admin' : 'annotator';
          console.log(`Redirecting user to home view: ${newView}`);
          setView(newView);
          return;
        }
      },
      { immediate: true }
    );

    return {
      organizations,
      selectedOrganization,
      selectedOrganizationId,
      hasOrganizations,
      currentUser: user,
      loading,
      error,
      isAdmin,
      setView,
      canCreateOrganization,
      routeToCreateOrganization
    };
  }
});
