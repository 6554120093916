import { computed, ComputedRef, unref, Ref, ref } from '@vue/composition-api';
import _find from 'lodash/find';

export const trimToPrecision = (v: number, digits = 3): string => {
  const rounded = v.toPrecision(digits);

  // the regexp trims the trailing zeros after the decimal point
  // as well as the point itself if no digits would remain in the fractional part
  const shortened = rounded.replace(/(?:\.0+|(\.\d+?)0+)$/, '$1');
  return `${shortened}%`;
};

const locale = 'default';

export const dateToLongFormat = (date: Date): string => {
  const formatter = new Intl.DateTimeFormat(locale, {
    dateStyle: 'full',
    timeStyle: 'long'
  } as any);

  return formatter.format(date);
};

export const dateToShortFormat = (date: Date): string => {
  const formatter = new Intl.DateTimeFormat(locale, {
    dateStyle: 'medium'
  } as any);

  return formatter.format(date);
};

export type MaybeRef<T> = T | Ref<T>;

/**
 * `OR` conditions for refs.
 * From Vue Use
 * https://github.com/vueuse/vueuse/blob/main/packages/shared/or/index.ts
 */
export function or(...args: MaybeRef<boolean>[]): ComputedRef<boolean> {
  return computed(() => args.some(i => unref(i)));
}

export function firstTruthy<K>(...args: Ref<K>[]): ComputedRef<K | undefined> {
  return computed(() => _find(args.map(arg => unref(arg))));
}

export async function loadScript(src: string) {
  /* https://github.com/tserkov/vue-plugin-load-script/blob/master/index.js */
  return new Promise(function(resolve, reject) {
    let shouldAppend = false;
    let scriptElement = document.querySelector(
      'script[src="' + src + '"]'
    ) as HTMLScriptElement;
    if (!scriptElement) {
      scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.async = true;
      scriptElement.src = src;
      shouldAppend = true;
    } else if (scriptElement.hasAttribute('data-loaded')) {
      resolve(scriptElement);
      return;
    }

    scriptElement.addEventListener('error', reject);
    scriptElement.addEventListener('abort', reject);
    scriptElement.addEventListener('load', function loadScriptHandler() {
      scriptElement.setAttribute('data-loaded', 'true');
      resolve(scriptElement);
    });

    if (shouldAppend) document.head.appendChild(scriptElement);
  });
}

export function validateEmail(s: string): boolean {
  // https://stackoverflow.com/a/201447/10561443
  return /^\S+@\S+\.\S+$/.test(s);
}

export function useCopyToClipboard() {
  const copied = ref(false);
  const error = ref<Error>(null);

  async function copyToClipBoard(text: string) {
    copied.value = false;
    error.value = null;

    const result = await navigator.permissions.query({
      name: 'clipboard-write'
    } as any);

    if (result.state !== 'granted' && result.state !== 'prompt') {
      error.value = new Error(`Cannot copy to clip-board`);
    }

    try {
      await navigator.clipboard.writeText(text);
      copied.value = true;
    } catch (err) {
      error.value = err;
    }
  }

  return { copied, error, copyToClipBoard };
}
