

































import { useProject, useProjectStatistics } from '@/api/use';
import { computed, defineComponent, ref, toRefs } from '@vue/composition-api';
import { trimToPrecision } from '@/utils';

export default defineComponent({
  props: {
    projectId: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const { projectId } = toRefs(props);

    const {
      project,
      loading: loadingProject,
      error: errorLoadingProject
    } = useProject(projectId);

    const {
      statistics,
      loading: loadingStatistics,
      error: errorLoadingStatistics
    } = useProjectStatistics({ projectId });

    const keyValuePairsLeft = computed(() => {
      if (!statistics.value) {
        return [];
      }
      const counts = statistics.value.counts;
      return [
        {
          key: 'Datasets',
          value: counts.datasets
        },
        {
          key: 'Batches',
          value: counts.batches
        },
        {
          key: 'Batches assigned',
          value: counts.batches_with_tasks
        },
        {
          key: 'Assets',
          value: counts.assets
        }
      ];
    });

    const keyValuePairsRight = computed(() => {
      if (!statistics.value) {
        return [];
      }
      const counts = statistics.value.counts;
      return [
        {
          key: 'Annotation tasks',
          value: counts.tasks
        },

        {
          key: 'Annotations',
          value: counts.annotations
        },
        {
          key: 'Assets annotated',
          value: counts.annotated_assets
        },
        {
          key: 'Annotations assigned',
          value: counts.annotations_assigned
        },
        {
          key: 'Annotations assigned done',
          value: counts.annotations_assigned_done
        }
      ];
    });

    const annotatedPercentage = computed(() => {
      if (!statistics.value) {
        return 0;
      }
      const counts = statistics.value.counts;
      return (counts.annotated_assets / counts.assets) * 100.0;
    });

    const assignedAnnotationsDonePercentage = computed(() => {
      if (!statistics.value) {
        return 0;
      }
      const counts = statistics.value.counts;
      return (
        (counts.annotations_assigned_done / counts.annotations_assigned) * 100.0
      );
    });

    const organizationId = computed(() => project.value?.organization.id);

    const errors = computed(() =>
      [errorLoadingProject, errorLoadingStatistics].filter(v => !!v.value)
    );

    const loading = computed(
      () => loadingProject.value || loadingStatistics.value
    );

    function projectRoute(projectId: string) {
      if (!organizationId.value) {
        return null;
      }
      return {
        name: 'ProjectTasks',
        params: {
          orgId: organizationId?.value,
          projectId: projectId
        }
      };
    }

    const hover = ref(false);

    return {
      project,
      loading,
      errors,
      statistics,
      trimToPrecision,
      keyValuePairsLeft,
      keyValuePairsRight,
      annotatedPercentage,
      assignedAnnotationsDonePercentage,
      projectRoute,
      hover
    };
  }
});
