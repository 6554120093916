









import { useProjectDatasets } from '@/api/use';
import { computed, defineComponent, toRefs } from '@vue/composition-api';

export default defineComponent({
  name: 'NudgeAddDatasetToProject',
  props: {
    projectId: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const { projectId } = toRefs(props);

    const { datasets, loading, error } = useProjectDatasets(projectId);

    const show = computed(
      () => !loading.value && !error.value && datasets.value.length === 0
    );

    return { show };
  }
});
