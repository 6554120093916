import { Batch, Dataset, Job } from '@/types';
import apiClient from './client';
import JobsApi from './jobs';

const slug = 'datasets';

export default {
  async uploadBatch({ datasetId, file }): Promise<Job> {
    console.log(`Uploading ${file.name} to ${datasetId}`);
    const endpoint = `/${slug}/${datasetId}/upload`;
    const formData = new FormData();
    formData.append('file', file);
    const uploadRes = await apiClient.post(endpoint, formData);
    return uploadRes.job;
  },
  async uploadBatchBlocking({ datasetId, file }): Promise<any> {
    const job = await this.uploadBatch({ datasetId, file });
    const batch = await JobsApi.waitForBatchUploadJob({ jobId: job.id });
    return batch;
  },
  createBatch({
    datasetId,
    name,
    filename
  }: {
    datasetId: string;
    name?: string;
    filename: string;
  }): Promise<Batch> {
    const payload = { name, filename };
    return apiClient.post(`/${slug}/${datasetId}/batches`, payload);
  },
  getDataset({ datasetId }): Promise<Dataset> {
    return apiClient.get(`/${slug}/${datasetId}`);
  },
  renameDataset({ datasetId, datasetName }): Promise<Dataset> {
    return apiClient.patch(`/${slug}/${datasetId}`, {
      name: datasetName
    });
  },
  async listBatches({ datasetId }): Promise<Batch[]> {
    const res = await apiClient.get(`/${slug}/${datasetId}/batches`);
    return res.batches;
  },
  async deleteDataset({ datasetId }): Promise<void> {
    await apiClient.delete(`/${slug}/${datasetId}`);
  }
};
