import OrgApi from '../../api/organizations';

function getDefaultState() {
  return {
    organizationProjects: [],
    selectedProject: undefined
  };
}
export default {
  namespaced: true,
  state: {
    organizationProjects: [],
    selectedProject: undefined
  },
  getters: {
    organizationProjects(state) {
      return state.organizationProjects;
    },
    selectedProject(state) {
      return state.selectedProject;
    },
    getProjectById: state => id => {
      return state.organizationProjects.find(proj => proj.id === id);
    }
  },
  actions: {
    getOrganizationProjects() {
      /* OrgApi.getOrganizationProjects(
        context.rootGetters['organizations/selectedOrganization'].id
      )
        .then(response => {
          const organizationProjects = response.data;
          context.commit('SET_ORGANIZATION_PROJECTS', organizationProjects);
          if (
            !context.state.selectedProject &&
            organizationProjects.length > 0
          ) {
            context.commit('SET_SELECTED_PROJECT', organizationProjects[0]);
          }
        })
        .catch(() => {
          context.commit('SET_ORGANIZATION_PROJECTS', []);
        }); */
    },
    createProject(context, newProject) {
      OrgApi.createProject(newProject)
        .then(newOrg => {
          context.commit('ADD_NEW_PROJECT', newOrg);
        })
        .catch(err => {
          console.log('Failed to create org: ', err);
        });
    },
    setSelectedProject(context, project) {
      context.commit('SET_SELECTED_PROJECT', project);
    },
    resetState(context) {
      context.commit('RESET_STATE');
    }
  },
  mutations: {
    SET_ORGANIZATION_PROJECTS: (state, organizationProjects) => {
      state.organizationProjects = organizationProjects;
    },
    SET_SELECTED_PROJECT: (state, project) => {
      state.selectedProject = project;
    },
    ADD_NEW_PROJECT: (state, newProject) => {
      state.organizationProjects.push(newProject);
    },
    RESET_STATE: state => {
      Object.assign(state, getDefaultState());
    }
  }
};
