import axios, { AxiosError, AxiosInstance, Method, ResponseType } from 'axios';

import { encodeGetParams } from '@/api/utils';
import { BACKEND_BASE_URL } from '@/api/config';

export interface PaginationParameters {
  url: string;
  query: Record<string, any>;
  // Which key to use in the response payload to gather results
  responseKey: string;
}

export class ApiException extends Error {
  public readonly url: string;
  public readonly code: number;
  constructor(message: string, code: number, url: string) {
    super(message);
    this.url = url;
    this.code = code;
  }
}

export interface ApiErrorSource {
  pointer: string;
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface ApiError {
  source: ApiErrorSource;
  title: string;
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export class BadRequest extends ApiException {
  errors: ApiError[];
  constructor(errors: ApiError[], url) {
    super('Bad request', 400, url);
    this.errors = errors;
  }

  joined(): string {
    return this.errors.map(err => err.title).join(',');
  }
}

export class Unauthorized extends ApiException {
  constructor(message: string, url: string) {
    super(message, 401, url);
  }
}

export class Forbidden extends ApiException {
  constructor(message: string, url: string) {
    super(message, 403, url);
  }
}

export class NotFound extends ApiException {
  constructor(message: string, url: string) {
    super(message, 404, url);
  }
}

export class ResourceConflict extends ApiException {
  constructor(message: string, url: string) {
    super(message, 409, url);
  }
}

export class JobFailedException extends Error {
  constructor(message: string) {
    super(message);
  }
}

export class JobTimeoutException extends Error {
  constructor(message: string) {
    super(message);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isAxiosError(error: any): error is AxiosError {
  return 'isAxiosError' in error && error.isAxiosError;
}

/**
 * Handles all calls to the backend, with logging and error handling.
 */
export class ApiClient {
  public readonly axios: AxiosInstance;
  private readonly baseURL: string;
  public static readonly PER_PAGE = 500;

  constructor(axiosInstance: AxiosInstance = null) {
    this.baseURL = BACKEND_BASE_URL;
    if (axiosInstance === null) {
      axiosInstance = axios.create({ baseURL: this.baseURL });
    }
    this.axios = axiosInstance;
  }

  async request({
    url,
    method = 'GET',
    data = undefined,
    headers = {},
    responseType = 'json',
    onUploadProgress = undefined
  }: {
    url: string;
    method?: Method;
    data?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    headers?: Record<string, string>;
    responseType?: ResponseType;
    onUploadProgress?: (progressEvent: ProgressEvent) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  }) {
    try {
      const response = await this.axios.request({
        url,
        method,
        data,
        headers,
        responseType,
        onUploadProgress
      });
      return response.status === 204 ? undefined : response.data;
    } catch (error) {
      if (!isAxiosError(error)) {
        console.error(
          `Unknown error on request: ${method.toUpperCase()} ${url}`,
          error
        );
        throw error;
      }

      if (!error.response) {
        console.error(
          `No response from server on request: ${method.toUpperCase()} ${url}`,
          error
        );
        throw new Error(
          `No response from the server on request: ${method.toUpperCase()} ${url}. The server might be temporarily down.`
        );
      }

      const status = error.response.status;
      const data = error.response.data;
      if (status === 400) {
        throw new BadRequest(data.errors, url);
      }
      if (status == 401) {
        throw new Unauthorized('Unauthorized.', url);
      }
      if (status == 403) {
        const msg = data.errors.map(err => err.title).join(', ');
        throw new Forbidden(msg, url);
      }
      if (status == 404) {
        throw new NotFound('Resource not found', url);
      }
      if (status == 409) {
        const msg = 'Resource already exists';
        throw new ResourceConflict(msg, url);
      }
      throw new ApiException(
        `Request to ${url} failed with code ${status} and data: ${JSON.stringify(
          data
        )}`,
        status,
        url
      );
    }
  }

  public stripBaseUrl(url: string): string {
    /** Strip base URL from the beginning of the URL */
    if (!url.startsWith(this.baseURL)) {
      throw Error(`url does not start with: ${this.baseURL}`);
    }
    return url.slice(this.baseURL.length);
  }

  /**
   * Make a POST request to backend.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async post(path: string, data: any, headers = {}) {
    return this.request({ url: path, method: 'post', data, headers });
  }

  /**
   * Make a PUT request to backend.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async put(
    path: string,
    data: any = undefined,
    headers = {},
    onUploadProgress: (progressEvent: ProgressEvent) => void = undefined
  ) {
    return this.request({
      url: path,
      method: 'put',
      data,
      headers,
      onUploadProgress
    });
  }

  /**
   * Make a PATCH request to backend.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async patch(path: string, data: any, headers = {}) {
    return this.request({ url: path, method: 'patch', data, headers });
  }

  /**
   * Make a DELETE request to backend.
   */
  delete(path: string) {
    return this.request({ url: path, method: 'delete' });
  }

  /**
   * Make a GET request to backend.
   * @param {string} path - Request path, excluding base URL
   */
  get(path: string) {
    return this.request({ url: path });
  }

  /**
   * Fetch binary data from server.
   * URL must point to the same domain so starting with,
   * for example, "/api/v1/".
   */
  getBinaryData(url: string): Promise<ArrayBuffer> {
    if (!url.startsWith('/')) {
      throw Error(
        `Did not expect URL not starting with '/', cannot fetch binary data from: ${url}`
      );
    }

    const path = url.startsWith(this.baseURL) ? this.stripBaseUrl(url) : url;

    const acceptType = 'application/octet-stream';

    return this.request({
      method: 'GET',
      url: path,
      responseType: 'arraybuffer',
      headers: {
        accept: acceptType
      }
    });
  }

  public async getPage({
    url,
    query,
    page,
    perPage = ApiClient.PER_PAGE
  }: {
    url: string;
    query: Record<string, any>;
    page: number;
    perPage?: number;
  }) {
    const qs = {
      page,
      per_page: perPage,
      ...query
    };
    const encoded = encodeGetParams(qs);
    const res = await this.get(`${url}?${encoded}`);
    return res;
  }

  async getPaginated({ url, query, responseKey }: PaginationParameters) {
    let res = await this.getPage({ url, query, page: 1 });
    const resultSet = res[responseKey];

    if (!res.pagination) {
      throw new Error(`Endpoint does not support pagination at URL: ${url}`);
    }

    while (res.pagination.next) {
      const nextWithoutBaseUrl = this.stripBaseUrl(res.pagination.next);
      res = await this.get(nextWithoutBaseUrl);
      resultSet.push(...res[responseKey]);
    }
    return resultSet;
  }
}

const apiClient = new ApiClient();

export default apiClient;
