import apiClient from './client';
import qs from 'query-string';
import { CompactUser, GoogleSignInSettings } from '@/types';

export default {
  checkJwt(): Promise<CompactUser> {
    return apiClient.get(`/me/`);
  },

  register({ firstName, lastName, email, password }): Promise<CompactUser> {
    const stringPayload = qs.stringify({
      first_name: firstName,
      last_name: lastName,
      email,
      password
    });

    return apiClient.post(`/register`, stringPayload, {
      'Content-Type': 'application/x-www-form-urlencoded'
    });
  },

  login({ email, password }): Promise<CompactUser> {
    const stringPayload = qs.stringify({
      email,
      password
    });

    return apiClient.post(`/login`, stringPayload, {
      'Content-Type': 'application/x-www-form-urlencoded'
    });
  },

  async getGoogleSignInSettings(): Promise<GoogleSignInSettings> {
    const res = await apiClient.get(`/auth/google/settings`);
    return {
      enabled: res.enabled,
      clientId: res.client_id,
      loginUri: res.login_uri
    };
  },

  async logout(): Promise<void> {
    await apiClient.post(`/logout`, {});
  }
};
