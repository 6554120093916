

















































import { computed, defineComponent, toRefs } from '@vue/composition-api';

import {
  useProject,
  useProjectTasksExpanded,
  useProjectReviewTasks
} from '@/api/use';

import ProjectTasksTable from '@/components/project/ProjectTasksTable.vue';
import ProjectReviewTasksTable from '@/components/project/ProjectReviewTasksTable.vue';
import ProjectStatistics from '@/components/project/ProjectStatistics.vue';
import LoadingModal from '@/components/LoadingModal.vue';
import NudgeAddDatasetToProject from '../molecules/NudgeAddDatasetToProject.vue';

export default defineComponent({
  components: {
    ProjectTasksTable,
    LoadingModal,
    ProjectStatistics,
    ProjectReviewTasksTable,
    NudgeAddDatasetToProject
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    orgId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { projectId } = toRefs(props);

    const {
      project,
      error: errorProject,
      loading: loadingProject
    } = useProject(projectId);

    const {
      tasks,
      error: errorTasks,
      loading: loadingTasks,
      mutate: refetchAnnotationTasks
    } = useProjectTasksExpanded(projectId);

    const {
      tasks: reviewTasks,
      error: errorReviewTasks,
      loading: loadingReviewTasks,
      mutate: refetchReviewTasks
    } = useProjectReviewTasks(projectId);

    async function refresh() {
      await Promise.all([refetchReviewTasks(), refetchAnnotationTasks()]);
    }

    const error = computed(
      () => errorProject.value || errorTasks.value || errorReviewTasks.value
    );
    const loading = computed(
      () =>
        loadingProject.value || loadingTasks.value || loadingReviewTasks.value
    );

    return {
      project,
      tasks,
      reviewTasks,
      error,
      loading,
      refresh
    };
  }
});
