























import { defineComponent, ref, toRefs, watch } from '@vue/composition-api';
import { PropType } from 'vue';
import { OrganizationMember, CompactUser } from '@/types';
import { useManageAdminUsers } from '@/api/use';
import { whenever } from '@vueuse/core';

export default defineComponent({
  name: 'OrganizationMemberAdminCheckbox',
  props: {
    organizationId: {
      required: true,
      type: String
    },
    member: {
      required: true,
      type: Object as PropType<OrganizationMember>
    },
    currentUser: {
      required: true,
      type: Object as PropType<CompactUser>
    }
  },
  setup(props, { emit }) {
    const { organizationId, member } = toRefs(props);

    const {
      createAdminStatus,
      deleteAdminStatus,
      saving,
      error
    } = useManageAdminUsers(organizationId);

    const showError = ref(false);

    whenever(error, function() {
      showError.value = true;
    });

    const localValue = ref(member.value.is_admin);

    watch(
      member,
      function() {
        localValue.value = member.value.is_admin;
      },
      { deep: true }
    );

    async function onChange() {
      if (localValue.value) {
        await createAdminStatus({ userId: member.value.id });
      } else {
        await deleteAdminStatus({ userId: member.value.id });
      }
      emit('refresh');
    }

    return { localValue, onChange, saving, showError, error };
  }
});
