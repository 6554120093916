/**
 * Note: We need to duplicate the types here instead of inferring types from Zod types.
 * We don't have strict null checks enabled so Zod inference doesn't work correctly
 * https://github.com/colinhacks/zod/issues/121#issuecomment-675671241
 */
import * as zod from 'zod';
import validateColor from 'validate-color';

export const DEFAULT_COLOR = '#ff1a1a';

export interface ImageObjectCategory {
  name: string;
  color: string;
}

export interface SkeletonGraphDefinitionNode {
  name: string;
  x: number;
  y: number;
}

export interface SkeletonGraphDefinition {
  name: string;
  nodes: SkeletonGraphDefinitionNode[];
}

export interface ImageAnnotatorParameters {
  labels: ImageObjectCategory[];
  skeletons: SkeletonGraphDefinition[];
}

export const DEFAULT_SKELETON: SkeletonGraphDefinition = {
  name: 'Human',
  nodes: [
    {
      name: 'nose',
      x: 0,
      y: -60
    },
    {
      name: 'left_eye',
      x: -20,
      y: -40
    },
    {
      name: 'right_eye',
      x: 20,
      y: -40
    },
    {
      name: 'left_ear',
      x: -30,
      y: -50
    },
    {
      name: 'right_ear',
      x: 30,
      y: -50
    },
    {
      name: 'left_shoulder',
      x: -50,
      y: -20
    },
    {
      name: 'right_shoulder',
      x: 50,
      y: -20
    },
    {
      name: 'left_elbow',
      x: -70,
      y: 10
    },
    {
      name: 'right_elbow',
      x: 70,
      y: 10
    },
    {
      name: 'left_wrist',
      x: -60,
      y: 40
    },
    {
      name: 'right_wrist',
      x: 60,
      y: 40
    },
    {
      name: 'left_hip',
      x: -35,
      y: 30
    },
    {
      name: 'right_hip',
      x: 35,
      y: 30
    },
    {
      name: 'left_knee',
      x: -40,
      y: 60
    },
    {
      name: 'right_knee',
      x: 40,
      y: 60
    },
    {
      name: 'left_ankle',
      x: -30,
      y: 100
    },
    {
      name: 'right_ankle',
      x: 30,
      y: 100
    }
  ]
};

export const DEFAULT_CONFIG: ImageAnnotatorParameters = {
  labels: [
    {
      name: 'Car',
      color: '#ff1a1a'
    },
    {
      name: 'Bus',
      color: '#1aff1a'
    },
    {
      name: 'Sign',
      color: '#1a1aff'
    },
    {
      name: 'Pedestrian',
      color: '#ffff1a'
    }
  ],
  skeletons: [DEFAULT_SKELETON]
};

const ColorNameSchema = zod
  .string()
  .refine((name: string) => validateColor(name), {
    message: 'Invalid CSS color'
  });

const LabelObjectSchema = zod.object({
  name: zod.string(),
  color: ColorNameSchema
});

const SkeletonObjectNodeSchema = zod.object({
  name: zod.string(),
  x: zod.number(),
  y: zod.number()
});

const SkeletonObjectSchema = zod.object({
  name: zod.string(),
  nodes: zod.array(SkeletonObjectNodeSchema)
});

export const ImageAnnotatorParameterSchema = zod.object({
  labels: zod.union([
    zod.array(LabelObjectSchema).nonempty(),
    zod.array(zod.string()).nonempty()
  ]),
  skeletons: zod
    .array(SkeletonObjectSchema)
    .max(1, 'Must have at most one skeleton')
});

export type ImageAnnotatorParametersFromSchema = zod.infer<
  typeof ImageAnnotatorParameterSchema
>;

export type SkeletonGraphDefinitionFromSchema = zod.infer<
  typeof SkeletonObjectSchema
>;

export type SkeletonGraphDefinitionNodeFromSchema = zod.infer<
  typeof SkeletonObjectNodeSchema
>;

export const resolveParameters = (params: any): ImageAnnotatorParameters => {
  const migratedLabels = params.labels
    ? params.labels.map(label =>
        typeof label === 'string'
          ? { name: label, color: DEFAULT_COLOR }
          : label
      )
    : DEFAULT_CONFIG.labels;

  const migratedParams = { ...params, labels: migratedLabels };

  return { ...DEFAULT_CONFIG, ...migratedParams };
};
