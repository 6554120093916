
































































import { PropType } from 'vue';
import { defineComponent, ref } from '@vue/composition-api';
import { toRefs, whenever } from '@vueuse/core';

import { CompactUser } from '@/types';
import Tooltip from '@/components/molecules/Tooltip.vue';
import useApi from '@/api/useApi';

export default defineComponent({
  components: { Tooltip },
  props: {
    organizationId: {
      type: String,
      required: true
    },
    currentUser: {
      type: Object as PropType<CompactUser>,
      required: true
    },
    member: {
      type: Object as PropType<CompactUser>,
      required: true
    }
  },
  setup(props, { emit }) {
    const { member, organizationId } = toRefs(props);
    const showDialog = ref(false);
    const deleting = ref(false);
    const deleteError = ref<Error>(null);
    const isOpen = ref(false);
    const success = ref(false);

    const api = useApi();

    function closeDialog() {
      isOpen.value = false;
    }

    async function onSubmit(): Promise<void> {
      deleteError.value = null;
      deleting.value = true;
      try {
        await api.organizations.removeUserAsMember({
          email: member.value.email,
          organizationId: organizationId.value
        });
        success.value = true;
      } catch (err) {
        deleteError.value = err;
      } finally {
        deleting.value = false;
      }
    }

    whenever(success, function() {
      setTimeout(function() {
        closeDialog();
        emit('refresh');
      }, 2000);
    });

    return {
      showDialog,
      deleting,
      deleteError,
      onSubmit,
      closeDialog,
      success,
      isOpen
    };
  }
});
