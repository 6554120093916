var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.datasetHeaders,"items":_vm.datasetItems,"items-per-page":10,"sort-by":"createdAtRaw","sort-desc":false,"show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Datasets")]),_c('v-spacer'),_c('create-resource-button',{staticClass:"create-dataset-button",attrs:{"title":"Create dataset","fields":_vm.createDatasetFields,"onSubmit":_vm.createDataset}})],1),_c('div',{staticClass:"text-caption px-4"},[_c('p',[_vm._v(" Datasets consist of "),_c('b',[_vm._v("batches")]),_vm._v(" created by uploading a ZIP or CSV file to the dataset. Batches consist of "),_c('b',[_vm._v("assets")]),_vm._v(". One asset may have multiple "),_c('b',[_vm._v("media files")]),_vm._v(" and "),_c('b',[_vm._v("attributes")]),_vm._v(". To annotate assets in a dataset, the dataset must first be added to a project. ")])])]},proxy:true},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('v-icon',{attrs:{"disabled":item.nBatches === 0},on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v(_vm._s(isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"))]),_vm._v(" ("+_vm._s(item.nBatches)+") ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":"","close-on-content-click":false,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('open-dialog-button',{attrs:{"text":"","title":"Edit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var closeDialog = ref.closeDialog;
return [_c('edit-dataset-card',_vm._g({attrs:{"dataset":item,"closeDialog":closeDialog}},_vm.$listeners))]}}],null,true)})],1),_c('v-list-item',[_c('open-dialog-button',{attrs:{"text":"","title":"Delete"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var closeDialog = ref.closeDialog;
return [_c('delete-dataset-card',_vm._g({attrs:{"dataset":item,"closeDialog":closeDialog}},_vm.$listeners))]}}],null,true)})],1)],1)],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.toDataset(item)}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('date-item',{attrs:{"date":new Date(item.created_at)}})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('id-item',{attrs:{"id":item.id}})]}},{key:"item.upload",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":_vm.uploadBatchRoute(item.id),"small":"","color":"info"}},[_vm._v(" Upload "),_c('v-icon',{staticClass:"ml-2",attrs:{"x-small":""}},[_vm._v("mdi-cloud-upload")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-1",attrs:{"colspan":headers.length}},[_c('dataset-batches-table',{staticClass:"dataset-batches-table",attrs:{"organizationId":_vm.organizationId,"datasetId":item.id,"onCreateTask":_vm.onCreateTask}})],1)]}},(_vm.noDatasets)?{key:"no-data",fn:function(){return [_vm._v(" No datasets. ")]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }