<template>
  <div>
    <v-alert type="error" v-if="error">{{ error }}</v-alert>
    <v-data-table
      :headers="batchHeaders"
      :items="batchItems"
      :items-per-page="10"
      sort-by="createdAtRaw"
      :sort-desc="true"
      :loading="loading"
      class="elevation-1"
      dense
      v-if="!error"
    >
      <template v-slot:top>
        <v-toolbar flat>
          Batches in dataset
        </v-toolbar>
      </template>
      <template v-slot:[`item.tasks`]="{ item }">
        <open-dialog-button
          title="Assign"
          class="py-0 my-2"
          small
          color="accent"
          v-slot="{ closeDialog }"
          v-if="!!projectsOfDataset && !!members"
          :maxWidth="800"
        >
          <create-task-card
            :batch="item"
            :projects="projectsOfDataset"
            :closeDialog="closeDialog"
            v-on="$listeners"
          />
        </open-dialog-button>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu bottom left :close-on-content-click="false" offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <open-dialog-button text title="Delete" v-slot="{ closeDialog }">
                <delete-batch-card
                  :batch="item"
                  v-on="$listeners"
                  :closeDialog="closeDialog"
                />
              </open-dialog-button>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <date-item :date="new Date(item.created_at)" />
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <id-item :id="item.id" />
      </template>
      <template v-if="!hasBatches" v-slot:no-data>
        No batches.
      </template>
    </v-data-table>
  </div>
</template>
<script>
import CreateTaskCard from '@/components/CreateTaskCard.vue';
import DateItem from '@/components/molecules/DateItem.vue';
import IdItem from '@/components/molecules/IdItem.vue';
import DeleteBatchCard from '@/components/DeleteBatchCard.vue';
import OpenDialogButton from '@/components/molecules/OpenDialogButton.vue';

export default {
  components: {
    CreateTaskCard,
    DateItem,
    IdItem,
    OpenDialogButton,
    DeleteBatchCard
  },
  created() {
    this.fetchData();
  },
  data: () => ({
    batches: null,
    projectsOfDataset: null,
    members: null,
    loading: true,
    error: null
  }),
  props: {
    organizationId: {
      type: String,
      required: true
    },
    datasetId: {
      type: String,
      required: true
    },
    onCreateTask: {
      type: Function,
      required: true
    }
  },
  computed: {
    batchHeaders() {
      const commonHeaders = [
        {
          text: 'File name',
          value: 'filename'
        },
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id'
        },
        {
          text: 'Uploaded by',
          value: 'createdBy'
        },
        {
          text: 'Uploaded at',
          value: 'createdAt'
        },
        {
          text: 'Assign task',
          value: 'tasks',
          align: 'center',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ];

      return this.hasAssetCounts
        ? [
            ...commonHeaders,
            {
              text: 'Assets',
              value: 'assets'
            }
          ]
        : commonHeaders;
    },
    batchItems() {
      if (!this.batches) {
        return [];
      }
      return this.batches.map(batch => ({
        ...batch,
        id: batch.id,
        filename: batch.filename,
        assets: (batch.counts && batch.counts.assets) || 0,
        createdBy: batch.created_by ? batch.created_by.name : undefined,
        createdAt: new Date(Date.parse(batch.created_at)).toLocaleDateString(),
        createdAtRaw: batch.created_at
      }));
    },
    hasBatches() {
      return !!this.batches && this.batches.length > 0;
    },
    hasAssetCounts() {
      return (
        this.hasBatches &&
        this.batches.every(batch => batch.counts && batch.counts.assets >= 0)
      );
    }
  },
  methods: {
    async fetchData() {
      this.batches = null;
      this.projectsOfDataset = null;
      this.members = null;

      this.loading = true;
      this.error = null;

      try {
        this.batches = await this.$api.datasets.listBatches({
          datasetId: this.datasetId
        });
        const projects = await this.$api.organizations.getOrganizationProjects(
          this.organizationId
        );
        const projectsWithDatasets = await Promise.all(
          projects.map(async project => {
            const datasets = await this.$api.projects.getProjectDatasets(
              project.id
            );
            return { ...project, datasets };
          })
        );
        this.projectsOfDataset = projectsWithDatasets.filter(project =>
          project.datasets.some(dataset => dataset.id === this.datasetId)
        );
        this.members = await this.$api.organizations.getOrgMembers(
          this.organizationId
        );
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    datasetId() {
      this.fetchData();
    }
  }
};
</script>
<style scoped></style>
