





































































import { computed, defineComponent, toRefs } from '@vue/composition-api';
import { Location } from 'vue-router';
import IdItem from '@/components/molecules/IdItem.vue';
import ReleaseExportsCard from '@/components/project/ReleaseExportsCard.vue';
import { useRelease } from '@/api/use';
import { dateToLongFormat } from '@/utils';

export default defineComponent({
  components: {
    IdItem,
    ReleaseExportsCard
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    releaseId: {
      type: String,
      required: true
    },
    orgId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { releaseId, projectId, orgId } = toRefs(props);
    const { error, loading, release } = useRelease(releaseId);

    const routeBack = computed(
      () =>
        ({
          name: 'ProjectReleases',
          params: {
            projectId: projectId.value,
            orgId: orgId.value
          }
        } as Location)
    );

    const keyValuePairsLeft = computed(() => {
      const releaseValue = release.value;
      if (!releaseValue) {
        return [];
      }
      return [
        {
          key: 'ID',
          value: releaseValue.id
        },
        {
          key: 'Name',
          value: releaseValue.name
        },
        {
          key: 'Description',
          value: releaseValue.description
        },
        {
          key: 'Project ID',
          value: releaseValue.project.id
        },
        {
          key: 'Created by',
          value: releaseValue.created_by?.name
        }
      ];
    });

    const keyValuePairsRight = computed(() => {
      const releaseValue = release.value;
      if (!releaseValue) {
        return [];
      }

      return [
        {
          key: 'Annotations',
          value: releaseValue.counts.annotations
        },
        {
          key: 'Tasks',
          value: releaseValue.tasks.length
        },
        {
          key: 'Review tasks',
          value: releaseValue.review_tasks.length
        },
        {
          key: 'Created at',
          value: dateToLongFormat(new Date(releaseValue.created_at))
        },
        {
          key: 'Processing finished at',
          value: releaseValue.finished_at
            ? dateToLongFormat(new Date(releaseValue.finished_at))
            : ''
        }
      ];
    });
    return {
      release,
      error,
      loading,
      keyValuePairsLeft,
      keyValuePairsRight,
      routeBack
    };
  }
});
