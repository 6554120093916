import { render, staticRenderFns } from "./ProjectData.vue?vue&type=template&id=04eb3d40&scoped=true&"
import script from "./ProjectData.vue?vue&type=script&lang=ts&"
export * from "./ProjectData.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectData.vue?vue&type=style&index=0&id=04eb3d40&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04eb3d40",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VAlert,VCard,VContainer,VDivider})
