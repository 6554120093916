import * as z from 'zod';

export type SaveAnnotations = (
  annotations: SignalAnnotationLabel[]
) => Promise<void>;

export const SignalAnnotatorParameterSchema = z.object({
  availableLabels: z.array(z.object({ name: z.string(), color: z.string() }))
});

export type SignalAnnotatorParametersFromSchema = z.infer<
  typeof SignalAnnotatorParameterSchema
>;

export const SignalAnnotationLabelSchema = z.object({
  from: z.number(),
  to: z.number(),
  label: z.string()
});

export type SignalAnnotationLabel = z.infer<typeof SignalAnnotationLabelSchema>;

export const DEFAULT_CONFIG: SignalAnnotatorParametersFromSchema = {
  // const colors = ['#ff4000', '#0000ff', '#00ff00', '#ffff00', '#ff0000'];
  // ['Normal', 'AFib', 'Other arrythmia', 'Noise', 'Unknown']
  availableLabels: [
    {
      name: 'AFib',
      color: '#ff4000'
    },
    {
      name: 'Other arrythmia',
      color: '#0000ff'
    },
    { name: 'Noise', color: '#ffff00' }
  ]
};

export const resolveParameters = (
  params: any
): SignalAnnotatorParametersFromSchema => {
  return { ...DEFAULT_CONFIG, ...params };
};
