



































import { PropType } from 'vue';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { Organization } from '@/types';
import _find from 'lodash/find';

export default defineComponent({
  name: 'ChangeOrganization',
  props: {
    organizations: {
      type: Array as PropType<Organization[]>,
      required: true
    },
    selectedOrganization: {
      type: Object as PropType<Organization>,
      required: false
    }
  },
  setup(props, { emit }) {
    const orgId = ref<string | null>(null);

    function updateSelectedOrganization(): void {
      const matchOrUndefined = _find(
        props.organizations,
        org => org.id === orgId.value
      );
      if (matchOrUndefined !== undefined) {
        emit('update:selectedOrganization', matchOrUndefined);
      } else {
        console.warn(
          'Picked non-existing organization, ignoring.',
          orgId.value
        );
      }
    }
    function setSelectedOrganizationId(organizationId: string): void {
      orgId.value = organizationId;
    }

    const selectedOrganizationIdWithDefault = computed(() => {
      if (props.organizations.length === 0) {
        return undefined;
      }
      const selected = props.selectedOrganization;
      if (!selected) {
        return props.organizations[0].id;
      }
      const matchOrUndefined = _find(
        props.organizations,
        org => org.id === selected.id
      );
      if (matchOrUndefined === undefined) {
        console.warn(
          'Picked non-existing organization, ignoring.',
          orgId.value
        );
      }
      return matchOrUndefined;
    });

    return {
      orgId,
      updateSelectedOrganization,
      setSelectedOrganizationId,
      selectedOrganizationIdWithDefault
    };
  }
});
