import apiClient, { JobFailedException, JobTimeoutException } from './client';
import { Job } from '@/types';

const route = 'jobs';

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const WAIT_JOB_INTERVAL_MS = 1000;
const MAX_WAIT_JOB_RETRIES = 5;

export default {
  getJob({ jobId }): Promise<Job> {
    return apiClient.get(`/${route}/${jobId}`);
  },
  async waitForJob({ jobId, maxRetries = MAX_WAIT_JOB_RETRIES }): Promise<Job> {
    const finished = false;

    let retriesDone = 0;
    console.log(`Waiting for job to finish ${jobId}:...`);
    while (!finished && retriesDone < maxRetries) {
      const job = await this.getJob({ jobId });
      console.log(`Got job`, job);
      if (job.state === 'FINISHED') {
        return job;
      } else if (job.state === 'FAILED') {
        const maybeReason = job.errors?.join(', ') || 'Unknown';
        throw new JobFailedException(
          `Job ${jobId} failed, reason: ${maybeReason}`
        );
      }
      retriesDone += 1;
      await sleep(WAIT_JOB_INTERVAL_MS);
    }
    throw new JobTimeoutException(`Job ${jobId} did not finish in time`);
  },
  async waitForBatchUploadJob({ jobId }) {
    const job = await this.waitForJob({ jobId });
    return job.result;
  }
};
