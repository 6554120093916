




















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    value: Boolean
  },
  methods: {
    emitInput(val: boolean): void {
      this.$emit('input', val);
    }
  }
});
