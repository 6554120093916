<template>
  <v-tooltip top :disabled="disabled" left nudge-bottom="75">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot />
      </div>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'NavTooltip',
  props: {
    disabled: Boolean
  },
  components: {},
  data: () => ({
    tooltipText: 'Create or join an organization first.'
  }),
  computed: {},
  methods: {},
  created() {}
};
</script>
<style scoped></style>
