import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import light from './light';
import dark from './dark';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light,
      dark
    },
    dark: false
  }
});
