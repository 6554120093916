



















































import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRef,
  toRefs,
  watch
} from '@vue/composition-api';
import { AnnotationStageDefProps } from './pipeline-types';
import { PropType } from 'vue';
import LoadingModal from '@/components/LoadingModal.vue';
import {
  useFirstAssetInBatch,
  useOrganizationMembers,
  useUiConfigurations,
  useUiGuidelines
} from '@/api/use';
import AnnotationUis from '@/components/annotations/uis';
import { firstTruthy, or } from '@/utils';

export default defineComponent({
  name: 'CreateAnnotationStage',
  components: {
    LoadingModal
  },
  props: {
    value: {
      type: Object as PropType<AnnotationStageDefProps>,
      required: false
    },
    batchId: {
      type: String,
      required: true
    },
    organizationId: {
      type: String,
      required: true
    },
    projectId: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const { batchId, organizationId, projectId } = toRefs(props);
    // eslint-disable-next-line no-undef
    const stageDefProps = reactive({
      annotators: [],
      uiName: null,
      configurationVersion: null,
      guidelineVersion: null
    } as AnnotationStageDefProps);

    const inputValid = ref(false);
    const {
      members,
      loading: loadingMembers,
      error: errorLoadingMembers
    } = useOrganizationMembers(organizationId);
    const {
      asset,
      loading: loadingFirstAsset,
      error: errorLoadingFirstAsset
    } = useFirstAssetInBatch({
      batchId
    });

    const availableUis = computed(() => {
      if (!asset.value) {
        return [];
      }
      return AnnotationUis.filter(ui => ui.canLabelAsset(asset.value));
    });

    function annotatorsValid(value) {
      return value.length > 0;
    }

    function uiNameValid(value) {
      return !!value;
    }

    function propsToServerInput(defProps: AnnotationStageDefProps) {
      const base = {
        annotators: defProps.annotators,
        ui_name: defProps.uiName
      };

      // The equal sign with two "==" is used on purpose to catch both null and undefined
      const maybeConfiguration =
        defProps.configurationVersion == null
          ? {}
          : { configuration_version: defProps.configurationVersion };

      const maybeGuideline =
        defProps.guidelineVersion == null
          ? {}
          : { guideline_version: defProps.guidelineVersion };

      return { ...base, ...maybeConfiguration, ...maybeGuideline };
    }

    const {
      configurations,
      loading: loadingConfigurations,
      error: errorLoadingConfigurations
    } = useUiConfigurations({
      projectId,
      uiName: toRef(stageDefProps, 'uiName')
    });

    const configurationChoices = computed(() => {
      return [
        { name: 'Always latest', version: null },
        ...configurations.value.map(configuration => ({
          name: `Version ${configuration.version}`,
          version: configuration.version
        }))
      ];
    });

    const {
      guidelines,
      loading: loadingGuidelines,
      error: errorLoadingGuidelines
    } = useUiGuidelines({
      projectId,
      uiName: toRef(stageDefProps, 'uiName')
    });

    const guidelineChoices = computed(() => {
      return [
        { name: 'Always latest', version: null },
        ...guidelines.value.map(guideline => ({
          name: `Version ${guideline.version}`,
          version: guideline.version
        }))
      ];
    });

    // Reset configuration and guideline when the ui name changes
    watch(
      () => stageDefProps.uiName,
      function() {
        stageDefProps.configurationVersion = null;
        stageDefProps.guidelineVersion = null;
      }
    );

    function emitInput() {
      if (inputValid.value) {
        emit('input', propsToServerInput(stageDefProps));
      } else {
        emit('input', null);
      }
    }

    watch([stageDefProps, inputValid], emitInput, {
      deep: true,
      immediate: true
    });

    const loading = or(
      loadingMembers,
      loadingFirstAsset,
      loadingConfigurations,
      loadingGuidelines
    );

    const error = firstTruthy(
      errorLoadingMembers,
      errorLoadingFirstAsset,
      errorLoadingConfigurations,
      errorLoadingGuidelines
    );

    return {
      stageDefProps,
      members,
      loading,
      error,
      annotatorsValid,
      uiNameValid,
      inputValid,
      annotationUis: availableUis,
      asset,
      guidelineChoices,
      configurationChoices
    };
  }
});
