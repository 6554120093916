import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { AnnotatorApiPlugin } from './api';
import LoadScript from 'vue-plugin-load-script';
import VueResizeObserver from 'vue-resize-observer';
import VueCompositionAPI from '@vue/composition-api';

Vue.use(VueCompositionAPI);
Vue.config.productionTip = false;

// Include $api to all Vue instances
// https://vuejs.org/v2/cookbook/adding-instance-properties.html
Vue.use(AnnotatorApiPlugin);
Vue.use(VueResizeObserver);
Vue.use(LoadScript);

Vue.filter('toDate', function(createdAt) {
  return new Date(Date.parse(createdAt)).toLocaleString();
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
