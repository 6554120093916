import colors from 'vuetify/lib/util/colors';

/* Silo Brand colors:
primary: #1b1b38
white: #ffffff
red accent: #f08686
blue accent: #4a5ea8
text color: #181730

/* Available fields:
primary: '#1976D2',
secondary: '#424242',
accent: '#82B1FF',
error: '#FF5252',
info: '#2196F3',
success: '#4CAF50',
warning: '#FFC107'
*/

export default {
  primary: '#2c6dcb',
  secondary: '#4a5ea8',
  background: '#FCFCFC',
  columbia: '#CDE6F5',
  magnolia: 'F6F2FF',
  lavender: 'DCCCFF',
  accent: '#2763D6',
  info: '#2763D6',
  success: '#4CAF50',
  error: '#f08686',
  warning: '#FFC107',
  gray: '#eeeeee',

  // All keys will generate theme styles,
  // Here we add a custom `tertiary` color
  tertiary: colors.pink.base,
  'silo-blue': '#2763D6',
  'silo-dark': '#1B1B38',
  'silo-light': '#DBE5F5',
  'line-gray': '#767676'
};
