

















import { computed, defineComponent } from '@vue/composition-api';
import _concat from 'lodash/concat';
import LoadingModal from '@/components/LoadingModal.vue';
import MyTasks from '@/components/MyTasks.vue';
import {
  useSelectedOrganization,
  useCurrentUser,
  useUserOrganizations
} from '@/store/use';
import {
  useCurrentUserOrganizations,
  useCurrentUserTasks,
  useCurrentUserReviewTasks
} from '@/api/use';

export default defineComponent({
  name: 'Home',
  components: {
    LoadingModal,
    MyTasks
  },
  props: {
    view: {
      required: false,
      type: String // admin or annotator
    }
  },
  setup(props, { root: { $store: store } }) {
    const { user } = useCurrentUser(store);

    const {
      loading: loadingOrganizations,
      organizations,
      error: errorLoadingOrganizations
    } = useCurrentUserOrganizations(user);

    const {
      loading: loadingTasks,
      tasks,
      error: errorLoadingTasks,
      mutate: mutateTasks
    } = useCurrentUserTasks(user);

    const {
      loading: loadingReviewTasks,
      review_tasks,
      error: errorLoadingReviewTasks,
      mutate: mutateReviewTasks
    } = useCurrentUserReviewTasks(user);

    const { userOrganizations } = useUserOrganizations(store);
    const hasOrganizations = computed(() => userOrganizations.value.length > 0);

    const { selectedOrganization } = useSelectedOrganization(store);

    const selectedOrganizationId = computed(
      () => selectedOrganization.value?.id
    );

    const loading = computed(
      () =>
        loadingOrganizations.value ||
        loadingTasks.value ||
        loadingReviewTasks.value
    );
    const error = computed(
      () =>
        errorLoadingOrganizations.value ||
        errorLoadingTasks.value ||
        errorLoadingReviewTasks.value
    );

    const myTasksAndKind = computed(() =>
      tasks.value
        .filter(task => task.organization.id === selectedOrganization.value?.id)
        .map(task => ({ task: task as any, kind: 'AnnotationTask' }))
    );

    const myReviewTasksAndKind = computed(() =>
      review_tasks.value
        .filter(task => task.organization.id === selectedOrganization.value?.id)
        .map(task => ({ task: task as any, kind: 'ReviewTask' }))
    );

    const allTasksAndKind = computed(() => {
      return _concat(myTasksAndKind.value, myReviewTasksAndKind.value);
    });

    async function onRefresh() {
      mutateTasks();
      mutateReviewTasks();
    }

    return {
      organizations,
      myTasksAndKind,
      myReviewTasksAndKind,
      allTasksAndKind,
      mutateTasks,
      mutateReviewTasks,
      selectedOrganization,
      selectedOrganizationId,
      hasOrganizations,
      currentUser: user,
      onRefresh,
      loading,
      error
    };
  }
});
