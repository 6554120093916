import { AnnotationUI } from '../annotations/ui';
import {
  DEFAULT_CONFIG,
  resolveParameters,
  TextAnnotatorParameterSchema,
  findTextMediaSlug
} from './utils';
import { Asset } from '@/types';

const controller = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "text-classification" */ './TextClassificationController.vue'
  );

const canLabelAsset = (asset: Asset): boolean => {
  // More proper check would be to check if the configured "textAttribute"
  // is present. However, the configuration is not available here.
  const hasMedia = !!findTextMediaSlug(asset.media);

  return (
    (hasMedia && Object.keys(asset.media).length == 1) ||
    (!hasMedia && Object.keys(asset.attributes).length > 0)
  );
};

const UI: AnnotationUI = {
  component: controller,
  name: 'TextClassification',
  canLabelAsset,
  defaultConfig: DEFAULT_CONFIG,
  resolveParameters,
  configSchema: TextAnnotatorParameterSchema,
  supportsReview: false
};

export default UI;
