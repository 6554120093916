var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":5,"show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Annotation configuration versions")]),_c('v-spacer'),_c('open-dialog-button',{attrs:{"title":"Create configuration"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var closeDialog = ref.closeDialog;
return [_c('create-annotation-ui-config-view',_vm._g({attrs:{"project":_vm.project,"ui":_vm.ui,"latest":_vm.latestVersion,"closeDialog":closeDialog}},_vm.$listeners))]}}])})],1),_c('div',{staticClass:"text-caption px-4"},[_vm._v(" Annotation configurations are used to set annotation UI parameters, overriding default parameters. The list of supported parameters is specific to each annotation UI. Example parameters are, for example, the list of available classes. ")])]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('date-item',{attrs:{"date":new Date(item.created_at)}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('v-expand-transition',[_c('td',{staticClass:"pa-1",attrs:{"colspan":headers.length}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-textarea',{attrs:{"value":JSON.stringify(item.parameters, null, 2),"readonly":""}})],1)],1)])]}},(_vm.versions.length === 0)?{key:"no-data",fn:function(){return [_vm._v(" No stored versions. ")]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }