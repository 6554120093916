









































import Vue, { PropType } from 'vue';
import { Dataset, Project } from '@/types';

export default Vue.extend({
  props: {
    datasets: Array as PropType<Dataset[]>,
    project: Object as PropType<Project>
  },
  data: function() {
    return {
      showDialog: false,
      chosenDatasetId: null,
      valid: false,
      rules: [dataset => !!dataset || 'Choose dataset']
    };
  },
  computed: {
    items(): { text: string; value: string }[] {
      return this.datasets.map(dataset => ({
        text: dataset.name,
        value: dataset.id
      }));
    },
    formIsValid(): boolean {
      return !!this.chosenDatasetId;
    }
  },
  methods: {
    async submit(): Promise<void> {
      const chosenDatasetId = this.chosenDatasetId;
      console.log('Linking to dataset', chosenDatasetId);
      await this.$api.projects.linkDataset({
        datasetId: chosenDatasetId,
        projectId: this.project.id
      });
      this.$emit('refresh');
      this.onClose();
    },
    onClose() {
      (this.$refs.form as HTMLFormElement).reset();
      this.showDialog = false;
    }
  }
});
