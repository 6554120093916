























import Vue, { PropType } from 'vue';
import { ApiException, BadRequest } from '@/api/client';

import { DatasetItem } from '@/types';

export default Vue.extend({
  props: {
    dataset: {
      required: true,
      type: Object as PropType<DatasetItem>
    },
    closeDialog: {
      required: true,
      type: Function as PropType<() => void>
    }
  },
  data: function() {
    return {
      deleting: false,
      deleteError: null as string | null
    };
  },
  computed: {
    errorMessages(): string[] {
      return (this.deleteError && [this.deleteError]) || [];
    },
    batchCount(): number {
      return this.dataset.nBatches;
    }
  },
  methods: {
    async onSubmit(): Promise<void> {
      console.log(`Deleting dataset ${this.dataset.id}`);
      this.deleteError = null;
      this.deleting = true;
      try {
        await this.$api.datasets.deleteDataset({ datasetId: this.dataset.id });
        this.$emit('refresh');
        this.onClose();
      } catch (err) {
        if (err instanceof BadRequest) {
          this.deleteError = err.errors.map(err => err.title).join(',');
        } else if (err instanceof ApiException) {
          this.deleteError = err.message;
        } else {
          console.error('Failed deleting', err);
          this.deleteError = err;
          throw err;
        }
      } finally {
        this.deleting = false;
      }
    },
    onClose(): void {
      this.closeDialog();
      this.deleteError = null;
    }
  }
});
