




















































































































































import { PropType } from 'vue';
import NavTooltip from '@/components/molecules/NavItemWithTooltip.vue';
import { Organization, CompactUser } from '@/types';
import JobsNotifications from '@/components/nav/JobsNotifications.vue';
import RequirePermission from '@/components/RequirePermission.vue';
import { computed, defineComponent } from '@vue/composition-api';
import { usePlatformPermissions } from '@/api/use';
import { useCurrentUser } from '@/store/use';

export default defineComponent({
  name: 'Nav',
  props: {
    currentUser: {
      type: Object as PropType<CompactUser>,
      required: false
    },
    organizations: {
      type: Array as PropType<Organization[]>,
      required: true
    },
    selectedOrganization: {
      type: Object as PropType<Organization>,
      required: false
    },
    onLogout: Function
  },
  components: {
    NavTooltip,
    JobsNotifications,
    RequirePermission
  },
  data: () => ({
    menuItems: [
      {
        name: 'settings',
        text: 'Settings',
        route: '/settings/account'
      },
      {
        name: 'create_organization',
        text: 'Create organization',
        route: { name: 'CreateOrganization' }
      },
      {
        name: 'logout',
        text: 'Sign out',
        route: '/logout'
      }
    ],
    notifications: 0,
    showCreateOrganizationDialog: false
  }),
  computed: {
    selectedOrganizationId(): string {
      return this.selectedOrganization ? this.selectedOrganization.id : 'n/a';
    },
    noOrganizations(): boolean {
      return this.organizations.length === 0;
    },
    selectedOrganizationName(): string | undefined {
      if (this.organizations.length === 0) {
        return undefined;
      }
      const selected = this.selectedOrganization;
      if (!selected) {
        return this.organizations[0].name;
      }
      const match = this.organizations.filter(org => org.id === selected.id);
      if (match.length === 1) {
        return match[0].name;
      }
      return this.organizations[0].name;
    }
  },
  methods: {
    async logout() {
      await this.onLogout();
    }
  },
  setup(_, { root: { $store: store } }) {
    const { user } = useCurrentUser(store);
    const {
      error: errorPlatformPermissions,
      platformPermissions
    } = usePlatformPermissions(user);

    const error = computed(() => errorPlatformPermissions.value);
    const canCreateOrganization = computed(
      () => platformPermissions.value?.can_create_organizations
    );

    return { error, canCreateOrganization };
  }
});
