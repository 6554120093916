









/**
 * Customized wrapper around Vuetify's v-tooltip, adding
 * our own styles
 */
import { defineComponent } from '@vue/composition-api';

export default defineComponent({});
