export interface Label {
  [key: string]: any;  // eslint-disable-line
}

export interface LabelGroup {
  [key: string]: any;  // eslint-disable-line
}

export interface IdObject {
  id: string;
}

export interface CompactUser {
  id: string;
  email: string;
  name: string;
}

export interface OrganizationMember extends CompactUser {
  is_admin: boolean;
}

export interface GoogleSignInSettings {
  enabled: boolean;
  clientId: string;
  loginUri: string;
}

export interface ExtendedUser extends CompactUser {
  name: string;
}

export interface Organization {
  id: string;
  name: string;
}

export interface UserPreferences {
  organization?: IdObject;
}

export interface PlatformPermissions {
  can_create_organizations: boolean;
}

export interface PersonalAccessToken {
  id: string;
  name: string;
  created_at: string;
}

export interface CreatedPersonalAccessToken {
  token: string;
}

export interface AnnotationTaskCounts {
  assets: number;
  annotations: number;
}

export interface AnnotationTask {
  id: string;
  project: CompactProject;
  organization: CompactOrganization;
  dataset: CompactDataset;
  batch: CompactBatch;
  priority: number;
  annotator: CompactUser;
  counts: AnnotationTaskCounts;
  created_at: string;
  created_by?: CompactUser;
  submitted_at?: string;
  configuration?: AnnotationConfiguration;
  guideline?: AnnotationGuideline;
  ui?: AnnotationUI;
  prediction_task?: IdObject;
}

export interface PredictionTask {
  id: string;
  // Include these for type-checking
  submitted_at?: string;
  counts?: AnnotationTaskCounts;
  batch?: IdObject;
  configuration?: AnnotationConfiguration;
  guideline?: AnnotationGuideline;
  ui?: AnnotationUI;
  project?: CompactProject;
  dataset?: CompactDataset;
  created_by?: CompactUser;
  created_at?: string;
}

export type ReviewTaskCounts = AnnotationTaskCounts;

export interface CompactDataset {
  id: string;
  name: string;
}

export interface CompactBatch {
  id: string;
  filename?: string;
}

export interface CompactProject {
  id: string;
  name: string;
}

export interface CompactOrganization {
  id: string;
  name: string;
}

export interface ReviewTask {
  id: string;
  project: CompactProject;
  organization: CompactOrganization;
  dataset: CompactDataset;
  batch: CompactBatch;
  assignee: CompactUser;
  counts: ReviewTaskCounts;
  created_at: string;
  created_by?: CompactUser;
  tasks: IdObject[];
  submitted_at?: string;
  configuration?: AnnotationConfiguration;
  guideline?: AnnotationGuideline;
  ui?: AnnotationUI;
}

export interface AnnotationProgressStatus {
  annotations: number;
  assets: number;
  progressInPercents: number;
}

export interface ExpandedAnnotationTask extends AnnotationTask {
  dataset: Dataset;
  batch: Batch;
  status: string;
}

export interface DurationMetricItem {
  created_at: string;
  total: number;
  average: number;
  median: number;
  label_count: number;
}

export interface DurationMetric {
  created_at: string;
  total: number;
  average: number;
  median: number;
  items: DurationMetricItem[];
}

export interface AnnotationTaskMetrics {
  id: string;
  created_at: string;
  asset_count: number;
  annotation_count: number;
  label_count: number;
  durations: DurationMetric;
  annotator: CompactUser;
}

export interface ProjectStatisticsCounts {
  datasets: number;
  batches: number;
  assets: number;
  tasks: number;
  batches_with_tasks: number;
  annotations: number;
  annotated_assets: number;
  annotations_assigned: number;
  annotations_assigned_done: number;
}

export interface ProjectStatistics {
  counts: ProjectStatisticsCounts;
}

export interface AnnotationProjectMetrics {
  id: string;
  created_at: string;
  asset_count: number;
  annotation_count: number;
  label_count: number;
  durations: DurationMetric;
}

export interface AnnotationUI {
  name: string;
  parameters?: AnnotationParameters;
}

export interface AssetMedia {
  url: string;
  media_type: string;
  filename: string;
  thumbnail_url?: string;
}

export interface AssetMediaEmbedding {
  id: string;
  model: string;
  url: string;
  filename: string;
  format: string;
}

export interface BatchCounts {
  assets: number;
}

export interface Batch {
  id: string;
  dataset: IdObject;
  created_at: string;
  created_by: CompactUser;
  filename?: string;
  counts?: BatchCounts;
}

export interface BatchWithTasksAndPipelines extends Batch {
  tasks: AnnotationTask[];
  pipelines: AnnotationPipeline[];
}

export interface BatchItem {
  id: string;
  filename?: string;
  assets: number;
  createdBy?: string;
  createdAt: string;
  nTasks: number;
}

export interface Asset {
  id: string;
  external_id?: string;
  attributes: Record<string, any>;
  metadata: Record<string, any>;
  media: Record<string, AssetMedia>;
  batch: IdObject;
}

export interface AssetInTask extends Asset {
  // Asset in task context, includes information about annotation status
  annotated_at?: string;
}

export interface LabelGroupMember {
  _id: string;
}

export interface LabelGroup {
  name: string;
  members: LabelGroupMember[];
}

export interface AnnotatedAsset {
  id: string;
  asset: {
    id: string;
    external_id?: string;
    created_at: string;
  };
  labels: Label[];
  groups?: LabelGroup[];
}

export enum TaskStatus {
  Pending = 'Pending',
  Ready = 'Ready for submitting',
  Complete = 'Complete'
}

export type TaskStatusStrings = keyof typeof TaskStatus;

export enum JobState {
  CREATED = 'CREATED',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED'
}

export enum JobKind {
  HANDLE_UPLOAD = 'handle_upload',
  CREATE_RELEASE = 'CREATE_RELEASE',
  CREATE_RELEASE_EXPORT = 'CREATE_RELEASE_EXPORT'
}

export interface Job {
  id: string;
  organization: IdObject;
  kind: JobKind;
  state: JobState;
  created_at?: string;
  updated_at?: string;
  metadata?: any;
  result?: any;
  errors?: string[];
}

export interface AnnotationGuidelineFile {
  url: string;
  media_type: string;
  filename: string;
}

export interface AnnotationGuideline extends IdObject {
  ui: AnnotationUI;
  text?: string;
  files: AnnotationGuidelineFile[];
  created_by: CompactUser;
  created_at: string;
  version: number;
}

export interface CompactAnnotationGuideline {
  ui: AnnotationUI;
  text?: string;
  files: AnnotationGuidelineFile[];
  version: number;
}

export interface AnnotationGuidelineVersion {
  version: number;
}

export type AnnotationParameters = Record<string, any>;

export interface AnnotationConfiguration extends IdObject {
  parameters: AnnotationParameters;
  ui: AnnotationUI;
  project: IdObject;
  version: number;
  guideline?: AnnotationGuidelineVersion;
  created_by: CompactUser;
  created_at: string;
}

export interface CompactAnnotationConfiguration {
  parameters: AnnotationParameters;
  ui: AnnotationUI;
  version: number;
}

export interface Project {
  id: string;
  name: string;
  organization: IdObject;
  created_at: string;
  created_by?: CompactUser;
  dataset_count: number;
}

export interface ProjectItem {
  id: string;
  name: string;
  createdBy?: string;
  createdAt: string;
  mDatasets: number;
}

export interface AnnotationReleaseCounts {
  annotations: number;
}

export interface AnnotationRelease {
  id: string;
  name: string;
  description: string;
  created_by: CompactUser;
  created_at: string;
  project: IdObject;
  counts: AnnotationReleaseCounts;
  tasks: IdObject[];
  review_tasks: IdObject[];
  finished_at?: string;
  job?: Job;
}

export interface AnnotationReleaseExportZip {
  filename: string;
  url: string;
}

export interface AnnotationReleaseExport {
  id: string;
  job: Job;
  release: IdObject;
  version: number;
  finished_at?: string;
  zip?: AnnotationReleaseExportZip;
  storage_uri?: string;
}

export interface Dataset {
  id: string;
  name: string;
  organization: IdObject;
  batches: Batch[];
  created_by: CompactUser;
  created_at: string;
}

export interface DatasetItem {
  id: string;
  name: string;
  created_by: CompactUser;
  created_at: string;
  nBatches: number;
}

export interface AnnotationStageProperties {
  annotators: CompactUser[];
}

export interface AnnotationStage {
  kind: 'Annotation';
  properties: AnnotationStageProperties;
}

export interface ReviewStageProperties {
  reviewer: CompactUser;
}

export interface ReviewStage {
  kind: 'Review';
  properties: ReviewStageProperties;
}

export interface PreAnnotationStageProperties {} // eslint-disable-line

export interface PreAnnotationStage {
  kind: 'Pre-annotation';
  properties: PreAnnotationStageProperties;
}

export type AnnotationPipelineStage =
  | AnnotationStage
  | ReviewStage
  | PreAnnotationStage;

export interface AnnotationPipeline {
  id: string;
  batch: IdObject;
  project: IdObject;
  dataset: IdObject;
  created_by: CompactUser;
  created_at: string;
  active_stage: number;
  state: string;
  definition: {
    stages: AnnotationPipelineStage[];
  };
}

export interface PolicyRole {
  name: string; // E.g. "Admin"
}

export interface PolicyScope {
  id: string;
  kind: string;
  name: string;
}

export interface PolicyBinding {
  created_at: string;
  created_by: CompactUser;
  id: string;
  organization: IdObject;
  role: string;
  scope: PolicyScope;
  subject: string;
}

export type FetchMediaRequest = {
  assetId: string;
  mediaSlug: string;
  mediaType: string;
};

export type FetchMedia = (req: FetchMediaRequest) => Promise<ArrayBuffer>;

export type RouteUpdateListener = () => Promise<boolean>;
