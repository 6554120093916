



import { defineComponent, onMounted, ref } from '@vue/composition-api';
import useApi from '@/api/useApi';
import { loadScript } from '@/utils';

export default defineComponent({
  components: {},
  setup() {
    const loading = ref(false);
    const error = ref(null as Error | null);

    const api = useApi();

    async function initialize() {
      loading.value = true;
      error.value = null;
      try {
        const {
          enabled,
          clientId,
          loginUri
        } = await api.auth.getGoogleSignInSettings();

        if (!enabled) {
          console.debug('Google sign-in not enabled');
          return;
        }

        await loadScript('https://accounts.google.com/gsi/client');

        const google = (window as any).google;

        // https://developers.google.com/identity/gsi/web/reference/js-reference
        google.accounts.id.initialize({
          client_id: clientId,
          login_uri: loginUri,
          ux_mode: 'redirect'
        });
        google.accounts.id.renderButton(
          document.getElementById('signInButtonDiv'),
          { theme: 'outline', size: 'large' }
        );
      } catch (err) {
        console.error('Error initializing Google sign-in button', err);
        error.value = err;
      } finally {
        loading.value = false;
      }
    }
    onMounted(initialize);
  }
});
