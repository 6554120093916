




































































import Vue, { PropType } from 'vue';
import DeleteBatchCard from '@/components/DeleteBatchCard.vue';
import CreateTaskCard from '@/components/CreateTaskCard.vue';
import DateItem from '@/components/molecules/DateItem.vue';
import { Project, Dataset, BatchWithTasksAndPipelines } from '@/types';
import OpenDialogButton from '@/components/molecules/OpenDialogButton.vue';

export default Vue.extend({
  name: 'BatchesTable',
  components: {
    CreateTaskCard,
    DeleteBatchCard,
    DateItem,
    OpenDialogButton
  },
  data() {
    return {
      headers: [
        {
          text: 'File name',
          value: 'filename'
        },
        {
          text: 'Uploaded by',
          value: 'createdBy'
        },
        {
          text: 'Uploaded at',
          value: 'createdAt'
        },
        {
          text: 'Tasks',
          value: 'nTasks',
          align: 'center'
        },
        {
          text: 'Pipelines',
          value: 'nPipelines',
          align: 'center'
        },
        {
          text: 'Create task',
          value: 'tasks',
          align: 'center',
          sortable: false
        },
        {
          text: 'Actions',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    };
  },
  props: {
    batches: {
      required: true,
      type: Array as PropType<BatchWithTasksAndPipelines[]>
    },
    dataset: {
      required: true,
      type: Object as PropType<Dataset>
    },
    project: {
      required: true,
      type: Object as PropType<Project>
    }
  },
  created() {},
  methods: {
    onClickBatchPipelines(batchId: string): void {
      this.$router.push({
        name: 'ProjectPipelines',
        params: {
          ...this.$route.params
        },
        query: {
          batch: batchId
        }
      });
    }
  },
  computed: {
    items(): Record<string, any>[] {
      if (!this.batches) {
        return [];
      }
      return this.batches.map(batch => ({
        ...batch,
        id: batch.id,
        filename: batch.filename,
        nTasks: batch.tasks.length,
        nPipelines: batch.pipelines.length,
        createdBy: batch.created_by ? batch.created_by.name : undefined,
        createdAt: new Date(Date.parse(batch.created_at)).toLocaleDateString(),
        createdAtRaw: batch.created_at
      }));
    }
  }
});
