export function arrayBufferToBase64(buffer: ArrayBuffer): string {
  // https://stackoverflow.com/a/9458996/10561443
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function base64ToArrayBuffer(base64: string): ArrayBuffer {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

// https://stackoverflow.com/a/50288717/10561443
export const encodeGetParams = (p: Record<string, any>): string =>
  Object.entries(p)
    .map(kv => kv.map(encodeURIComponent).join('='))
    .join('&');
