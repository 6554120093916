


































































import {
  computed,
  defineComponent,
  toRefs,
  PropType
} from '@vue/composition-api';

import { TaskAndKind } from '@/layers';

export default defineComponent({
  props: {
    selectedTask: {
      required: false,
      type: Object as PropType<TaskAndKind>
    },
    tasks: {
      required: true,
      type: Array as PropType<TaskAndKind[]>
    },
    batchId: {
      required: true,
      type: String
    }
  },
  setup(props, { emit }) {
    const { selectedTask } = toRefs(props);
    const selectedTaskLocal = computed({
      get(): TaskAndKind | null {
        return selectedTask.value;
      },
      set(val: TaskAndKind | null) {
        emit('update:selectedTask', val);
      }
    });
    return { selectedTaskLocal };
  }
});
