







import { computed, defineComponent, toRefs } from '@vue/composition-api';
import { PropType } from 'vue';

const idToShort = (id: string): string => {
  return `${id.substring(0, 4)}...`;
};

export default defineComponent({
  name: 'IdLink',
  components: {},
  props: {
    id: {
      required: true,
      type: String
    },
    to: {
      required: true,
      type: Object as PropType<any>
    }
  },
  setup(props) {
    const { id } = toRefs(props);

    const short = computed(() => idToShort(id.value));

    return { short, long: id };
  }
});
