


















































import _find from 'lodash/find';
import _orderBy from 'lodash/orderBy';
import Vue, { PropType } from 'vue';

import ExportAnnotationsButton from '@/components/molecules/ExportAnnotationsButton.vue';
import OpenDialogButton from '@/components/molecules/OpenDialogButton.vue';
import CreateReviewTaskCard from '@/components/CreateReviewTaskCard.vue';
import DateItem from '@/components/molecules/DateItem.vue';

import { AnnotationTask, TaskStatus } from '@/types';
import { TaskAndKind } from '@/layers';

export default Vue.extend({
  components: {
    ExportAnnotationsButton,
    CreateReviewTaskCard,
    OpenDialogButton,
    DateItem
  },
  data: () => ({
    headers: [
      {
        text: 'Task type',
        value: 'uiName',
        align: 'center'
      },
      {
        text: 'Assigned by',
        value: 'createdBy'
      },
      {
        text: 'Submitted at',
        value: 'submitted_at'
      },
      {
        text: 'Status',
        value: 'status',
        align: 'center'
      },

      {
        text: 'Progress',
        value: 'progress',
        align: 'center'
      },
      {
        text: 'Priority',
        value: 'priority',
        align: 'center'
      },
      {
        text: 'Actions',
        value: 'annotate',
        align: 'center'
      }
    ]
  }),
  props: {
    tasksAndKind: {
      required: true,
      type: Array as PropType<Array<TaskAndKind>>
    }
  },
  computed: {
    taskItems(): Array<any> {
      const taskItems = this.tasksAndKind
        .map(({ task, kind }) => ({
          /* eslint-disable */
          ...task,
          task,
          kind: kind,
          id: task.id,
          // @ts-ignore
          annotator: kind === 'AnnotationTask' ? task.annotator?.name : null,
          assets: task.counts.assets,
          annotations: task.counts.annotations,
          // @ts-ignore
          priority: task.priority || 0,
          // @ts-ignore
          createdBy: task.created_by ? task.created_by.name : undefined,
          // @ts-ignore
          createdAt: task.created_at
            // @ts-ignore
            ? new Date(Date.parse(task.created_at)).toLocaleDateString()
            : null,
          status: task.submitted_at
            ? TaskStatus.Complete
            : task.counts.annotations === task.counts.assets
            ? TaskStatus.Ready
            : TaskStatus.Pending,
          progress: `${(
            (task.counts.annotations / task.counts.assets) *
            100.0
          ).toFixed(0)} %`,
          uiName:
            kind === 'AnnotationTask' ? task.ui?.name : 'Annotation review',
          /* eslint-enable */
        }))
        .filter(taskItem => taskItem.status === TaskStatus.Complete);
      return _orderBy(taskItems, ['priority', 'created_at'], ['desc', 'desc']);
    },
    noTasks(): boolean {
      return this.taskItems.length === 0;
    }
  },
  methods: {
    isAnnotationTask(item: any) {
      return item.kind === 'AnnotationTask';
    },
    taskForId(taskId: string): AnnotationTask {
      return _find(this.taskItems, task => task.id === taskId);
    },
    toGeneric(item: any): TaskAndKind {
      return _find(this.tasksAndKind, ({ task }) => task.id === item.id);
    }
  }
});
