

















































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { BadRequest, Unauthorized } from '@/api/client';
import SignInWithGoogle from '@/components/molecules/SignInWithGoogle.vue';
import { defineComponent } from '@vue/composition-api';

const stampWhite = require('../assets/Silo-stamp-white.png');  // eslint-disable-line

export default defineComponent({
  components: { SignInWithGoogle },
  data: () => ({
    loginForm: {
      email: '',
      password: ''
    },
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    pwRules: [v => !!v || 'Password is required'],
    nameRules: [v => !!v || 'Name is required'],
    emailErrors: [],
    pwErrors: [],
    isLoggingIn: true,
    loginFormValid: false,
    errorLoggingIn: null,
    registerForm: {
      firstName: '',
      lastName: '',
      email: '',
      password: ''
    },
    registerFormValid: false,
    errorRegistering: null,
    registerSuccess: false
  }),
  computed: {
    ...mapGetters('auth', ['currentUser']),
    imgSrc(): string {
      return stampWhite;
    }
  },
  mounted() {
    if ((this as any).currentUser) {
      console.log('Redirecting to home...');
      setTimeout(() => {
        this.$router.push({ path: '/' });
      }, 1000);
    }
  },
  methods: {
    async loginUser({ email, password }: { email: string; password: string }) {
      const loginPayload = {
        email,
        password
      };
      try {
        this.errorLoggingIn = null;
        await this.$store.dispatch('auth/login', loginPayload);
        await this.$store.dispatch('organizations/getUserOrganizations');
        this.$router.push({ path: '/' });
      } catch (err) {
        if (err instanceof Unauthorized) {
          this.pwErrors = ['Invalid login'];
        } else {
          this.errorLoggingIn = err.message;
          console.error(err);
        }
      }
    },
    async login() {
      await this.loginUser({
        email: this.loginForm.email,
        password: this.loginForm.password
      });
    },
    async register() {
      if (!this.registerFormValid || this.isLoggingIn) {
        return;
      }
      const email = this.registerForm.email;
      const password = this.registerForm.password;
      const registerPayload = {
        firstName: this.registerForm.firstName,
        lastName: this.registerForm.lastName,
        email,
        password
      };
      try {
        this.errorRegistering = null;
        await this.$store.dispatch('auth/register', registerPayload);
        this.registerSuccess = true;
        setTimeout(() => {
          this.loginUser({ email, password });
        }, 2000);
      } catch (err) {
        if (err instanceof BadRequest) {
          const pwErrors = err.errors
            .filter(err => err.source.pointer === 'password')
            .map(err => err.title);
          const emailErrors = err.errors
            .filter(err => err.source.pointer === 'email')
            .map(err => err.title);
          this.pwErrors = pwErrors;
          this.emailErrors = emailErrors;
          return;
        }
        this.errorRegistering = err.message;
      }
    }
  },
  watch: {
    isLoggingIn: function(value: boolean) {
      if (value) {
        Vue.nextTick(() => (this.$refs.login as HTMLFormElement).reset());
      } else {
        Vue.nextTick(() => (this.$refs.register as HTMLFormElement).reset());
      }
    }
  }
});
