





































































import { PropType } from 'vue';
import { computed, defineComponent, toRefs } from '@vue/composition-api';

import DateItem from '@/components/molecules/DateItem.vue';
import { Project, ReviewTask } from '@/types';
import SubmitTaskButton from './SubmitTaskButton.vue';
import ExportAnnotationsButton from '@/components/molecules/ExportAnnotationsButton.vue';
import { TaskAndKind } from '@/layers';
import DeleteTaskButton from '@/components/molecules/DeleteTaskButton.vue';
import IdLink from '@/components/molecules/IdLink.vue';

export default defineComponent({
  components: {
    DateItem,
    IdLink,
    SubmitTaskButton,
    ExportAnnotationsButton,
    DeleteTaskButton
  },
  props: {
    tasks: {
      required: true,
      type: Array as PropType<Array<ReviewTask>>
    },
    project: {
      required: true,
      type: Object as PropType<Project>
    },
    orgId: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const { project, orgId, tasks } = toRefs(props);
    const headers = [
      {
        text: 'ID',
        value: 'id',
        sortable: true
      },
      {
        text: 'Dataset',
        value: 'datasetName',
        sortable: true
      },
      {
        text: 'Batch',
        value: 'batchName',
        sortable: true
      },
      {
        text: 'Assignee',
        value: 'reviewer',
        sortable: true
      },
      {
        text: 'Task type',
        value: 'uiName',
        sortable: true
      },
      {
        text: 'Assigned at',
        value: 'createdAt',
        sortable: true
      },
      {
        text: 'Progress',
        value: 'progress',
        align: 'center',
        sortable: false
      },
      {
        text: 'Actions',
        value: 'actions',
        align: 'center',
        sortable: false
      }
    ];

    const taskItems = computed(() =>
      tasks.value.map(task => ({
        ...task,
        task,
        id: task.id,
        reviewer: task.assignee?.name,
        assets: task.counts.assets,
        annotations: task.counts.annotations,
        createdBy: task.created_by ? task.created_by.name : undefined,
        createdAt: new Date(Date.parse(task.created_at)).toLocaleDateString(),
        progress: `${(
          (task.counts.annotations / task.counts.assets) *
          100.0
        ).toFixed(0)} % (${task.counts.annotations}/${task.counts.assets})`,
        uiName: task.ui?.name,
        datasetName: task.dataset.name,
        batchName: task.batch.filename
      }))
    );

    const annotateReviewTaskRoute = (taskId: string) => {
      return {
        name: 'ReviewTaskAnnotate',
        params: {
          projectId: project.value.id,
          orgId: orgId.value,
          taskId
        }
      };
    };

    function taskDetailsRoute(taskId: string) {
      return {
        name: 'ReviewTaskDetails',
        params: { projectId: project.value.id, taskId, orgId: orgId.value }
      };
    }

    const toGeneric = (task: ReviewTask): TaskAndKind => ({
      task,
      kind: 'ReviewTask'
    });

    return {
      headers,
      taskItems,
      annotateReviewTaskRoute,
      toGeneric,
      taskDetailsRoute
    };
  }
});
