























































import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  toRefs
} from '@vue/composition-api';
import { useCopyToClipboard } from '@/utils';
import useApi from '@/api/useApi';
import { whenever } from '@vueuse/core';

function useCreatePersonalAccessToken() {
  const token = ref<string>(null);
  const creating = ref(false);
  const error = ref<Error>(null);
  const api = useApi();

  async function createToken({ name }: { name: string }) {
    try {
      const res = await api.me.createPersonalAccessToken({ name });
      token.value = res.token;
    } catch (err) {
      error.value = err;
    } finally {
      creating.value = false;
    }
  }

  return { token, creating, error, createToken };
}

export default defineComponent({
  name: 'CreatePersonalAccessTokenCard',
  props: {
    closeDialog: {
      required: true,
      type: Function as PropType<() => void>
    }
  },
  setup(props, { emit, refs }) {
    const { closeDialog } = toRefs(props);
    const name = ref('');
    const inputValid = ref(false);
    const {
      createToken,
      creating,
      error,
      token
    } = useCreatePersonalAccessToken();

    const success = computed(() => !!token.value);

    const { copied, copyToClipBoard } = useCopyToClipboard();

    async function submit() {
      if (!inputValid.value) {
        return;
      }
      await createToken({ name: name.value });
    }

    function copyTokenToClipboard() {
      if (!token.value) {
        return;
      }
      copyToClipBoard(token.value);
    }

    const form = ref<HTMLFormElement>(null);

    onMounted(function() {
      form.value = refs.form as HTMLFormElement;
    });

    function close() {
      form.value?.reset();
      emit('refresh');
      closeDialog.value();
    }

    const nameRules = [
      (v: string) => !!v || 'Token name cannot be empty',
      (v: string) =>
        (!!v && v.length <= 32) || 'Token name can have at most 32 characters'
    ];

    return {
      token,
      inputValid,
      submit,
      error,
      creating,
      copyTokenToClipboard,
      copied,
      close,
      success,
      name,
      nameRules
    };
  }
});
