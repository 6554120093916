

















import { defineComponent } from '@vue/composition-api';
import Tooltip from '@/components/molecules/Tooltip.vue';

export default defineComponent({ components: { Tooltip } });
