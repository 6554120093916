var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.projectHeaders,"items":_vm.projectItems,"items-per-page":10,"sort-by":"createdAtRaw","sort-desc":false},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Projects")]),_c('v-spacer'),_c('create-resource-button',{staticClass:"create-proj-button",attrs:{"title":"Create project","fields":_vm.createProjectFields,"onSubmit":_vm.createProject}})],1),_c('div',{staticClass:"text-caption px-4"},[_c('p',[_vm._v(" Projects are used to group annotations per machine learning project. To annotate assets in a dataset, you first add the dataset to project. You can then start creating "),_c('b',[_vm._v("annotation tasks")]),_vm._v(" for batches. ")])])]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.toProject(item)}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('id-item',{attrs:{"id":item.id}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('date-item',{attrs:{"date":new Date(item.created_at)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":"","close-on-content-click":false,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('open-dialog-button',{attrs:{"text":"","title":"Edit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var closeDialog = ref.closeDialog;
return [_c('edit-project-card',_vm._g({attrs:{"project":item,"closeDialog":closeDialog}},_vm.$listeners))]}}],null,true)})],1),_c('v-list-item',[_c('open-dialog-button',{attrs:{"text":"","title":"Delete"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var closeDialog = ref.closeDialog;
return [_c('delete-project-card',_vm._g({attrs:{"project":item,"closeDialog":closeDialog}},_vm.$listeners))]}}],null,true)})],1)],1)],1)]}},(_vm.noProjects)?{key:"no-data",fn:function(){return [_vm._v(" No projects. ")]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }