









































































import { computed, defineComponent, toRefs } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import RemoveOrganizationMemberButton from '@/components/molecules/RemoveOrganizationMemberButton.vue';
import OrganizationMemberAdminCheckbox from '@/components/molecules/OrganizationMemberAdminCheckbox.vue';
import CreateUserButton from '@/components/molecules/CreateUserButton.vue';
import LoadingModal from '@/components/LoadingModal.vue';
import { useOrganizationMembers } from '@/api/use';
import OpenDialogButton from '@/components/molecules/OpenDialogButton.vue';
import AddUserToOrganizationCard from '@/components/molecules/AddUserToOrganizationCard.vue';

export default defineComponent({
  name: 'OrganizationUsers',
  components: {
    LoadingModal,
    RemoveOrganizationMemberButton,
    OrganizationMemberAdminCheckbox,
    CreateUserButton,
    OpenDialogButton,
    AddUserToOrganizationCard
  },
  props: {
    orgId: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const { orgId: organizationId } = toRefs(props);
    const { members, loading, error, mutate } = useOrganizationMembers(
      organizationId
    );

    const userItems = computed(() => {
      return members.value.map(member => ({
        ...member,
        admin: member.is_admin
      }));
    });

    const userHeaders = [
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Email',
        value: 'email'
      },
      {
        text: 'Admin',
        value: 'admin',
        align: 'center'
      },
      {
        text: 'Actions',
        value: 'actions',
        align: 'center',
        sortable: false
      }
    ];

    return {
      userItems,
      userHeaders,
      members,
      loading,
      error,
      refreshUsers: mutate
    };
  },
  computed: {
    ...mapGetters('auth', ['currentUser'])
  }
});
