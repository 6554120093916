import { render, staticRenderFns } from "./IdItem.vue?vue&type=template&id=49a0953c&scoped=true&"
import script from "./IdItem.vue?vue&type=script&lang=ts&"
export * from "./IdItem.vue?vue&type=script&lang=ts&"
import style0 from "./IdItem.vue?vue&type=style&index=0&id=49a0953c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49a0953c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBtn,VMenu,VSnackbar})
