import Vue from 'vue';
import VueRouter from 'vue-router';

import routes from './routes';

import Store from '../store/';

Vue.use(VueRouter);

const router = new VueRouter({
  routes
});

let entryUrl = null;
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!Store.getters['auth/isAuth']) {
      Store.dispatch('auth/checkJwt')
        .then(response => {
          if (response.auth) {
            if (entryUrl) {
              const url = entryUrl;
              entryUrl = null;
              next(url);
            } else {
              next();
            }
          } else {
            entryUrl = to.fullPath;
            next({
              path: '/welcome',
              params: { nextUrl: to.fullPath }
            });
          }
        })
        .catch(error => {
          entryUrl = to.fullPath;
          console.log('Error checking JWT', error);
          next({
            path: '/welcome',
            params: { nextUrl: to.fullPath }
          });
        });
    } else if (entryUrl) {
      const url = entryUrl;
      entryUrl = null;
      next(url);
    } else {
      next();
    }
  } else {
    if (to.matched.some(record => record.meta.dispatchLogout)) {
      Store.dispatch('auth/logout');
    }
    next();
  }
});

export default router;
