

















































import { Batch, Dataset } from '@/types';
import { PipelineStageDef } from './pipeline-types';
import Vue from 'vue';
import LoadingModal from '@/components/LoadingModal.vue';
import CreateAnnotationStage from '@/components/project/CreateAnnotationStage.vue';
import CreateReviewStage from '@/components/project/CreateReviewStage.vue';

export default Vue.extend({
  name: 'CreatePipeline',
  components: {
    LoadingModal,
    CreateAnnotationStage,
    CreateReviewStage
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    batchId: {
      type: String,
      required: true
    },
    orgId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      batch: null as Batch | null,
      dataset: null as Dataset | null,
      loading: false,
      errorFetching: null as string | null,
      // Let child components handle the proper typing
      annotationStageProps: null as any | null,
      reviewStageProps: null as any | null,
      submitting: false,
      errorSubmitting: null as string | null,
      successfullyCreated: false
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    invalidBatchId(): string | null {
      return this.batchId ? null : 'Missing batch ID';
    },
    stages(): PipelineStageDef[] {
      return [
        { kind: 'Annotation', properties: this.annotationStageProps },
        { kind: 'Review', properties: this.reviewStageProps }
      ];
    },
    inputValid(): boolean {
      return !!this.annotationStageProps && !!this.reviewStageProps;
    },
    routeAfterCreate(): Record<string, any> {
      return {
        name: 'ProjectPipelines',
        params: {
          projectId: this.projectId,
          orgId: this.orgId
        },
        query: {
          batch: this.batchId
        }
      };
    }
  },
  methods: {
    async fetchData() {
      if (!this.batchId) {
        return;
      }

      this.loading = true;
      this.errorFetching = null;

      try {
        const batch = await this.$api.batches.getBatch({
          batchId: this.batchId
        });
        const dataset = await this.$api.datasets.getDataset({
          datasetId: batch.dataset.id
        });
        this.batch = batch;
        this.dataset = dataset;
      } catch (error) {
        this.errorFetching = error;
      } finally {
        this.loading = false;
      }
    },
    async submit() {
      const stageDefs = this.stages;
      this.submitting = true;
      this.successfullyCreated = false;

      try {
        const pipeline = await this.$api.projects.createPipeline({
          projectId: this.projectId,
          batchId: this.batchId,
          definition: {
            stages: stageDefs
          }
        });
        console.log(`Created pipeline: ${pipeline.id}`);
        this.successfullyCreated = true;
        setTimeout(() => this.$router.push(this.routeAfterCreate), 1500);
      } catch (error) {
        this.errorSubmitting = error;
      } finally {
        this.submitting = false;
      }
    }
  },
  watch: {
    batchId: function() {
      this.fetchData();
    }
  },
  filters: {
    toDate: function(createdAt: string) {
      return new Date(Date.parse(createdAt)).toLocaleString();
    }
  }
});
