








































































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import OpenDialogButton from '@/components/molecules/OpenDialogButton.vue';
import CreatePersonalAccessTokenCard from '@/components/settings/CreatePersonalAccessTokenCard.vue';
import { useCurrentUserPersonalAccessTokens } from '@/api/use';
import { useCurrentUser } from '@/store/use';
import DateItem from '@/components/molecules/DateItem.vue';
import IdItem from '@/components/molecules/IdItem.vue';
import DeletePersonalAccessTokenButton from '@/components/molecules/DeletePersonalAccessTokenButton.vue';

export default defineComponent({
  name: 'PersonalAccessTokens',
  components: {
    DeletePersonalAccessTokenButton,
    OpenDialogButton,
    CreatePersonalAccessTokenCard,
    IdItem,
    DateItem
  },
  setup(_, { root: { $store: store } }) {
    const { user } = useCurrentUser(store);

    const showUsageInstructions = ref(false);

    const {
      error,
      loading,
      tokens,
      mutate: refresh
    } = useCurrentUserPersonalAccessTokens({
      user
    });

    const items = computed(() =>
      tokens.value.map(token => ({
        ...token,
        token
      }))
    );

    const headers = [
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'name'
      },
      {
        text: 'ID',
        align: 'start',
        sortable: true,
        value: 'id'
      },
      {
        text: 'Created at',
        value: 'created_at',
        sortable: false
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        align: 'center'
      }
    ];

    return {
      tokens,
      items,
      headers,
      error,
      loading,
      refresh,
      showUsageInstructions
    };
  }
});
