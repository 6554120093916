










































































import Vue, { PropType } from 'vue';

import { Project, AnnotationPipeline, AnnotationPipelineStage } from '@/types';

export default Vue.extend({
  name: 'ProjectPipelineItem',
  data() {
    return {
      loading: false,
      errorLoading: null as string | null,
      e1: 0
    };
  },
  props: {
    pipeline: {
      required: true,
      type: Object as PropType<AnnotationPipeline>
    },
    project: {
      required: true,
      type: Object as PropType<Project>
    }
  },
  computed: {
    steps(): number {
      return this.stages.length;
    },
    stages(): AnnotationPipelineStage[] {
      return this.pipeline.definition.stages;
    },
    activeStageName(): string | null {
      return this.pipeline.active_stage != undefined
        ? this.pipeline.definition.stages[this.pipeline.active_stage].kind
        : null;
    }
  },
  methods: {
    renderProperties(stage: AnnotationPipelineStage): string[] {
      if (stage.kind == 'Annotation') {
        return [
          `Annotators: ${stage.properties.annotators
            .map(annotator => annotator.name)
            .join(', ')}`
        ];
      } else if (stage.kind == 'Review') {
        return [`Reviewer: ${stage.properties.reviewer?.name}`];
      }

      return Object.keys(stage.properties).map(
        propertyKey =>
          `${propertyKey}: ${JSON.stringify(stage.properties[propertyKey])}`
      );
    },
    colorFromState(state: string): string {
      return state === 'FINISHED' ? 'success' : 'warning';
    }
  }
});
