



















































import { whenever } from '@vueuse/core';

import {
  computed,
  defineComponent,
  PropType,
  toRefs
} from '@vue/composition-api';

import { useSubmitDialog } from './functions';
import { TaskAndKind } from '@/layers';

export default defineComponent({
  name: 'SubmitTaskMenu',
  props: {
    taskAndKind: {
      required: true,
      type: Object as PropType<TaskAndKind>
    }
  },
  setup(props, { emit }) {
    const { taskAndKind } = toRefs(props);

    const {
      success,
      canSubmit,
      closeDialog,
      submitting,
      ...submitMenu
    } = useSubmitDialog(taskAndKind);

    const isDisabled = computed(() => !canSubmit.value || submitting.value);

    whenever(success, function() {
      setTimeout(() => {
        closeDialog();
        emit('refresh');
      }, 1000);
    });

    return { success, isDisabled, closeDialog, submitting, ...submitMenu };
  }
});
