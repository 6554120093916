































































import {
  computed,
  defineComponent,
  ref,
  Ref,
  toRefs
} from '@vue/composition-api';
import { whenever } from '@vueuse/core';
import { PropType } from 'vue';

import useApi from '@/api/useApi';
import { TaskAndKind } from '@/layers';

export const useDeleteDialog = (taskAndKind: Ref<TaskAndKind>) => {
  const isOpen = ref(false);

  const taskId = computed(() => taskAndKind.value.task.id);

  const success = ref(false);
  const error = ref<Error>(null);
  const loading = ref(false);
  const api = useApi();

  async function deleteTask() {
    const kind = taskAndKind.value.kind;
    success.value = false;
    error.value = null;
    loading.value = true;

    try {
      if (kind === 'AnnotationTask') {
        await api.tasks.deleteTask({ taskId: taskId.value });
      } else if (kind === 'ReviewTask') {
        await api.reviewTasks.deleteTask({ taskId: taskId.value });
      } else {
        throw new Error(`Cannot delete task with unknown type: ${kind}`);
      }
      success.value = true;
    } catch (err) {
      console.error(`Failed deleting task`, err);
      error.value = err;
    } finally {
      loading.value = false;
    }
  }

  function closeDialog() {
    isOpen.value = false;
  }

  return {
    success,
    isOpen,
    error,
    loading,
    deleteTask,
    closeDialog
  };
};

export default defineComponent({
  components: {},
  props: {
    taskAndKind: {
      required: true,
      type: Object as PropType<TaskAndKind>
    }
  },
  setup(props, { emit }) {
    const { taskAndKind } = toRefs(props);
    const task = computed(() => taskAndKind.value.task);
    const counts = computed(() => task.value.counts);
    const {
      loading,
      error,
      success,
      deleteTask,
      isOpen,
      closeDialog
    } = useDeleteDialog(taskAndKind);

    whenever(success, function() {
      setTimeout(() => {
        closeDialog();
        emit('refresh');
      }, 3000);
    });

    return { loading, error, success, deleteTask, isOpen, closeDialog, counts };
  }
});
