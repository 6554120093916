import { RouteConfig } from 'vue-router';

import Home from '@/components/home/Home.vue';
import Welcome from '@/views/Welcome.vue';
import ProjectsManagement from '@/views/ProjectManagement.vue';
import OrganizationUsers from '@/components/OrganizationUsers.vue';

import Onboarding from '@/components/onboarding/Onboarding.vue';
import CreateOrganization from '@/components/onboarding/CreateOrganization.vue';
import CreateProject from '@/components/onboarding/CreateProject.vue';
import CreateDataset from '@/components/onboarding/CreateDataset.vue';
import UploadBatch from '@/components/onboarding/UploadBatch.vue';

import DatasetsManagement from '@/views/DatasetManagement.vue';

import ConfigureUI from '@/views/ConfigureUI.vue';

import ProjectNav from '@/components/project/ProjectNav.vue';
import ProjectTasks from '@/components/project/ProjectTasks.vue';
import ProjectData from '@/components/project/ProjectData.vue';

import ProjectConfigurations from '@/components/project/ProjectConfigurations.vue';
import ProjectPipelines from '@/components/project/ProjectPipelines.vue';
import ProjectReleases from '@/components/project/ProjectReleases.vue';
import CreateAnnotationRelease from '@/components/project/CreateAnnotationRelease.vue';
import ReleaseDetails from '@/components/project/ReleaseDetails.vue';

import CreatePipeline from '@/components/project/CreatePipeline.vue';

import SettingsNav from '@/components/settings/SettingsNav.vue';
import SettingsAccount from '@/components/settings/SettingsAccount.vue';
import PersonalAccessTokens from '@/components/settings/PersonalAccessTokens.vue';

/* Lazy-load all routes that make use of charts, drawing, or other tasks requiring large JS bundles */

const DatasetBrowseView = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "dataset-browse" */ '@/components/dataset/DatasetBrowseView.vue'
  );

const TaskDetails = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "task-details" */ '@/components/project/TaskDetails.vue'
  );

const ProjectMetrics = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "project-metrics" */ '@/components/project/ProjectMetrics.vue'
  );
const TaskAnnotateV2 = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "task-annotate" */ '@/components/TaskAnnotateV2/TaskAnnotateV2.vue'
  );

const routes: Array<RouteConfig> = [
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: Onboarding,
    meta: {
      requiresAuth: true
    },
    props: true,
    children: [
      {
        path: 'organization',
        name: 'CreateOrganization',
        component: CreateOrganization,
        props: true
      },
      {
        path: 'project',
        name: 'CreateProject',
        component: CreateProject,
        props: true
      },
      {
        path: 'dataset',
        name: 'CreateDataset',
        component: CreateDataset,
        props: route => ({ ...route.params, projectId: route.query.projectId })
      },
      {
        path: 'upload',
        name: 'UploadBatch',
        component: UploadBatch,
        props: route => ({
          ...route.params,
          projectId: route.query.projectId,
          datasetId: route.query.datasetId
        })
      }
    ]
  },
  {
    path: '/organization/:orgId/projects',
    name: 'Projects',
    component: ProjectsManagement,
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: '/organization/:orgId/datasets',
    name: 'Datasets',
    component: DatasetsManagement,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/organization/:orgId/users',
    name: 'OrganizationUsers',
    component: OrganizationUsers,
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: '/organization/:orgId/project/:projectId',
    name: 'ProjectNav',
    component: ProjectNav,
    meta: {
      requiresAuth: true
    },
    props: true,
    children: [
      {
        path: 'tasks',
        name: 'ProjectTasks',
        component: ProjectTasks,
        props: true
      },
      {
        path: 'data',
        name: 'ProjectData',
        component: ProjectData,
        props: true
      },
      {
        path: 'metrics',
        name: 'ProjectMetrics',
        component: ProjectMetrics,
        props: true
      },
      {
        path: 'configurations',
        name: 'ProjectConfigurations',
        component: ProjectConfigurations,
        props: true
      },
      {
        path: 'pipelines/new',
        name: 'CreatePipeline',
        component: CreatePipeline,
        props: route => ({ ...route.params, batchId: route.query.batch })
      },
      {
        path: 'pipelines',
        name: 'ProjectPipelines',
        component: ProjectPipelines,
        props: route => ({ ...route.params, batchId: route.query.batch })
      },
      {
        path: 'releases',
        name: 'ProjectReleases',
        component: ProjectReleases,
        props: true
      },
      {
        path: 'create-release',
        name: 'CreateAnnotationRelease',
        component: CreateAnnotationRelease,
        props: true
      },
      {
        path: 'configure/:uiName',
        name: 'ConfigureUI',
        component: ConfigureUI,
        props: true
      },
      {
        path: 'tasks/:taskId',
        name: 'AnnotationTaskDetails',
        component: TaskDetails,
        props: route => ({
          ...route.params,
          taskKind: 'AnnotationTask'
        })
      },
      {
        path: 'review-tasks/:taskId',
        name: 'ReviewTaskDetails',
        component: TaskDetails,
        props: route => ({
          ...route.params,
          taskKind: 'ReviewTask'
        })
      },
      {
        path: 'releases/:releaseId',
        name: 'ReleaseDetails',
        component: ReleaseDetails,
        props: true
      }
    ]
  },
  {
    path: '/projects/:projectId/review-tasks/:taskId/annotate',
    name: 'ReviewTaskAnnotate',
    component: TaskAnnotateV2,
    meta: {
      requiresAuth: true
    },
    props: route => ({
      ...route.params,
      taskKind: 'ReviewTask',
      assetId: route.query.asset,
      uiName: route.query.ui
    })
  },
  {
    path: '/organization/:orgId/dataset/:datasetId/batch/:batchId?',
    name: 'DatasetBrowse',
    component: DatasetBrowseView,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:projectId/tasks/:taskId/annotate',
    name: 'TaskAnnotate',
    component: TaskAnnotateV2,
    meta: {
      requiresAuth: true
    },
    props: route => ({
      ...route.params,
      taskKind: 'AnnotationTask',
      assetId: route.query.asset,
      uiName: route.query.ui
    })
  },
  {
    path: '/settings',
    name: 'SettingsNav',
    component: SettingsNav,
    meta: {
      requiresAuth: true
    },
    props: true,
    children: [
      {
        path: 'account',
        name: 'SettingsAccount',
        component: SettingsAccount,
        props: true
      },
      {
        path: 'personal-access-tokens',
        name: 'PersonalAccessTokens',
        component: PersonalAccessTokens,
        props: true
      }
    ]
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Welcome,
    meta: {
      // TODO Handle the logout more explicitly
      dispatchLogout: true
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    },
    props: route => ({ ...route.params, view: route.query.view })
  }
];

export default routes;
