import * as z from 'zod';
import _findKey from 'lodash/findKey';
import { AssetMedia } from '@/types';

export interface TextAnnotationLabel {
  feature: string;
  value: string;
  certainty?: string;
}

export interface TextAnnotation {
  labels: TextAnnotationLabel[];
}

export type SaveTextAnnotationLabels = (
  labels: TextAnnotationLabel[]
) => Promise<void>;

export const resolveParameters = (params: any): TextAnnotatorParameters => {
  // TODO Validate
  return { ...DEFAULT_CONFIG, ...params };
};

const TextAnnotationFeatureClassSchema = z.object({ name: z.string() });
const TextAnnotationFeatureSchema = z.object({
  name: z.string(),
  classes: z.array(TextAnnotationFeatureClassSchema)
});

export type TextAnnotationFeatureClass = z.infer<
  typeof TextAnnotationFeatureClassSchema
>;

export type TextAnnotationFeature = z.infer<typeof TextAnnotationFeatureSchema>;

export const TextAnnotatorParameterSchema = z
  .object({
    features: z.array(TextAnnotationFeatureSchema),
    textAttribute: z.string()
  })
  .nonstrict();

export type TextAnnotatorParameters = z.infer<
  typeof TextAnnotatorParameterSchema
>;
export const DEFAULT_CONFIG: TextAnnotatorParameters = {
  features: [
    {
      name: 'Sentiment',
      classes: [
        {
          name: 'Neutral'
        },
        {
          name: 'Negative'
        },
        {
          name: 'Positive'
        }
      ]
    },
    {
      name: 'Politics',
      classes: [
        {
          name: 'No'
        },
        {
          name: 'Yes'
        }
      ]
    },
    {
      name: 'Religion',
      classes: [
        {
          name: 'No'
        },
        {
          name: 'Yes'
        }
      ]
    },
    {
      name: 'Hate speech',
      classes: [
        {
          name: 'No'
        },
        {
          name: 'Some'
        },
        {
          name: 'Very'
        }
      ]
    },
    {
      name: 'Violence',
      classes: [
        {
          name: 'No'
        },
        {
          name: 'Some'
        },
        {
          name: 'Very'
        }
      ]
    }
  ],
  textAttribute: 'Text'
};

export const findTextMediaSlug = (
  medias: Record<string, AssetMedia>
): string | undefined => {
  return _findKey(medias, val => val.media_type.startsWith('text/'));
};
