var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.error)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.error.message))]):_vm._e(),_c('v-card',{staticClass:"mx-6 mb-4 px-0",attrs:{"flat":"","outlined":"","background-color":"transparent"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"sort-desc":false,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Personal access tokens")]),_c('v-spacer'),_c('open-dialog-button',{staticClass:"py-0 my-2",attrs:{"title":"Create token","small":"","color":"accent","maxWidth":800},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var closeDialog = ref.closeDialog;
return [_c('create-personal-access-token-card',{attrs:{"closeDialog":closeDialog},on:{"refresh":_vm.refresh}})]}}])})],1),_c('div',{staticClass:"text-caption px-4"},[_c('p',[_vm._v(" Personal access tokens can be used for programmatic access to the Silo Annotator API. They provide access to all the resources that you have access to, so treat them with the same care as your password. ")]),_c('p',[_c('span',{staticClass:"more primary--text",on:{"click":function($event){_vm.showUsageInstructions = !_vm.showUsageInstructions}}},[_vm._v(_vm._s(_vm.showUsageInstructions ? 'Hide usage instructions' : 'Show usage instructions'))])]),(_vm.showUsageInstructions)?_c('div',[_c('div',[_c('p',{staticClass:"mb-1"},[_c('b',[_vm._v("Annotator Python SDK")]),_vm._v(" (experimental)")]),_c('p',[_vm._v(" Run "),_c('code',[_vm._v("annotator init")]),_vm._v(" and follow the instructions to authenticate Python SDK with a personal access token. ")])]),_c('div',[_c('p',{staticClass:"mb-1"},[_c('b',[_vm._v("Basic authentication")])]),_c('p',{staticClass:"mb-1"},[_vm._v(" Use the personal access to authenticate to the Silo Annotator API using the "),_c('a',{attrs:{"href":"https://developer.mozilla.org/en-US/docs/Web/HTTP/Authentication#basic_authentication_scheme"}},[_vm._v("Basic authentication scheme")]),_vm._v(". ")]),_c('p',{staticClass:"mb-1"},[_vm._v(" To authenticate, you need to: ")]),_c('ul',[_c('li',[_vm._v(" Concatenate your e-mail and personal access token, separated by a colon. For example: "),_c('code',[_vm._v("user@example.com:YOUR_TOKEN")])]),_c('li',[_vm._v(" Encode the concatenated string to Base64 encoding. For example: "),_c('code',[_vm._v("dXNlckBleGFtcGxlLmNvbTpZT1VSX1RPS0VO")])]),_c('li',[_vm._v(" Add the encoded value as "),_c('code',[_vm._v("Authorization: Basic")]),_vm._v(" header to HTTP requests. For exaple: "),_c('code',[_vm._v("Authorization: Basic dXNlckBleGFtcGxlLmNvbTpZT1VSX1RPS0VO")])])])])]):_vm._e()])]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('id-item',{attrs:{"id":item.id,"length":4}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('date-item',{attrs:{"date":new Date(item.created_at)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":"","close-on-content-click":false,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('delete-personal-access-token-button',_vm._g({attrs:{"text":"","small":"","color":"info","token":item},on:{"refresh":_vm.refresh}},_vm.$listeners))],1)],1)],1)]}},(_vm.items.length === 0)?{key:"no-data",fn:function(){return [_vm._v(" No personal access tokens. ")]},proxy:true}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }