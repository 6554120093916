























































import Vue from 'vue';
import { ApiException } from '@/api/client';
import LoadingModal from '../LoadingModal.vue';
import ProjectPipelineItem from './ProjectPipelineItem.vue';

import { Project, AnnotationPipeline, Batch } from '@/types';

export default Vue.extend({
  components: {
    LoadingModal,
    ProjectPipelineItem
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    orgId: {
      type: String,
      required: true
    },
    batchId: {
      type: String,
      required: true
    }
  },
  computed: {
    createNewPipelineRoute(): Record<string, any> {
      return {
        name: 'CreatePipeline',
        params: {
          ...this.$route.params
        },
        query: {
          batch: this.$route.query.batch
        }
      };
    }
  },
  created() {
    this.fetchData();
  },
  data: function() {
    return {
      loading: false,
      error: null as string | null,
      project: null as Project | null,
      pipelines: null as AnnotationPipeline[] | null,
      batch: null as Batch | null
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.error = null;
      try {
        const project = await this.$api.projects.getProject(this.projectId);
        this.project = project;
        const projectPipelines = await this.$api.projects.getPipelines(
          this.projectId
        );
        this.batch = await this.$api.batches.getBatch({
          batchId: this.batchId
        });
        this.pipelines = projectPipelines.filter(
          pipeline => pipeline.batch.id === this.batchId
        );
      } catch (err) {
        if (!(err instanceof ApiException)) {
          console.error(err);
        }
        this.error = err;
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    projectId: function() {
      this.fetchData();
    }
  }
});
