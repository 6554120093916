

































































































import { defineComponent, PropType, toRefs } from '@vue/composition-api';
import _find from 'lodash/find';

import { Dataset } from '@/types';
import CreateResourceButton from './CreateResourceButton.vue';
import DatasetBatchesTable from './DatasetBatchesTable.vue';
import DeleteDatasetCard from './DeleteDatasetCard.vue';
import DateItem from '@/components/molecules/DateItem.vue';
import IdItem from '@/components/molecules/IdItem.vue';
import EditDatasetCard from '@/components/EditDatasetCard.vue';
import OpenDialogButton from '@/components/molecules/OpenDialogButton.vue';

export default defineComponent({
  props: {
    organizationId: {
      required: true,
      type: String
    },
    datasets: {
      required: true,
      type: Array as PropType<Dataset[]>
    },
    onCreateDataset: {
      required: true,
      type: Function as PropType<
        ({
          name,
          organization_id
        }: {
          name: string;
          organization_id: string;
        }) => Promise<Dataset>
      >
    },
    onCreateTask: {
      required: true,
      type: Function
    }
  },
  components: {
    CreateResourceButton,
    DatasetBatchesTable,
    EditDatasetCard,
    DeleteDatasetCard,
    DateItem,
    OpenDialogButton,
    IdItem
  },
  setup() {
    function uploadBatchRoute(datasetId: string) {
      return {
        name: 'UploadBatch',
        query: {
          datasetId
        }
      };
    }

    const createDatasetFields = [
      {
        name: 'name',
        label: `Name of the dataset. For example: 'User Comments 2020' or 'Security Camera Footage 2021'.`
      }
    ];
    const datasetHeaders = [
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'name'
      },
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id'
      },
      {
        text: 'Created by',
        value: 'createdBy',
        sortable: false
      },
      {
        text: 'Created at',
        value: 'createdAt',
        sortable: false
      },
      {
        text: 'Actions',
        value: 'actions',
        align: 'center',
        sortable: false
      },
      {
        text: 'Upload batch',
        align: 'center',
        value: 'upload',
        sortable: false
      },
      {
        text: 'Batches',
        value: 'data-table-expand',
        align: 'center',
        sortable: false
      }
    ];
    return { createDatasetFields, uploadBatchRoute, datasetHeaders };
  },
  computed: {
    datasetItems(): Record<string, any> {
      return this.datasets.map(dataset => ({
        ...dataset,
        name: dataset.name,
        id: dataset.id,
        createdBy: dataset.created_by ? dataset.created_by.email : undefined,
        createdAtRaw: dataset.created_at,
        nBatches: dataset.batches.length
      }));
    },
    noDatasets(): boolean {
      return this.datasets.length === 0;
    }
  },
  methods: {
    toDataset(item): any {
      if (!this.datasets) {
        return undefined;
      }
      const dataset = _find(this.datasets, ds => ds.id === item.id);
      if (!dataset) {
        return undefined;
      }
      const params = {
        orgId: dataset.organization.id,
        datasetId: dataset.id,
        batchId: 'null'
      };
      const name = 'DatasetBrowse';
      return {
        name,
        params
      };
    },
    datasetForId(datasetId): Dataset {
      return this.datasets.filter(dataset => dataset.id === datasetId)[0];
    },
    async createDataset({ name }) {
      await this.onCreateDataset({
        name,
        organization_id: this.organizationId
      });
    }
  }
});
