var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.error)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),(!_vm.error)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.batchHeaders,"items":_vm.batchItems,"items-per-page":10,"sort-by":"createdAtRaw","sort-desc":true,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_vm._v(" Batches in dataset ")])]},proxy:true},{key:"item.tasks",fn:function(ref){
var item = ref.item;
return [(!!_vm.projectsOfDataset && !!_vm.members)?_c('open-dialog-button',{staticClass:"py-0 my-2",attrs:{"title":"Assign","small":"","color":"accent","maxWidth":800},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var closeDialog = ref.closeDialog;
return [_c('create-task-card',_vm._g({attrs:{"batch":item,"projects":_vm.projectsOfDataset,"closeDialog":closeDialog}},_vm.$listeners))]}}],null,true)}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":"","close-on-content-click":false,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('open-dialog-button',{attrs:{"text":"","title":"Delete"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var closeDialog = ref.closeDialog;
return [_c('delete-batch-card',_vm._g({attrs:{"batch":item,"closeDialog":closeDialog}},_vm.$listeners))]}}],null,true)})],1)],1)],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('date-item',{attrs:{"date":new Date(item.created_at)}})]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('id-item',{attrs:{"id":item.id}})]}},(!_vm.hasBatches)?{key:"no-data",fn:function(){return [_vm._v(" No batches. ")]},proxy:true}:null],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }